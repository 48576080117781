import countries from "@assets/countries.json";
import { Button } from "@atoms/button/button";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { Info, SectionSmall } from "@atoms/text";
import { UpdateCustomersRequest } from "@features/customers/types";
import {
  IdentificationIcon,
  BuildingOfficeIcon,
  PlusIcon,
  TagIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";

export const CustomerIdentity = ({
  form,
  setForm,
}: {
  form: UpdateCustomersRequest;
  setForm: (form: UpdateCustomersRequest) => void;
}) => {
  return (
    <>
      {form.customers[0].account_type !== "F" && (
        <div id="identity-individual" className="mt-6">
          <div className="flex flex-row justify-between items-center mb-2">
            <SectionSmall>Identity</SectionSmall>
            <IdentificationIcon className="w-5 opacity-50 inline-block mr-1 " />
          </div>

          <div className="flex flex-row mt-4">
            <div className="w-1/4">
              <InputLabel
                label={"First name"}
                input={
                  <Input
                    placeholder="First name"
                    value={form.customers[0].first_name}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        customers: [
                          {
                            ...form.customers[0],
                            first_name: e.target.value,
                          },
                        ],
                      })
                    }
                  />
                }
              />
            </div>
            <div className="w-1/4 pl-2">
              <InputLabel
                label={"Middle name"}
                input={
                  <Input
                    placeholder="Middle name"
                    value={form.customers[0].middle_name}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        customers: [
                          {
                            ...form.customers[0],
                            middle_name: e.target.value,
                          },
                        ],
                      })
                    }
                  />
                }
              />
            </div>
            <div className="w-1/4 pl-2">
              <InputLabel
                label={"Last name"}
                input={
                  <Input
                    placeholder="Last name"
                    value={form.customers[0].last_name}
                    onChange={(e) =>
                      setForm({
                        ...form,
                        customers: [
                          {
                            ...form.customers[0],
                            last_name: e.target.value,
                          },
                        ],
                      })
                    }
                  />
                }
              />
            </div>
            <div className="w-1/4 pl-2">
              <InputLabel
                label={"Birth date"}
                input={
                  <InputDate
                    placeholder="Birth date"
                    value={form.customers[0].date_of_birth}
                    onChange={(date) => {
                      setForm({
                        ...form,
                        customers: [
                          {
                            ...form.customers[0],
                            date_of_birth: date
                              ? new Date(
                                  new Date(date).getTime() -
                                    new Date(date).getTimezoneOffset() * 60000
                                ) //We don't care about timezone here
                                  .toISOString()
                                  .split("T")[0] || ""
                              : "",
                          },
                        ],
                      });
                    }}
                  />
                }
              />
            </div>
          </div>

          <div className="flex flex-row mt-4">
            <div className="w-1/2">
              <InputLabel
                label={"Nationality"}
                input={
                  <SelectMultiple
                    multiple={true}
                    value={
                      form.customers[0].nationality_code === ""
                        ? []
                        : [form.customers[0].nationality_code]
                    }
                    onChange={(list) => {
                      if (list.length === 0) {
                        setForm({
                          ...form,
                          customers: [
                            {
                              ...form.customers[0],
                              nationality_code: "",
                            },
                          ],
                        });
                      } else {
                        // take last element
                        const code = list[list.length - 1];
                        setForm({
                          ...form,
                          customers: [
                            {
                              ...form.customers[0],
                              nationality_code: code,
                            },
                          ],
                        });
                      }
                    }}
                    options={countries.map((c) => ({
                      label: c.name,
                      value: c["alpha-3"],
                    }))}
                  />
                }
              />
            </div>
            <div className="w-1/2 pl-2">
              <InputLabel
                label={"Domicile country"}
                input={
                  <SelectMultiple
                    multiple={true}
                    value={
                      form.customers[0].domicile_code === ""
                        ? []
                        : [form.customers[0].domicile_code]
                    }
                    onChange={(codes) => {
                      if (codes.length === 0) {
                        setForm({
                          ...form,
                          customers: [
                            {
                              ...form.customers[0],
                              domicile_code: "",
                            },
                          ],
                        });
                      } else {
                        // take last element
                        const code = codes[codes.length - 1];
                        setForm({
                          ...form,
                          customers: [
                            {
                              ...form.customers[0],
                              domicile_code: code,
                            },
                          ],
                        });
                      }
                    }}
                    options={countries.map((c) => ({
                      label: c.name + ` (${c["alpha-3"]})`,
                      value: c["alpha-3"],
                    }))}
                  />
                }
              />
            </div>
          </div>
        </div>
      )}
      {
        //company fields
        form.customers[0].account_type === "F" && (
          <div id="identity-company" className="mt-6">
            <div className="flex flex-row justify-between items-center mb-2">
              <SectionSmall>Identity</SectionSmall>
              <BuildingOfficeIcon className="w-5 opacity-50 inline-block mr-1 " />
            </div>

            <div className="flex flex-row mt-4">
              <div className="w-1/4">
                <InputLabel
                  label={"Registration number"}
                  input={
                    <Input
                      placeholder="Registration number"
                      value={form.customers[0].trading_name}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          customers: [
                            {
                              ...form.customers[0],
                              trading_name: e.target.value,
                            },
                          ],
                        })
                      }
                    />
                  }
                />
              </div>
              <div className="w-1/4 pl-2">
                <InputLabel
                  label={"Company name"}
                  input={
                    <Input
                      placeholder="Company name"
                      value={form.customers[0].company_name}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          customers: [
                            {
                              ...form.customers[0],
                              company_name: e.target.value,
                            },
                          ],
                        })
                      }
                    />
                  }
                />
              </div>{" "}
              <div className="w-1/4 pl-2">
                {" "}
                <InputLabel
                  label={"Trading name"}
                  input={
                    <Input
                      placeholder="Trading name"
                      value={form.customers[0].trading_name}
                      onChange={(e) =>
                        setForm({
                          ...form,
                          customers: [
                            {
                              ...form.customers[0],
                              trading_name: e.target.value,
                            },
                          ],
                        })
                      }
                    />
                  }
                />
              </div>{" "}
              <div className="w-1/4 pl-2">
                {" "}
                <InputLabel
                  label={"Domicile country"}
                  input={
                    <SelectMultiple
                      multiple={true}
                      value={
                        form.customers[0].domicile_code === ""
                          ? []
                          : [form.customers[0].domicile_code]
                      }
                      onChange={(codes) => {
                        if (codes.length === 0) {
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                domicile_code: "",
                              },
                            ],
                          });
                        } else {
                          // take last element
                          const code = codes[codes.length - 1];
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                domicile_code: code,
                              },
                            ],
                          });
                        }
                      }}
                      options={countries.map((c) => ({
                        label: c.name + ` (${c["alpha-3"]})`,
                        value: c["alpha-3"],
                      }))}
                    />
                  }
                />
              </div>
            </div>
          </div>
        )
      }
      <div className="flex flex-row mt-4">
        <div className="w-full">
          <InputLabel
            label={"Contacts"}
            input={
              <Input
                placeholder="email@algoreg.com, Harold <harold@algoreg.com>"
                value={form.customers[0].contacts}
                onChange={(e) =>
                  setForm({
                    ...form,
                    customers: [
                      {
                        ...form.customers[0],
                        contacts: e.target.value,
                      },
                    ],
                  })
                }
              />
            }
          />
        </div>
      </div>
      <div className="flex flex-row mt-4">
        <div className="w-full">
          <InputLabel
            label={"Phone"}
            input={
              <Input
                placeholder="+33612345678"
                value={form.customers[0].phone}
                onChange={(e) =>
                  setForm({
                    ...form,
                    customers: [
                      {
                        ...form.customers[0],
                        phone: e.target.value
                          ? "+" + e.target.value.replace(/[^0-9]/g, "")
                          : e.target.value,
                      },
                    ],
                  })
                }
              />
            }
          />
        </div>
      </div>

      {form.customers[0].variations.map((variation, index) => (
        <div key={"variation_" + index} className="mt-4">
          <div className="flex flex-row justify-between items-center mb-2">
            <div className="text-gray-400">
              <TagIcon className="w-4 inline-block mr-1 " />
              <Info>Name variation {index + 1}</Info>
            </div>
            <Button
              theme="danger"
              size="sm"
              onClick={() => {
                const newVariations = form.customers[0].variations.filter(
                  (v, i) => i !== index
                );
                setForm({
                  ...form,
                  customers: [
                    {
                      ...form.customers[0],
                      variations: newVariations,
                    },
                  ],
                });
              }}
            >
              <TrashIcon className="w-4" />
            </Button>
          </div>
          <div className="flex flex-row">
            {
              //individual fields
              form.customers[0].account_type !== "F" && (
                <>
                  <InputLabel
                    className="mr-4 grow"
                    label={"First name"}
                    input={
                      <Input
                        placeholder="First name"
                        value={variation.first_name}
                        onChange={(e) => {
                          const newVariations =
                            form.customers[0].variations.map((v, i) => {
                              if (i === index) {
                                return {
                                  ...v,
                                  first_name: e.target.value,
                                };
                              }
                              return v;
                            });
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                variations: newVariations,
                              },
                            ],
                          });
                        }}
                      />
                    }
                  />
                  <InputLabel
                    className="mr-4 grow"
                    label={"Middle name"}
                    input={
                      <Input
                        placeholder="Middle name"
                        value={variation.middle_name}
                        onChange={(e) => {
                          const newVariations =
                            form.customers[0].variations.map((v, i) => {
                              if (i === index) {
                                return {
                                  ...v,
                                  middle_name: e.target.value,
                                };
                              }
                              return v;
                            });
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                variations: newVariations,
                              },
                            ],
                          });
                        }}
                      />
                    }
                  />
                  <InputLabel
                    className="grow"
                    label={"Last name"}
                    input={
                      <Input
                        placeholder="Last name"
                        value={variation.last_name}
                        onChange={(e) => {
                          const newVariations =
                            form.customers[0].variations.map((v, i) => {
                              if (i === index) {
                                return {
                                  ...v,
                                  last_name: e.target.value,
                                };
                              }
                              return v;
                            });
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                variations: newVariations,
                              },
                            ],
                          });
                        }}
                      />
                    }
                  />
                </>
              )
            }
            {
              //company fields
              form.customers[0].account_type === "F" && (
                <>
                  <InputLabel
                    className="mr-4 grow"
                    label={"Company name"}
                    input={
                      <Input
                        placeholder="Company name"
                        value={variation.company_name}
                        onChange={(e) => {
                          const newVariations =
                            form.customers[0].variations.map((v, i) => {
                              if (i === index) {
                                return {
                                  ...v,
                                  company_name: e.target.value,
                                };
                              }
                              return v;
                            });
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                variations: newVariations,
                              },
                            ],
                          });
                        }}
                      />
                    }
                  />
                  <InputLabel
                    className="mr-4 grow"
                    label={"Trading name"}
                    input={
                      <Input
                        placeholder="Trading name"
                        value={variation.trading_name}
                        onChange={(e) => {
                          const newVariations =
                            form.customers[0].variations.map((v, i) => {
                              if (i === index) {
                                return {
                                  ...v,
                                  trading_name: e.target.value,
                                };
                              }
                              return v;
                            });
                          setForm({
                            ...form,
                            customers: [
                              {
                                ...form.customers[0],
                                variations: newVariations,
                              },
                            ],
                          });
                        }}
                      />
                    }
                  />
                </>
              )
            }
          </div>
        </div>
      ))}

      <div className="flex flex-row  justify-between mt-4">
        <div />
        <Button
          size="sm"
          theme="outlined"
          className="mt-2"
          onClick={() => {
            const variation = {
              first_name: "",
              last_name: "",
              middle_name: "",
              trading_name: "",
              company_name: "",
            };
            setForm({
              ...form,
              customers: [
                {
                  ...form.customers[0],
                  variations: [...form.customers[0].variations, variation],
                },
              ],
            });
          }}
        >
          <PlusIcon className="w-4 mr-2" />
          Add name variation
        </Button>
      </div>
    </>
  );
};
