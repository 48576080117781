import { CustomerDetailType, CustomerType } from "./types";

export const CUSTOMERS_EDD_STATES_ID: any = {
  SYSTEM_ERROR: 2,
  ACTION_REQUIRED: 3,
  PASSED: 4,
  PENDING: 5,
  REFUSED: 6,
};

export const CUSTOMERS_EDD_STATES: { [key: string]: string } = {
  // { id: 1, name: 'undefined' },
  "2": "System Error",
  "3": "Action Required",
  "4": "Passed",
  "5": "Pending",
  "6": "Refused",
  "7": "Passed 1 Month",
  "8": "Passed 3 Month",
  "9": "Passed 6 Month",
  "10": "Passed 12 Month",
  "11": "Passed 24 Month",
  "12": "Passed 36 Month",
  disabled: "Disabled",
};

export const CUSTOMERS_EDD_STATES_COLORS: any = {
  // { id: 1, name: 'undefined' },
  "2": "#AF2424",
  "3": "#FB923C",
  "4": "#10B981",
  "5": "#FCD34D",
  "6": "#EF4444",
  "7": "#3B82F6",
  "8": "#3B62F6",
  "9": "#3B42F6",
  "10": "#3B22F6",
  "11": "#3B02F6",
  "12": "#3B02A6",
};

export { RISK_LABEL } from "@features/risk-decisions/utils";

export const CUSTOMER_TYPES_LABELS: any = {
  N: "Individual",
  F: "Firm",
  S: "Stakeholder",
  U: "Subaccount",
};

export const RELATIONSHIP: any = {
  0: "Undefined",
  1: "Wife",
  2: "Husband",
  3: "Brother",
  4: "Sister",
  5: "Son",
  6: "Daughter",
  7: "Mother",
  8: "Father",
  9: "Cousin",
  10: "Step-Son",
  11: "Step-Daughter",
  12: "Brother-in-law",
  13: "Sister-in-law",
  14: "Uncle",
  15: "Aunt",
  16: "Mother-in-law",
  17: "Father-in-law",
  18: "Grandfather",
  19: "Grandmother",
  20: "Son-in-law",
  21: "Daughter-in-law",
  22: "Niece",
  23: "Nephew",
  24: "Grandson",
  25: "Granddaughter",
  26: "Stepfather",
  27: "Stepmother",
  28: "Business Associate",
  29: "Friend",
  30: "Financial Adviser",
  31: "Legal Adviser",
  32: "Colleague",
  33: "Agent/Representative",
  34: "Employee",
  35: "Associate",
  36: "Child",
  37: "Family Member",
  38: "Political Adviser",
  39: "Senior Official",
  40: "Unmarried Partner",
  41: "Same-sex Spouse",
  42: "Employer",
  43: "Shareholder/Owner",
  44: "Associated Special Interest Person",
  45: "Parent Company",
  46: "Subsidiary",
  47: "Asset",
  100: "Affiliate Company",
  101: "Ultimate Beneficial Owner (UBO)",
  102: "Legal Representative",
  103: "Subscriber",
  104: "Provider",
  105: "Trustee",
  106: "Beneficiary",
  107: "Sole Proprietor",
  108: "Director",
  109: "Officer",
  110: "Contractor",
};

export const STATES_COLORS: any = {
  "2": "red-700",
  "3": "orange-500",
  "4": "green-500",
  "5": "yellow-500",
  "6": "red-500",
  "7": "blue-500",
  "8": "blue-500",
  "9": "blue-500",
  "10": "blue-500",
  "11": "blue-500",
  "12": "blue-500",
};

export const getEddIdFromBackendName: any = (edd: string) => {
  return (
    {
      error: 2,
      required: 3,
      notrequired: 4,
      pending: 5,
      failed: 6,
      passed1months: 7,
      passed3months: 8,
      passed6months: 9,
      passed12months: 10,
      passed24months: 11,
      passed36months: 12,
    }[edd.toLocaleLowerCase().replace(/[^a-z0-9]/gm, "")] || 1
  );
};

export const STATES_BACKEND_NAMES: any = {
  "2": "Error",
  "3": "Required",
  "4": "Passed",
  "5": "Pending",
  "6": "Refused",
  "7": "Passed_1_month",
  "8": "Passed_3_months",
  "9": "Passed_6_months",
  "10": "Passed_12_months",
  "11": "Passed_24_months",
  "12": "Passed_36_months",
};

export type RiskType =
  | "Undefined"
  | "Error"
  | "Low"
  | "Medium"
  | "High"
  | "Critical";

export enum CustomerRiskStatus {
  Error = 0,
  Undefined = 1,
  Low = 2,
  Medium = 3,
  High = 4,
  Critical = 5,
}

export const CUSTOMERS_RISKS_BACKEND_NAME: {
  [key in number]: RiskType;
} = {
  0: "Error",
  1: "Undefined",
  2: "Low",
  3: "Medium",
  4: "High",
  5: "Critical",
};

export const CUSTOMERS_RISKS_BACKEND_LABEL: {
  [key in CustomerRiskStatus]: string;
} = {
  0: "Error",
  1: "Undefined",
  2: "Low",
  3: "Medium-Low",
  4: "Medium-High",
  5: "High",
};

export const CUSTOMERS_RISKS_BACKEND_COLORS: {
  [key in number]: string;
} = {
  0: "red-800",
  1: "slate-400",
  2: "green-500",
  3: "yellow-500",
  4: "orange-500",
  5: "red-500",
};

export const CUSTOMERS_RISKS_BACKEND_DIGIT: {
  [key in number]: number;
} = {
  [CustomerRiskStatus.Undefined]: 0,
  [CustomerRiskStatus.Low]: 1,
  [CustomerRiskStatus.Medium]: 2,
  [CustomerRiskStatus.High]: 3,
  [CustomerRiskStatus.Critical]: 4,
};

export const RISK_CUSTOMER_CODES_LIST = [
  {
    id: CustomerRiskStatus["Undefined"],
    value: CUSTOMERS_RISKS_BACKEND_NAME[CustomerRiskStatus["Undefined"]],
    label: CUSTOMERS_RISKS_BACKEND_LABEL[CustomerRiskStatus["Undefined"]],
    color: CUSTOMERS_RISKS_BACKEND_COLORS[CustomerRiskStatus["Undefined"]],
  },
  {
    id: CustomerRiskStatus["Low"],
    digit: CUSTOMERS_RISKS_BACKEND_DIGIT[CustomerRiskStatus["Low"]],
    value: CUSTOMERS_RISKS_BACKEND_NAME[CustomerRiskStatus["Low"]],
    label: CUSTOMERS_RISKS_BACKEND_LABEL[CustomerRiskStatus["Low"]],
    color: CUSTOMERS_RISKS_BACKEND_COLORS[CustomerRiskStatus["Low"]],
  },
  {
    id: CustomerRiskStatus["Medium"],
    digit: CUSTOMERS_RISKS_BACKEND_DIGIT[CustomerRiskStatus["Medium"]],
    value: CUSTOMERS_RISKS_BACKEND_NAME[CustomerRiskStatus["Medium"]],
    label: CUSTOMERS_RISKS_BACKEND_LABEL[CustomerRiskStatus["Medium"]],
    color: CUSTOMERS_RISKS_BACKEND_COLORS[CustomerRiskStatus["Medium"]],
  },
  {
    id: CustomerRiskStatus["High"],
    digit: CUSTOMERS_RISKS_BACKEND_DIGIT[CustomerRiskStatus["High"]],
    value: CUSTOMERS_RISKS_BACKEND_NAME[CustomerRiskStatus["High"]],
    label: CUSTOMERS_RISKS_BACKEND_LABEL[CustomerRiskStatus["High"]],
    color: CUSTOMERS_RISKS_BACKEND_COLORS[CustomerRiskStatus["High"]],
  },
  {
    id: CustomerRiskStatus["Critical"],
    digit: CUSTOMERS_RISKS_BACKEND_DIGIT[CustomerRiskStatus["Critical"]],
    value: CUSTOMERS_RISKS_BACKEND_NAME[CustomerRiskStatus["Critical"]],
    label: CUSTOMERS_RISKS_BACKEND_LABEL[CustomerRiskStatus["Critical"]],
    color: CUSTOMERS_RISKS_BACKEND_COLORS[CustomerRiskStatus["Critical"]],
  },
];

export const extractCustomerFullName = (
  item: Pick<
    CustomerType | CustomerDetailType["customer"],
    "company_name" | "trading_name" | "first_name" | "last_name" | "middle_name"
  >
) => {
  return [
    (item as CustomerDetailType["customer"])?.company_name ||
      (item as CustomerType)?.company_name,
    (item as CustomerDetailType["customer"])?.trading_name ||
      (item as CustomerType)?.trading_name,
    item?.first_name,
    item?.middle_name,
    item?.last_name,
  ]
    .filter((a) => a)
    .join(" ");
};

export const extractInitialsName = (
  item: CustomerType | CustomerDetailType["customer"]
) => {
  const name = extractCustomerFullName(item);
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || "") + (initials.pop() || "")).toUpperCase();
};
