import { useCallback } from "react";
import { useMutation, useQuery } from "react-query";
import { KytApiClient } from "../api-client/api-client";
import { KytContext, KytContextResponse, KytContexts } from "../types";

const contextToResponseContext = (context: KytContext): KytContextResponse => {
  return { ...context, review_groups: context.review_groups.join(",") };
};

export function useContexts() {
  const getContextsQuery = () =>
    useQuery<KytContexts>({
      queryKey: ["kyt", "contexts"],
      queryFn: async () => {
        const response = await KytApiClient.getContexts();
        return {
          ...response,
          contexts: response.contexts.map((context) => ({
            ...context,
            review_groups: context.review_groups
              .split(",")
              .filter((value) => value),
          })),
        };
      },
      refetchOnWindowFocus: false,
    });

  const createContextMutation = useMutation({
    mutationFn: async (context: KytContext) => {
      await KytApiClient.createContext(contextToResponseContext(context));
    },
  });

  const updateContextMutation = useMutation({
    mutationFn: async (context: KytContext) => {
      await KytApiClient.updateContext(contextToResponseContext(context));
    },
  });

  const deleteContextMutation = useMutation({
    mutationFn: async (context: KytContext) => {
      await KytApiClient.deleteContext(contextToResponseContext(context));
    },
  });

  const { mutateAsync: createContextMutate } = createContextMutation;
  const { mutateAsync: updateContextMutate } = updateContextMutation;
  const { mutateAsync: deleteContextMutate } = deleteContextMutation;

  const {
    data: contexts,
    refetch: refetchContexts,
    isLoading: isFetchingContexts,
  } = getContextsQuery();

  const createContext = useCallback(
    async (context: KytContext) => {
      await createContextMutate(context);
      await refetchContexts();
    },
    [createContextMutate, refetchContexts]
  );

  const updateContext = useCallback(
    async (context: KytContext) => {
      await updateContextMutate(context);
      await refetchContexts();
    },
    [updateContextMutation, refetchContexts]
  );

  const deleteContext = useCallback(
    async (context: KytContext) => {
      await deleteContextMutate(context);
      await refetchContexts();
    },
    [deleteContextMutation, refetchContexts]
  );

  return {
    createContext,
    updateContext,
    deleteContext,
    contexts,
    loading: isFetchingContexts,
  };
}
