export type CustomFieldType = {
  id?: number;
  field_id?: number;
  label: string;
  header_name: string;
  group: string;
  review_groups: string[];
  format: {
    type?: string;
    multiple?: boolean;
    related_products?: string[];
  };
  field_type: 2 | 3 | 4 /* 	FieldType_Numeric FieldType = 2
                            FieldType_Boolean           = 3
                            FieldType_String            = 4 */;
  field_source:
    | 2
    | 3
    | 4
    | 5
    | 6
    | 7
    | 8 /* 	FieldSource_CustomerInternal         FieldSource = 2
            FieldSource_CustomerCustom                       = 3
            FieldSource_CustomerComputed                     = 4
            FieldSource_CustomerRelationInternal             = 5
            FieldSource_CustomerRelationCustom               = 6 
            define an unused field_source value              = 7
            FieldSource_TransactionCustom                    = 8
            */;
};

export const FIELDS_NAMES: any = {
  is_pep: "Is PEP",
  is_str: "Is STR",
  is_sanction: "Is SAN",
  is_tm: "Is TM",
  may_be_pep: "May be PEP",
  may_be_sanction: "May be SAN",
};
