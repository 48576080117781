import { Input } from "@atoms/input/input-text";
import { Info } from "@atoms/text";
import {
  RiskNodeField,
  RiskResourceType,
  RiskTreeNode,
} from "@features/risk-decisions/types";
import {
  COMPARAISON_OPERATORS,
  NODE_INVISIBLE_UNDEFINED,
  RISK_CODES_LIST,
} from "@features/risk-decisions/utils";
import { LightBulbIcon } from "@heroicons/react/24/outline";
import { CSSProperties } from "react";
import { Handle, HandleProps, NodeProps, Position } from "reactflow";
import { NodeHeader } from "./components/_node_header";
import { NodeLayout } from "./components/_node_layout";
import { FieldSelector, useGetFieldType } from "./components/field-selector";
import Select from "@atoms/select";

const handleStyle: CSSProperties = {
  height: 15,
  width: 15,
};

const fromProps: HandleProps = {
  type: "target",
  id: "from",
  position: Position.Left,
};
const noProps: HandleProps = {
  type: "source",
  id: "on_default_node_id",
  position: Position.Right,
};
const yesProps: HandleProps = {
  type: "source",
  id: "on_success_node_id",
  position: Position.Right,
};

const undefinedProps: HandleProps = {
  type: "source",
  id: "on_undefined_node_id",
  position: Position.Right,
};

const validateNode = (node: RiskTreeNode) => {
  if (!node.name) return "Name is missing";
  if (!node.comparison?.field?.id && !node.comparison?.field?.string_identifier)
    return "Field is missing";
  if (!node.comparison?.operator) return "Comparison operator is missing";
  if (!node.comparison?.value) return "Value is missing";

  if (
    !Object.values(node.output_node_ids).some(
      (v) => v !== NODE_INVISIBLE_UNDEFINED.id
    )
  )
    return "At least one output is required";
  return "";
};

export default function ComparisonNode(
  props: NodeProps<
    RiskTreeNode & {
      field: RiskNodeField;
      resource: RiskResourceType;
      withRelations: boolean;
      editable: boolean;
      onChange: (data: RiskTreeNode) => void;
    }
  >
) {
  const getFieldType = useGetFieldType();
  const isBooleanField =
    getFieldType(props.data.comparison!.field) === "boolean";
  const isStringField = getFieldType(props.data.comparison!.field) === "text";

  return (
    <NodeLayout
      selected={props.selected}
      invalidMessage={validateNode(props.data)}
    >
      {" "}
      <NodeHeader
        disabled={!props.data.editable}
        icon={(p) => <LightBulbIcon {...p} />}
        type="Comparison Component"
        value={props.data.name || ""}
        onChange={(value) =>
          props.data.onChange({
            ...props.data,
            name: value,
          })
        }
      />
      <FieldSelector
        type={props.data.resource}
        withRelations={props.data.withRelations}
        editable={props.data.editable}
        value={
          props.data.comparison?.field || {
            id: 0,
            string_identifier: undefined,
            entity: "customer",
          }
        }
        onChange={(value) => {
          const fieldType = getFieldType(value);
          return props.data.onChange({
            ...props.data,
            comparison: {
              ...props.data.comparison!,
              ...(fieldType === "boolean"
                ? {
                    operator: "eq",
                    value: "TRUE",
                  }
                : {
                    operator: "",
                    value: "",
                  }),
              field: value,
            },
          });
        }}
      />
      <div className="flex flex-row nodrag text-center h-9 text-sm  border-b border-gray-200 dark:border-slate-400">
        {[
          ...COMPARAISON_OPERATORS,
          {
            value: "regex",
            label: "~=",
            tooltip: "Regular expression",
          },
        ].map((operator) => {
          return (
            <button
              data-tooltip={operator.tooltip}
              disabled={
                !props.data.editable ||
                (isBooleanField && !["eq", "neq"].includes(operator.value)) ||
                (!isStringField && operator.value === "regex")
              }
              key={"comparaison_" + operator.value}
              onClick={() => {
                props.data.onChange({
                  ...props.data,
                  comparison: {
                    ...props.data.comparison!,
                    operator: operator.value,
                  },
                });
              }}
              className={`disabled:opacity-30 grow m-auto h-full border-r ${
                operator.value === props.data.comparison!.operator
                  ? "bg-blue-600 text-white font-bold"
                  : ""
              }`}
            >
              {operator.label}
            </button>
          );
        })}
      </div>
      {props.data.comparison?.field.string_identifier?.includes(
        "risk.factor"
      ) ? (
        <Select
          className="disabled:opacity-50 nowheel nodrag w-full rounded-none grow"
          defaultValue={props.data.comparison?.value}
          onChange={(e) => {
            props.data.onChange({
              ...props.data,
              comparison: {
                ...props.data.comparison!,
                value: e.target.value ?? "",
              },
            });
          }}
        >
          <option value="">Select the type of risk factor</option>
          {RISK_CODES_LIST.map((risk) => (
            <option
              key={risk.value}
              className={`text-${risk.color}`}
              value={risk.value}
            >
              {risk.digit}
            </option>
          ))}
        </Select>
      ) : (
        <Input
          className="disabled:opacity-30 nowheel nodrag rounded-none text-sm border-gray-200 border-t-0 border-l-0 border-r-0"
          placeholder={
            props.data.comparison?.operator === "regex" ? "^.*$" : "Value"
          }
          disabled={isBooleanField || !props.data.editable}
          value={props.data.comparison?.value || ""}
          onChange={(e) => {
            props.data.onChange({
              ...props.data,
              comparison: {
                ...props.data.comparison!,
                value: e.target.value ?? "",
              },
            });
          }}
        />
      )}
      {props.data.comparison?.operator === "regex" && (
        <Info>
          Regular expression are case insensitive, do not include the delimiters
          nor modifiers.
        </Info>
      )}
      <div className="h-2"></div>
      <div className="relative">
        <small className="mx-4">from</small>
        <Handle {...fromProps} style={{ ...handleStyle, left: -8 }} />
      </div>
      <div className="relative text-right">
        <small className="mx-4">no</small>
        <Handle
          onConnect={(params) => {
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [noProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...noProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="relative text-right">
        <small className="mx-4">yes</small>
        <Handle
          onConnect={(params) => {
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [yesProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...yesProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="relative text-right">
        <small className="mx-4 opacity-30">undefined</small>
        <Handle
          onConnect={(params) => {
            console.log("handle onConnect", params);
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [undefinedProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...undefinedProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="h-4"></div>
    </NodeLayout>
  );
}
