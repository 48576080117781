import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, Info, InfoSmall } from "@atoms/text";
import { AssigneeTag } from "@components/assignations/tag";
import { CustomFieldType } from "@features/custom-fields/types";
import { CustomerSearchType } from "@features/customers/types";
import {
  extractCustomerFullName,
  getEddIdFromBackendName,
  RISK_LABEL,
  CUSTOMERS_RISKS_BACKEND_COLORS,
  STATES_BACKEND_NAMES,
  STATES_COLORS,
  CUSTOMERS_RISKS_BACKEND_LABEL,
} from "@features/customers/utils";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { getSubLabels } from "@features/risk-decisions/utils";
import { ROUTES } from "@features/routes";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { ScenarioListType } from "@features/scenarios/types";
import { formatNumber } from "@features/utils";
import { formatAmount } from "@features/utils/strings";
import {
  ArrowDownCircleIcon,
  ArrowUpCircleIcon,
  BuildingOfficeIcon,
  EyeIcon,
  QuestionMarkCircleIcon,
  UserIcon,
} from "@heroicons/react/24/outline";
import _ from "lodash";
import { ReactNode } from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

const amountToColor = (amount: number) => {
  amount = parseFloat(amount.toString());
  if (amount < 1000) {
    return "text-black dark:text-white opacity-50";
  }
  if (amount < 10000) {
    return "text-black dark:text-white opacity-75";
  }
  return "text-red-900 dark:text-red-50";
};

export const availableColumns = Object.assign({
  primary_external_id: {
    title: "External ID",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info
        className="whitespace-nowrap overflow-hidden text-ellipsis max-w-40"
        data-tooltip={row.external_id}
      >
        {row.external_id}
      </Info>
    ),
  },
  sort_name: {
    title: "Name",
    orderable: true,
    thClassName: "w-full",
    render: (row: CustomerSearchType) => (
      <div className="flex items-center">
        {!!(row.trading_name || row.company_name) && (
          <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
        )}
        {!(row.trading_name || row.company_name) && (
          <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
        )}
        {extractCustomerFullName(row)}
      </div>
    ),
  },
  date_of_birth: {
    title: "Birth Date",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">
        {new Date(row.date_of_birth * 1000).toISOString().split("T")[0]}
      </Info>
    ),
  },
  first_names: {
    title: "First Name",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">{row.first_name}</Info>
    ),
  },
  last_names: {
    title: "Last Name",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">{row.last_name}</Info>
    ),
  },
  customer_last_updated_at: {
    title: "Last Updated",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">
        {
          new Date(row.customer_last_updated_at * 1000)
            .toISOString()
            .split("T")[0]
        }
      </Info>
    ),
  },
  edd_last_updated_at: {
    title: "EDD Updated",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">
        {new Date(row.edd_last_updated_at * 1000).toISOString().split("T")[0]}
      </Info>
    ),
  },
  trading_names: {
    title: "Trading Names",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">{row.trading_name}</Info>
    ),
  },
  domicile_code: {
    title: "Domicile",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">{row.domicile_code}</Info>
    ),
  },
  nationality_code: {
    title: "Nationality",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">{row.nationality_code}</Info>
    ),
  },
  company_names: {
    title: "Company Names",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Info className="whitespace-nowrap">{row.company_name}</Info>
    ),
  },
  edd_comment: {
    title: "EDD Comment",
    thClassName: "whitespace-nowrap",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Base className="whitespace-nowrap">{row.edd_comment}</Base>
    ),
  },
  contacts: {
    title: "Contacts",
    orderable: true,
    thClassName: "w-full",
    render: (row: CustomerSearchType) => (
      <Info>
        {(row.contacts || []).map((a) => (
          <div key={a} className="whitespace-nowrap">
            {a}
          </div>
        ))}
      </Info>
    ),
  },
  open_chat_categories: {
    title: "Open Chats",
    orderable: true,
    thClassName: "w-full whitespace-nowrap",
    render: (row: CustomerSearchType) => <ChatCell row={row} closed={false} />,
  },
  closed_chat_categories: {
    title: "Closed Chats",
    orderable: true,
    thClassName: "w-full whitespace-nowrap",
    render: (row: CustomerSearchType) => <ChatCell row={row} open={false} />,
  },
  review_groups: {
    title: "Groups",
    orderable: true,
    thClassName: "w-full",
    render: (row: CustomerSearchType) => (
      <div className="flex items-center">
        {row.review_groups?.map((group) => (
          <Tag key={group} className="mx-1 my-1">
            {group}
          </Tag>
        ))}
      </div>
    ),
  },
  overall_risk: {
    title: "Risk",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <Tag
        noColor
        className={
          "mx-1 my-1 text-white bg-" +
          (CUSTOMERS_RISKS_BACKEND_COLORS[row.risk] || "slate-500")
        }
      >
        {CUSTOMERS_RISKS_BACKEND_LABEL[row.risk] || row.risk}
      </Tag>
    ),
  },
  edd_state: {
    title: "Status",
    orderable: true,
    render: (row: CustomerSearchType) => (
      <>
        {row.edd_state === "Required" && (
          <span className="inline-flex h-3 w-3 relative mr-2">
            <span className="relative inline-flex rounded-full h-3 w-3 bg-orange-400"></span>
          </span>
        )}
        <Tag
          noColor
          className={
            "mx-1 my-1 text-white bg-" +
            (STATES_COLORS[
              row.disabled ? "disabled" : getEddIdFromBackendName(row.edd_state)
            ] || "slate-500")
          }
        >
          {STATES_BACKEND_NAMES[getEddIdFromBackendName(row.edd_state)] ||
            "unknown"}
        </Tag>
      </>
    ),
  },
  risk_factors: {
    title: "Risk Factors",
    thClassName: "whitespace-nowrap",
    render: (row: CustomerSearchType) => (
      <div>
        {_.sortBy(Object.entries(row.risk_factors || {}), (r) =>
          getSubLabels(r[0])
        ).map(([key, value]) => (
          <Tag
            key={key}
            noColor
            data-tooltip={key + ": " + value}
            className={
              "overflow-hidden text-ellipsis max-w-24 mx-1 my-1 text-white bg-" +
              (CUSTOMERS_RISKS_BACKEND_COLORS[
                Object.keys(RISK_LABEL).find(
                  (k) =>
                    RISK_LABEL[k].toLocaleLowerCase() ===
                    value.toLocaleLowerCase()
                ) as any
              ] || "slate-500")
            }
          >
            {key?.replace(/ *\| */, ": ")}
          </Tag>
        ))}
      </div>
    ),
  },
  scan: {
    title: "Scan",
    render: (row: CustomerSearchType) => (
      <Link
        to={ROUTES.Alerts + "?id=" + row.external_id}
        className="flex space-x-1"
      >
        {row.computed_fields.may_be_sanction && (
          <Tag
            noColor
            data-tooltip="Unresolved SAN alerts in Go!Scan"
            className={
              "mx-1 my-1 text-white bg-red-400 whitespace-nowrap flex items-center space-x-1"
            }
          >
            <QuestionMarkCircleIcon className="w-4 h-4" />
            <span>SAN</span>
          </Tag>
        )}
        {row.computed_fields.is_sanction && (
          <Tag noColor className={"mx-1 my-1 text-white bg-red-500"}>
            SAN
          </Tag>
        )}
        {row.computed_fields.may_be_pep && (
          <Tag
            noColor
            data-tooltip="Unresolved PEP alerts in Go!Scan"
            className={
              "mx-1 my-1 text-white bg-blue-400 whitespace-nowrap flex items-center space-x-1"
            }
          >
            <QuestionMarkCircleIcon className="w-4 h-4" />
            <span>PEP</span>
          </Tag>
        )}
        {row.computed_fields.is_pep && (
          <Tag noColor className={"mx-1 my-1 text-white bg-blue-500"}>
            PEP
          </Tag>
        )}
      </Link>
    ),
  },
  assignees: {
    title: "",
    className: "text-right",
    headClassName: "justify-end",
    thClassName: "w-24",
    render: (row: CustomerSearchType) => (
      <>
        <AssigneeTag type="customer" id={row.customer_id} />
      </>
    ),
  },
  actions: {
    className: "text-right justify-end",
    headClassName: "justify-end",
    thClassName: "w-24",
    render: (row: CustomerSearchType) => (
      <Link to={ROUTES.CustomerView.replace(":id", row.customer_id)}>
        <Button
          size="sm"
          theme="outlined"
          className="px-2"
          data-tooltip="Open customer"
        >
          <EyeIcon className="w-4 h-4" />
        </Button>
      </Link>
    ),
  },
});

const kytColumns = (contexts: string[]) => {
  return ["all", "in", "out"].reduce(
    (accType, type) => [
      ...accType,
      ...["max", "sum", "min", "count"].reduce(
        (acc, aggreg) => [
          ...acc,
          ...contexts.reduce(
            (acc, context) => [
              ...acc,
              ...[
                1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
              ].map((period) => ({
                [`kyt_data.${context}.${type}.${aggreg}_${period}m`]: {
                  title: `${
                    {
                      max: "Max.",
                      sum: "Sum",
                      min: "Min.",
                      count: "Count",
                    }[aggreg]
                  } ${
                    {
                      all: "",
                      in: "in ",
                      out: "out ",
                    }[type]
                  }${period}m`,
                  orderable: true,
                  className: "w-full text-right whitespace-nowrap justify-end",
                  headClassName: " justify-end text-right grow flex",
                  thClassName: "w-max whitespace-nowrap justify-end text-right",
                  render: (row: CustomerSearchType) => {
                    return (
                      <>
                        {type === "in" && (
                          <ArrowDownCircleIcon className="h-4 w-4 text-green-500" />
                        )}
                        {type === "out" && (
                          <ArrowUpCircleIcon className="h-4 w-4 text-red-500" />
                        )}

                        {aggreg === "count" ? (
                          <Base noColor className={"ml-1 w-max font-mono"}>
                            {formatNumber(
                              (row.kyt_data as any)?.[context]?.[type]?.[
                                `${aggreg}_${period}m`
                              ] || 0
                            )}
                          </Base>
                        ) : (
                          <Base
                            noColor
                            className={
                              "ml-1 w-max font-mono " +
                              amountToColor(
                                (row.kyt_data as any)?.[context]?.[type]?.[
                                  `${aggreg}_${period}m`
                                ] || 0
                              )
                            }
                          >
                            {formatAmount(
                              (row.kyt_data as any)?.[context]?.[type]?.[
                                `${aggreg}_${period}m`
                              ] || 0
                            )}{" "}
                            €
                          </Base>
                        )}
                      </>
                    );
                  },
                },
              })),
            ],
            [] as {
              [key: string]: {
                title: string;
                render: (row: CustomerSearchType) => ReactNode;
              };
            }[]
          ),
        ],
        [] as {
          [key: string]: {
            title: string;
            render: (row: CustomerSearchType) => ReactNode;
          };
        }[]
      ),
    ],
    [] as {
      [key: string]: {
        title: string;
        render: (row: CustomerSearchType) => ReactNode;
      };
    }[]
  );
};

export const VidCell = ({
  code,
  mode,
}: {
  code: string;
  mode: "failed" | "success" | "in_progress" | "outdated" | "none";
}) => {
  const { scenarios } = useScenarios();
  if (mode === "none") return <></>;
  const name =
    scenarios?.data.find((s) => (s.result_label || s.code) === code)?.label ||
    code;
  return (
    <InfoSmall>
      <Tag
        data-tooltip={mode + ": " + name}
        noColor
        className={twMerge(
          "whitespace-nowrap",
          mode === "failed" && "bg-red-500 text-white",
          mode === "success" && "bg-green-500 text-white",
          mode === "in_progress" && "bg-orange-500 text-white",
          mode === "outdated" && "bg-red-800 text-white"
        )}
      >
        {mode}
      </Tag>
    </InfoSmall>
  );
};

const ChatCell = ({
  row,
  closed,
  open,
}: {
  row: CustomerSearchType;
  closed?: boolean;
  open?: boolean;
}) => {
  const { labels } = useMessagesLabels();
  return (
    <Info className="flex items-center space-x-1">
      {open !== false &&
        ((row.computed_fields.open_chat_categories as string) || "")
          .split(/,|;/gm)
          .filter((a) => a)
          .map((a) => (
            <Tag
              key={a}
              data-tooltip="Open tickets categories"
              className="bg-orange-500 text-white flex items-center whitespace-nowrap"
              noColor
            >
              <span className="relative inline-flex rounded-full h-2 w-2 bg-white mr-1"></span>
              <span>{labels.find((l) => l.code === a)?.name || a}</span>
            </Tag>
          ))}
      {closed !== false &&
        ((row.computed_fields.closed_chat_categories as string) || "")
          .split(/,|;/gm)
          .filter((a) => a)
          .map((a) => (
            <Tag
              key={a}
              data-tooltip="Closed tickets categories"
              className=" whitespace-nowrap"
            >
              <span>{labels.find((l) => l.code === a)?.name || a}</span>
            </Tag>
          ))}
    </Info>
  );
};

export const getColumns = (
  fields: CustomFieldType[],
  scenarios:
    | {
        data: ScenarioListType[];
        total: number;
      }
    | undefined,
  kytContexts: string[]
) =>
  Object.assign(
    availableColumns,
    ...kytColumns(kytContexts),
    ...[
      ...(fields
        ?.filter((a) => a.field_source === 3)
        ?.map((a) => ({
          ["custom_fields_labels." + a.label]: {
            title: a.header_name || a.label,
            orderable: true,
            className:
              a.field_type === 2
                ? "w-full text-right whitespace-nowrap justify-end"
                : "",
            headClassName:
              a.field_type === 2 ? "justify-end text-right grow flex" : "",
            thClassName:
              a.field_type === 2
                ? "w-max whitespace-nowrap justify-end text-right"
                : "",
            render: (row: CustomerSearchType) => (
              <Info>
                {a.field_type === 2 && // Number
                  formatNumber((row.custom_fields as any)[a.label])}
                {a.field_type === 3 && // Boolean
                  (row.custom_fields as any)[a.label]}
                {a.field_type === 4 && // String
                  (row.custom_fields as any)[a.label]}
              </Info>
            ),
          },
        })) || []),
      ...(scenarios?.data
        ?.filter((a) => a.enabled && (a.result_label || a.code))
        .map((a) => {
          const code = a.result_label || a.code;
          return {
            ["vid_" + code]: {
              title: code,
              render: (row: CustomerSearchType) => (
                <VidCell
                  code={code}
                  mode={
                    row.computed_fields.vid_failed?.includes(code)
                      ? "failed"
                      : row.computed_fields.vid_success?.includes(code)
                      ? "success"
                      : row.computed_fields.vid_outdated?.includes(code)
                      ? "outdated"
                      : row.computed_fields.vid_in_progress?.includes(code)
                      ? "in_progress"
                      : "none"
                  }
                />
              ),
            },
          };
        }) || []),
    ]
  );
