import Link from "@atoms/link";
import { BaseSmall } from "@atoms/text";
import { ROUTES } from "@features/routes";
import {
  AcademicCapIcon,
  Bars3Icon,
  LifebuoyIcon,
} from "@heroicons/react/24/outline";
import { useRecoilState } from "recoil";
import { Account } from "./account";
import { Search } from "./search";
import { SidebarOpenAtom } from "./sidebar";
import { useHasAccess } from "@features/auth/state/use-access";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useRecoilState(SidebarOpenAtom);
  const hasAccess = useHasAccess();
  return (
    <div className="bg-slate-600 h-11 flex flex-row justify-center items-center px-2 sm:px-4 min-h-0 shrink-0 z-60">
      <Logo url={hasAccess("MONITORING") ? ROUTES.Dashboard : ROUTES.Customers} />
      <div
        onClick={() => setMenuOpen(!menuOpen)}
        className="flex flex-row items-center justify-center mr-4 cursor-pointer sm:hidden"
      >
        <Bars3Icon className="h-5 text-white" />
      </div>
      <div className="grow flex">
        <Search />
      </div>
      <div className="flex flex-row items-center justify-center ml-4">
        <Links />
        <Account />
      </div>
    </div>
  );
};

const Logo = ({url}: {url: string}) => (
  <Link
    to={url}
    noColor
    className="flex-row items-center mr-4 md:mr-4 hidden sm:flex"
  >
    <img alt="Algoreg" src="/images/logo-text.svg" className="h-5" />
  </Link>
);

const Links = () => (
  <>
    <Link
      target="_BLANK"
      href="https://docs.algoreg.com"
      noColor
      className="hidden md:inline-flex flex-row text-white"
    >
      <AcademicCapIcon className="h-5 mr-2" />
      <BaseSmall noColor>Guides</BaseSmall>
    </Link>
    <Separator />

    {false && (
      <>
        <Link
          target="_BLANK"
          href="mailto:support@algoreg.com"
          noColor
          className="hidden md:inline-flex flex-row text-white"
        >
          <LifebuoyIcon className="h-5 mr-2" />
          <BaseSmall noColor>Support</BaseSmall>
        </Link>
        <Separator />
      </>
    )}
  </>
);

const Separator = () => (
  <div className="hidden md:inline h-5 mx-4 border-solid border-r border-white opacity-50 inline-block"></div>
);
