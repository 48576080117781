import { Button } from "@atoms/button/button";
import { FullScreenPage } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import RiskFactorsView from "@components/risks";
import { RisksHeader } from "@components/risks/header";
import { useHasAccess } from "@features/auth/state/use-access";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { ROUTES } from "@features/routes";
import { useNavigate, useParams } from "react-router-dom";

export const KytRulePage = () => {
  const decisionResource = "kyt";

  const hasAccess = useHasAccess();
  const navigate = useNavigate();

  const { id } = useParams();
  const { riskFactors } = useRiskFactors(decisionResource);

  return (
    <FullScreenPage>
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-col mx-auto text-black dark:text-white w-full">
          <div className="p-4 pb-0 pt-6 mb-6">
            <div className="float-right">
              <Button
                size="sm"
                theme="outlined"
                onClick={() => navigate(ROUTES.KytRules)}
              >
                Back to list
              </Button>
            </div>
            <Title className="flex flex-row justify-start items-center">
              {riskFactors.find((factor) => factor.id + "" === id + "")
                ?.label || "Risk factor"}
              <div className="inline-block ml-4">
                <RisksHeader
                  editable={hasAccess("KYT_MANAGE")}
                  resource={decisionResource}
                />
              </div>
            </Title>
          </div>
        </div>
        <div className="grow">
          <RiskFactorsView
            editable={hasAccess("RISK_SETTINGS_WRITE")}
            type={decisionResource}
          />
        </div>
      </div>
    </FullScreenPage>
  );
};
