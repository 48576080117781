import { LinkConfirm } from "@atoms/link/confirm";
import { useMatrixEntries } from "@features/custom-matrix/state/use-matrix-entries";
import { formatDuration, formatTime } from "@features/utils/dates";
import parsePhoneNumber from "libphonenumber-js";

import { Info, SectionSmall } from "@atoms/text";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomerAllDetailType } from "@features/customers/types";

import _ from "lodash";

export const CustomFields = ({
  customer,
  displayByRelatedProduct,
}: {
  customer: CustomerAllDetailType;
  displayByRelatedProduct:
    | "header"
    | "identity"
    | "go!scan"
    | "go!vid"
    | "go!risk"
    | "go!chat"
    | "go!press"
    | "go!kyt"
    | "none";
}) => {
  const { fields } = useCustomFields();
  let customFields = customer?.details.custom_fields.filter((cf) =>
    fields
      .filter((f) => f.field_id === cf.id)
      .map((f) => f.format.related_products)
      .flat()
      .includes(displayByRelatedProduct)
  );

  if (displayByRelatedProduct === "none") {
    customFields = customer?.details.custom_fields.filter(
      (cf) =>
        fields
          .filter((f) => f.field_id === cf.id)
          .filter(
            (f) =>
              f.format.related_products?.length === undefined ||
              f.format.related_products?.length === 0
          ).length === 1
    );
  }

  const groups = _.sortBy(
    _.uniq(
      customFields
        .map((cf) =>
          fields.filter((f) => f.field_id === cf.id).map((f) => f.group)
        )
        .flat()
    ),
    (group) => group || "zzzzz"
  );

  return (
    <>
      {customFields.length > 0 && (
        <>
          {displayByRelatedProduct !== "none" && <hr className="my-4 -mx-4" />}
          {groups.map((group) => (
            <div
              key={group}
              className={displayByRelatedProduct !== "none" ? "mt-4" : ""}
            >
              <SectionSmall>{group || "Other fields"}</SectionSmall>
              <div className="mt-2 grid grid-cols-4 gap-y-4">
                {_.sortBy(
                  customFields.filter((cf) =>
                    fields
                      .filter((f) => f.group === group)
                      .map((f) => f.field_id)
                      .includes(cf.id)
                  ),
                  (cf) => cf.name
                ).map((f) => (
                  <div key={f.id}>
                    <Info>
                      {fields.find((a) => a.field_id === f.id)?.header_name}
                    </Info>
                    <br />
                    {formatFieldValue(
                      f.value,
                      fields.find((a) => a.field_id === f.id)?.format
                    )}
                  </div>
                ))}
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
};

export const formatFieldValue = (
  value: any,
  format: { type?: string; multiple?: boolean } = {}
) => {
  if (format.multiple) {
    return (
      <>
        {(typeof value === "string" ? value.split(/,|;/) : value || [])
          .map((a: string) => a.trim())
          .map((v: any, i: number) => (
            <span key={i}>
              {i > 0 ? ", " : ""}
              {formatFieldValue(v, { type: format?.type })}
            </span>
          ))}
      </>
    );
  }

  if (
    value === undefined ||
    value === null ||
    Number.isNaN(value) ||
    value === ""
  ) {
    return "-";
  }

  if (typeof value === "boolean") {
    return value ? "Yes" : "No";
  }

  if (format.type?.match(/currency_/)) {
    return Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: format.type.split("_")[1],
    }).format(value || 0);
  }

  if (format.type === "number") {
    return Intl.NumberFormat(navigator.language, {}).format(value || 0);
  }

  if (format.type?.match(/date_/)) {
    const isSeconds = format.type.split("_")[1] === "s";
    const time = (value || 0) * (isSeconds ? 1000 : 1);
    return formatTime(time);
  }

  if (format.type?.match(/elapsed_/)) {
    const isSeconds = format.type.split("_")[1] === "s";
    const time = (value || 0) * (isSeconds ? 1000 : 1);
    return formatDuration(time);
  }

  if (format.type === "iban") {
    return (value || "").replace(/(.{4})/g, "$1 ").toUpperCase();
  }

  if (format.type === "phone") {
    return parsePhoneNumber(value || "")?.formatInternational() || value;
  }

  if (format.type === "url") {
    return <LinkConfirm href={value}>{value}</LinkConfirm>;
  }

  if (format.type?.match(/matrix_/)) {
    return <MatrixValue value={value} matrixId={format.type.split("_")[1]} />;
  }

  return value;
};

const MatrixValue = ({ value, matrixId }: { value: any; matrixId: string }) => {
  const { matrix } = useMatrixEntries(matrixId);
  const label = matrix.find((a) => a.key === value)?.label || value;
  const color = matrix.find((a) => a.key === value)?.value || "U";
  return (
    <span
      className={
        (
          {
            H: "text-red-600",
            M: "text-yellow-600",
            L: "text-green-600",
          } as any
        )[color] || ""
      }
    >
      {label}
    </span>
  );
};
