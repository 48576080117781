import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { KytApiClient } from "@features/kyt/api-client/api-client";
import { useCurrencies } from "@features/kyt/state/use-currencies";
import { KytCurrenciesWithID } from "@features/kyt/types";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";

export const CurrencyEditionModalAtom = atom({
  key: "CurrencyEditionModalAtom",
  default: {
    open: false,
    currency: undefined as KytCurrenciesWithID | undefined,
  },
});

export const CurrencyEditionModal = () => {
  const [currencyModal, setCurrencyModal] = useRecoilState(
    CurrencyEditionModalAtom
  );

  return (
    <SideModal
      className="bg-slate-100 dark:bg-slate-900"
      open={currencyModal.open}
      onClose={() => {
        setCurrencyModal({ open: false, currency: undefined });
      }}
    >
      {currencyModal.open && <CurrencyEditionModalContent />}
    </SideModal>
  );
};

export const CurrencyEditionModalContent = () => {
  const { currencies } = useCurrencies();
  const [currencyModal, setCurrencyModal] = useRecoilState(
    CurrencyEditionModalAtom
  );
  const [form, setForm] = useState<KytCurrenciesWithID>();

  useEffect(() => {
    setForm({
      ID: currencyModal.currency?.ID ?? null,
      code: currencyModal.currency?.code ?? null,
      label: currencyModal.currency?.label ?? null,
      price: currencyModal.currency?.price ?? null,
    });
  }, [currencyModal.currency]);

  return (
    <ModalContent
      title={
        currencyModal.currency ? (
          <div className="flex flex-row items-center">Edit Currency</div>
        ) : (
          "New Currency"
        )
      }
    >
      <div style={{ maxWidth: "800px", width: "100vw" }} className="space-y-4">
        <div className="flex flex-row space-x-2">
          <InputLabel
            label="Code"
            className="grow"
            input={
              <Input
                placeholder="Code"
                value={form?.code ?? ""}
                onChange={(e) => {
                  setForm({ ...form!!, code: e.target.value });
                }}
              />
            }
          />
          <InputLabel
            label="Label"
            className="grow"
            input={
              <Input
                placeholder="Label"
                value={form?.label ?? ""}
                onChange={(e) => setForm({ ...form!, label: e.target.value })}
              />
            }
          />
        </div>
        <InputLabel
          label="Price"
          className="grow"
          input={
            <Input
              type="number"
              step="0.01"
              placeholder="0.01"
              value={form?.price ?? 0}
              onChange={(e) =>
                setForm({ ...form!, price: parseFloat(e.target.value) })
              }
            />
          }
        />
        <ButtonConfirm
          className="float-right"
          theme="outlined"
          disabled={!form?.code || !form?.label || !form?.price}
          onClick={async () => {
            try {
              !currencyModal.currency
                ? await KytApiClient.createCurrency(form!)
                : form?.ID &&
                  (await KytApiClient.updateCurrency(form?.ID, form!));
              currencies.refetch();
              setCurrencyModal({ open: false, currency: undefined });
            } catch (error) {
              toast.error("Error creating currency");
            } finally {
              console.log(form);
            }
          }}
        >
          {currencyModal.currency ? "Edit" : "Create"} currency
        </ButtonConfirm>
      </div>
    </ModalContent>
  );
};
