import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import Text, { Base, Info } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useMatrixEntries } from "@features/custom-matrix/state/use-matrix-entries";
import {
  CustomMatrixType,
  MatrixEntryType,
} from "@features/custom-matrix/types";
import {
  RISKS_COLORS,
  RISKS_LABELS,
  RisksCustomMatrix,
} from "@features/custom-matrix/utils";
import { PencilSquareIcon, TrashIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import CustomMatrixCsv from "./custom-matrix-csv";
import { formatTime } from "@features/utils/dates";
import { useRecoilState } from "recoil";
import { CustomMatrixEntrySortByAtom } from "@features/custom-matrix/state/store";

export default function CustomMatrixEntries(props: {
  matrix?: CustomMatrixType;
  onClose: () => void;
}) {
  const {
    matrix: matrixEntries,
    refresh,
    createMatrixEntry,
    deleteMatrixEntry,
    editMatrixEntry,
    loading,
  } = useMatrixEntries(props.matrix?.id ?? "");
  const hasAccess = useHasAccess();

  const [sortBy, setSortBy] = useRecoilState(CustomMatrixEntrySortByAtom);

  const entries = useMemo(() => {
    const sorted = _.sortBy(matrixEntries, sortBy.key);
    return sortBy.order === "DESC" ? sorted.reverse() : sorted;
  }, [matrixEntries, sortBy]);

  const keyToIndex: { [key: string]: number } = {
    label: 0,
    created_at: 3,
  };

  const [modal, setModal] = useState<
    Partial<MatrixEntryType> & { open: boolean }
  >({ open: false });

  useEffect(() => {
    if (props.matrix) {
      refresh();
    }
  }, [props.matrix, refresh]);

  if (!props.matrix) return null;

  const columns: Column<MatrixEntryType>[] = [
    {
      title: "Label",
      orderable: true,
      render: (row) => {
        return <Info>{_.capitalize(row.label)}</Info>;
      },
    },
    {
      title: "Key",
      render: (row) => <Base>{row.key}</Base>,
    },
    {
      title: "Value",
      render: (row) => (
        <Tag
          noColor
          className={
            "rounded px-1  text-sm text-white bg-" + RISKS_COLORS[row.value]
          }
        >
          {RisksCustomMatrix[row.value]}
        </Tag>
      ),
    },
    {
      title: "Last update",
      orderable: true,
      render: (row) => (
        <Tag
          data-tooltip={`${
            row.created_at &&
            formatTime(row.created_at, navigator.language, {
              keepTime: false,
            })
          } by ${row.agent?.name}`}
        >
          {row.created_at &&
            formatTime(row.created_at, navigator.language, {
              keepTime: false,
            })}
        </Tag>
      ),
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) =>
        hasAccess("MATRIX_UPDATE") && (
          <div className="flex flex-row space-x-2">
            <Button
              theme="outlined"
              size="sm"
              onClick={() => {
                setModal({ ...row, open: true });
              }}
            >
              <PencilSquareIcon className="w-4" />
            </Button>
            <ButtonConfirm
              confirmTitle={"Delete entry " + row.label}
              confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks"
              confirmButtonTheme="danger"
              theme="danger"
              size="sm"
              onClick={async () => {
                deleteMatrixEntry(row);
              }}
            >
              <TrashIcon className="w-4" />
            </ButtonConfirm>
          </div>
        ),
    },
  ];

  return (
    <>
      <ModalContent title={props.matrix!.matrix_type}>
        <Modal open={modal.open} onClose={() => setModal({ open: false })}>
          <ModalContent
            title={modal.id ? "Edit matrix entry" : "New matrix entry"}
          >
            <InputLabel
              label="Label"
              input={
                <Input
                  placeholder="Entry Label"
                  value={modal.label || ""}
                  onChange={(e) =>
                    setModal({ ...modal, label: e.target.value })
                  }
                />
              }
            />
            <InputLabel
              className="mt-4"
              label="Key"
              input={
                <>
                  <Info>The key must be unique.</Info>

                  {matrixEntries.findIndex(
                    (entry) =>
                      entry.key.toLowerCase() === modal.key?.toLowerCase() &&
                      entry.id !== modal.id
                  ) !== -1 && (
                    <>
                      <br />
                      <Text type="info-small" className="text-red-500">
                        This key entry already exists !
                      </Text>
                    </>
                  )}

                  <Input
                    placeholder="Entry Key"
                    value={modal.key || ""}
                    onChange={(e) =>
                      setModal({ ...modal, key: e.target.value })
                    }
                  />
                </>
              }
            />

            <InputLabel
              className="mt-4"
              label="Risk Level"
              input={
                <>
                  <Select
                    value={modal.value || ""}
                    placeholder="Select Risk type"
                    onChange={(e) =>
                      setModal({ ...modal, value: e.target.value as any })
                    }
                  >
                    <option disabled value="">
                      Select Risk Level
                    </option>

                    {["L", "M", "H", "C"].map((risk) => (
                      <option key={"risk_" + risk} value={risk}>
                        {_.capitalize(RISKS_LABELS[risk])}
                      </option>
                    ))}
                  </Select>
                </>
              }
            />

            <ButtonConfirm
              className="mt-6 float-right"
              theme="primary"
              confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks"
              loading={loading}
              disabled={
                !modal.label ||
                !modal.key ||
                !modal.value ||
                matrixEntries.findIndex(
                  (entry) =>
                    entry.key.toLowerCase() === modal.key?.toLowerCase() &&
                    entry.id !== modal.id
                ) !== -1
              }
              onClick={async () => {
                if (!modal.id) {
                  await createMatrixEntry({
                    label: modal.label!,
                    key: modal.key!,
                    value: modal.value!,
                  }).then(() => {
                    setModal({ open: false });
                  });
                } else {
                  await editMatrixEntry(modal as MatrixEntryType).then(() => {
                    setModal({ open: false });
                  });
                }
              }}
            >
              Save
            </ButtonConfirm>
          </ModalContent>
        </Modal>

        <div style={{ maxWidth: "800px", width: "100vw" }}>
          <>
            {hasAccess("MATRIX_UPDATE") && (
              <>
                <Button
                  size="sm"
                  onClick={async () => {
                    setModal({
                      open: true,
                      label: "",
                      key: "",
                    });
                  }}
                >
                  Add new entry
                </Button>
                <CustomMatrixCsv
                  matrix={props.matrix}
                  matrixEntries={matrixEntries}
                />
              </>
            )}
            <Table
              columns={columns}
              showPagination={false}
              initialPagination={{
                page: 1,
                perPage: 10,
                order: sortBy.order,
                orderBy: keyToIndex[sortBy.key],
              }}
              onRequestData={async (pagination) => {
                const key = _.invert(keyToIndex)[
                  pagination.orderBy || 0
                ] as keyof MatrixEntryType;
                const order = pagination.order || "ASC";
                setSortBy({ ...sortBy, key, order });
              }}
              data={loading ? [] : entries}
              loading={loading}
            />
          </>
        </div>
      </ModalContent>
    </>
  );
}
