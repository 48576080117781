import { ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { useProfileRecord } from "@features/alerts-namesearch/state/use-profile-record";
import {
  DowJonesAssociate,
  DowJonesRecord,
  DowJonesRecordCategories,
} from "@features/alerts-namesearch/types";
import { useControlledEffect } from "@features/utils";
import { atom, useRecoilState } from "recoil";
import { ListProfileDetails } from "./list-profile-details";

type Type = {
  open: boolean;
  id: string | null;
  record?: DowJonesRecord | null;
  associates?: DowJonesAssociate[] | null;
  categories?: DowJonesRecordCategories | null;
};

export const ProfileModalAtom = atom<Type>({
  key: "ProfileModalAtom",
  default: {
    open: false,
    id: null,
    record: null,
    associates: null,
    categories: null,
  },
});

export const ProfileModal = () => {
  const [modal, setModal] = useRecoilState(ProfileModalAtom);
  return (
    <SideModal
      className="bg-slate-100 dark:bg-slate-900"
      open={modal.open}
      onClose={() => setModal({ ...modal, open: false })}
    >
      <ProfileModalContent key={modal.id} modal={modal} />
    </SideModal>
  );
};

export const ProfileModalContent = ({ modal }: { modal: Type }) => {
  const { record, associates, refresh } = useProfileRecord(modal.id || "");

  useControlledEffect(() => {
    refresh();
  }, []);

  if (!record) return <></>;

  return (
    <ModalContent title={`Profile ${modal.id}`}>
      <div style={{ maxWidth: "1000px", width: "100vw" }}>
        <ListProfileDetails
          record={record || modal.record}
          associates={
            ((associates?.length || 0) > 0 ? associates : modal.associates) ||
            []
          }
          categories={modal.categories || modal.record?.categories || {}}
        />
      </div>
    </ModalContent>
  );
};
