import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Info, Section } from "@atoms/text";
import { KytContext } from "@features/kyt/types";
import { Button } from "@atoms/button/button";
import { ReviewGroupInput } from "@components/review-group-input";
import { useState } from "react";
import { useContexts } from "@features/kyt/state/use-contexts";

type ContextModalProps = {
  onClose: () => void;
  context: KytContext;
};

export function ContextModal({ onClose, context }: ContextModalProps) {
  const [form, setForm] = useState<KytContext>(context);
  const {
    updateContext,
    createContext,
  } = useContexts();
  return (
    <Modal
      open={true}
      onClose={() => {
        onClose();
      }}
    >
      <ModalContent title={context ? "Edit context" : "New context"}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
          }}
          className="flex flex-col gap-2"
        >
          <div>
            <Section>Name</Section>
            <Input
              value={form.name}
              onChange={(e) => setForm({...form, name: e.target.value.toLowerCase()})}
              placeholder={"Name"}
            />
          </div>
          <div>
            <Section>Review groups</Section>

            <ReviewGroupInput
              canCreateReviewGroup={false}
              value={form.review_groups}
              onChange={(review_groups: string[]) =>
                setForm({ ...form, review_groups })
              }
            />
            <Info>Leave blank for all review groups.</Info>
          </div>
        </form>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse text-center sm:text-left">
          <Button
            theme={"primary"}
            disabled={form.name.trim() === ""}
            onClick={async () => {
              if (form.id) {
                await updateContext(form);
              } else {
                await createContext(form);
              }
              onClose();
            }}
            className="mr-4 my-2"
          >
            Save
          </Button>
          <Button
            onClick={onClose}
            theme="default"
            className={"mr-4 my-2 shadow-none"}
          >
            Cancel
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}
