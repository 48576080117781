import countries from "@assets/countries.json";
import Avatar from "@atoms/avatar/avatar";
import { Tag } from "@atoms/badge/tag";
import { PageBlock } from "@atoms/layout/page-block";
import { Base, Info, InfoSmall, Section } from "@atoms/text";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { useCustomerStates } from "@features/customers/state/user-customer-states";
import { CustomerAllDetailType } from "@features/customers/types";
import {
  CUSTOMERS_RISKS_BACKEND_COLORS,
  CUSTOMERS_RISKS_BACKEND_LABEL,
  extractCustomerFullName,
  STATES_COLORS,
} from "@features/customers/utils";
import { useDashboard } from "@features/dashboard/state/hooks";
import { SessionsApiClient } from "@features/sessions/api-client/api-client";
import { useSessions } from "@features/sessions/state/use-sessions";
import { SessionsRequestOptions } from "@features/sessions/types";
import { getSessionAvatar, getVersion } from "@features/sessions/utils";
import { useControlledEffect } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { formatDistance } from "date-fns";
import { useCallback, useState } from "react";
import { formatFieldValue } from "./custom-fields";
import { twMerge } from "tailwind-merge";

export default function CustomerSummary({
  customer,
}: {
  customer: CustomerAllDetailType;
}) {
  const { risk } = useDashboard();
  const { states } = useCustomerStates();
  const { fetchSessions } = useSessions();
  const [avatar, setAvatar] = useState<{ url: string; type: string }>();
  const { fields } = useCustomFields();
  const customFields = customer?.details.custom_fields ?? [];
  const findLatestSessionWithFace = useCallback(async () => {
    const customerLatestSessions = (
      await fetchSessions({
        external_id: customer.details.customer.external_id,
        on_page: 1,
        per_page: 10,
        order_by: "date",
        order_way: "DESC",
      } as SessionsRequestOptions)
    ).data;
    if (customerLatestSessions.length > 0) {
      let i = 0;
      let tempAvatar = undefined;
      while (!tempAvatar && i < customerLatestSessions.length) {
        const session = await SessionsApiClient.getSession(
          customerLatestSessions[i].session_id
        );
        session.version = getVersion(session);
        tempAvatar = getSessionAvatar(session);
        i++;
      }
      setAvatar(tempAvatar);
    }
  }, []);

  useControlledEffect(() => {
    findLatestSessionWithFace();
  }, []);

  const lastRiskDate = Math.max(
    // Global scan end-date
    new Date(risk?.last_risk_scoring_info?.end_date || 0).getTime(),
    // Customer last updated
    new Date(customer.details.customer.created_at || 0).getTime()
  );

  return (
    <>
      <Section className="mt-4">Customer summary</Section>
      <div className="mt-4 flex flex-row">
        <PageBlock className="grow w-1/2">
          <div className="flex flex-row w-full py-2 items-center">
            {customer.details.customer.customer_type !== "3" &&
              (avatar ? (
                <Avatar
                  size={24}
                  className="mr-4 shrink-0"
                  avatar={avatar.url}
                  type={avatar.type}
                />
              ) : (
                <Avatar size={24} className="mr-4 shrink-0" />
              ))}
            <div className="grow">
              <Section className="flex flex-row items-center">
                {customer.details.customer.customer_type !== "3" ? (
                  <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                ) : (
                  <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                )}
                {extractCustomerFullName(customer.details.customer)}
              </Section>

              <div className="-mt-1">
                <Base className="mr-4">
                  <Info>ID</Info> {customer.details.customer?.external_id}
                </Base>
                {customer.details.customer?.registration_number && (
                  <Base className="mr-4">
                    <Info>Registration Number</Info>{" "}
                    {customer.details.customer?.registration_number}
                  </Base>
                )}
              </div>

              {customer.details.customer?.date_of_birth && (
                <Base className="mr-4">
                  <Info>Date of birth</Info>{" "}
                  {(customer.details.customer?.date_of_birth
                    ? new Date(customer.details.customer?.date_of_birth)
                        .toISOString()
                        .split("T")[0]
                    : null) || "Not defined"}
                </Base>
              )}
              {customer.details.customer?.nationality_codes.length > 0 && (
                <Base className="mr-4">
                  <Info>
                    {"Nationalit" +
                      (customer.details.customer?.nationality_codes.length > 1
                        ? "ies"
                        : "y")}
                  </Info>{" "}
                  {customer.details.customer?.nationality_codes.map((code) => (
                    <>
                      {countries.find((c) => c["alpha-3"] === code)?.name ||
                        "Invalid"}{" "}
                      <Info>({code})</Info>{" "}
                    </>
                  ))}
                </Base>
              )}
              {customer.details.customer?.domicile_codes.length > 0 && (
                <Base className="mr-4">
                  <Info>
                    {"Domicil" +
                      (customer.details.customer?.domicile_codes.length > 1
                        ? "es"
                        : "e")}
                  </Info>{" "}
                  {customer.details.customer?.domicile_codes.map((code) => (
                    <>
                      {countries.find((c) => c["alpha-3"] === code)?.name ||
                        "Invalid"}{" "}
                      <Info>({code})</Info>{" "}
                    </>
                  ))}
                </Base>
              )}

              <div>
                {customFields
                  .filter((cf) =>
                    fields
                      .filter((f) => f.field_id === cf.id)
                      .map((f) => f.format.related_products)
                      .flat()
                      .includes("header")
                  )
                  .map((f) => (
                    <Base key={f.id} className="mr-4">
                      <Info>
                        {fields.find((a) => a.field_id === f.id)?.header_name}
                      </Info>{" "}
                      {formatFieldValue(
                        f.value,
                        fields.find((a) => a.field_id === f.id)?.format
                      )}
                    </Base>
                  ))}
              </div>
            </div>
            <div className="w-1/3 text-left ">
              <div className="flex flex-row">
                <div
                  className={twMerge(
                    "grid grid-cols-1 grid-rows-2 p-2 gap-y-1 bg-opacity-10 rounded rounded-r-none w-1/2 items-center",
                    "bg-" +
                      STATES_COLORS[
                        customer.details.customer.disabled
                          ? "disabled"
                          : `${customer.details.edd_status.edd_state}`
                      ] || "slate-500"
                  )}
                >
                  <div className="flex items-center justify-between">
                    <Tag
                      noColor
                      className={
                        " text-white !px-3 !py-1 text-md bg-" +
                        (STATES_COLORS[
                          customer.details.customer.disabled
                            ? "disabled"
                            : `${customer.details.edd_status.edd_state}`
                        ] || "slate-500")
                      }
                    >
                      {" "}
                      <InfoSmall noColor>Status is</InfoSmall>{" "}
                      <span className="capitalize">
                        {customer.details.customer.disabled
                          ? "Disabled"
                          : states[customer.details.edd_status.edd_state] ||
                            "Unknown"}
                      </span>
                    </Tag>
                  </div>
                  <InfoSmall
                    data-tooltip={new Date(lastRiskDate).toLocaleString()}
                  >
                    {customer.details.edd_status.agent_name || "System"} •{" "}
                    {formatTime(
                      customer.details.edd_status.created_at,
                      undefined,
                      {
                        keepTime: true,
                        keepDate: true,
                      }
                    )}
                  </InfoSmall>
                </div>
                <div
                  className={twMerge(
                    "rounded",
                    "ml-px rounded-l-none grid grid-cols-1 grid-rows-2 p-2 gap-y-1 bg-opacity-10 w-1/2 items-center",
                    "bg-" +
                      CUSTOMERS_RISKS_BACKEND_COLORS[
                        `${customer.details.overall_risk}`
                      ]
                  )}
                >
                  <div className="flex items-center justify-between">
                    <Tag
                      noColor
                      className={
                        "text-white !px-3 !py-1 text-md bg-" +
                        CUSTOMERS_RISKS_BACKEND_COLORS[
                          `${customer.details.overall_risk}`
                        ]
                      }
                    >
                      <InfoSmall noColor>Risk is</InfoSmall>{" "}
                      <span className="capitalize">
                        {CUSTOMERS_RISKS_BACKEND_LABEL[
                          customer.details.overall_risk
                        ] || "Undefined"}
                      </span>
                    </Tag>
                  </div>
                  <InfoSmall
                    data-tooltip={new Date(lastRiskDate).toLocaleString()}
                  >
                    Updated{" "}
                    {formatDistance(lastRiskDate, new Date(), {
                      addSuffix: true,
                    })}
                  </InfoSmall>
                </div>
              </div>
              <div className="mt-2 block space-x-1">
                <div className="inline-block">
                  <InfoSmall>
                    Customer created{" "}
                    <b>
                      {formatTime(
                        customer.edd_history.edd_revisions[
                          customer.edd_history.edd_revisions.length - 1
                        ]?.created_at || customer?.details?.customer?.created_at
                      )}
                    </b>
                  </InfoSmall>
                </div>
                <div className="inline-block">
                  <InfoSmall>review groups</InfoSmall>{" "}
                  {customer?.details?.customer?.review_groups?.map(
                    (group, i) => (
                      <Tag key={i} className="mr-1">
                        {group}
                      </Tag>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
        </PageBlock>
      </div>
    </>
  );
}
