import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import InputSuggestions from "@atoms/input/input-suggestions";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { BaseSmall, Info, Section } from "@atoms/text";
import { ReviewGroupInput } from "@components/review-group-input";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { useBilling } from "@features/billing/use-billing";
import { FIELD_TYPES } from "@features/custom-fields/enum";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import { CustomFieldType, FIELDS_NAMES } from "@features/custom-fields/types";
import { useCustomMatrix } from "@features/custom-matrix/state/use-custom-matrix";
import { LockClosedIcon, SwatchIcon } from "@heroicons/react/20/solid";
import {
  DocumentTextIcon,
  HashtagIcon,
  LightBulbIcon,
  QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";
import _, { capitalize } from "lodash";
import { useEffect, useState } from "react";

export const CustomFieldsTables = () => {
  const { fields, save, loading, refresh } = useCustomFields();
  const [modal, setModal] = useState<
    Partial<CustomFieldType> & { open: boolean }
  >({ open: false });
  const hasAccess = useHasAccess();
  const { matrices, refresh: refreshMatrices } = useCustomMatrix();
  const { plan } = useBilling();

  useEffect(() => {
    refreshMatrices();
    refresh();
  }, [refresh]);

  const columns: Column<CustomFieldType>[] = [
    {
      title: "Identifier",
      render: (row) => (
        <>
          {[2, 4, 5].includes(row.field_source) && (
            <Info className="flex items-center mr-2">
              <LockClosedIcon className="h-3 w-3 inline-block mr-1" /> Internal
              field
            </Info>
          )}
          {![2, 4, 5].includes(row.field_source) && (
            <Info className="flex items-center mr-2">
              <SwatchIcon className="h-3 w-3 inline-block mr-1" /> Custom field
            </Info>
          )}
          {row.label}
        </>
      ),
    },
    {
      title: "Display name",
      render: (row) => (
        <>
          {row.group && (
            <BaseSmall className="flex items-center mr-1 font-bold">
              {row.group} /
            </BaseSmall>
          )}
          {FIELDS_NAMES[row.label] ||
            _.capitalize(row.header_name || row.label.replace(/_/gm, " "))}
        </>
      ),
    },
    {
      title: "Type",
      render: (row) => (
        <Info className="flex items-center mr-2">
          {row.field_type === FIELD_TYPES.NUMBER && (
            <>
              <HashtagIcon className="h-4 w-4 inline-block mr-1" /> Number
            </>
          )}
          {row.field_type === FIELD_TYPES.BOOLEAN && (
            <>
              <LightBulbIcon className="h-4 w-4 inline-block mr-1" /> Boolean
            </>
          )}
          {row.field_type === FIELD_TYPES.TEXT && (
            <>
              <DocumentTextIcon className="h-4 w-4 inline-block mr-1" /> Text
            </>
          )}
          {row.format?.type && (
            <Info className="flex items-center ml-2">
              (
              {row.format?.type.match(/matrix_/)
                ? matrices.find(
                    (a) => a.id === row.format?.type?.split("_")?.[1]
                  )?.matrix_type || "Unknown or deleted matrix"
                : row.format.type}
              {row.format.multiple ? ", multiple" : ""})
            </Info>
          )}
        </Info>
      ),
    },
    {
      title: "Write access",
      render: (row) => <Info>{(row.review_groups || []).join(", ")}</Info>,
    },
    {
      title: "Related products",
      render: (row) => (
        <Info>
          {(row.format?.related_products || [])
            .map((p) => capitalize(p))
            .join(", ")}
        </Info>
      ),
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) =>
        ![2, 4, 5].includes(row.field_source) &&
        hasAccess("SIDENAV_IMPORT_SETUP_SAVE") && (
          <Button
            theme="outlined"
            size="sm"
            onClick={() => setModal({ open: true, ...row })}
          >
            Edit
          </Button>
        ),
    },
  ];

  return (
    <>
      <Modal open={modal.open} onClose={() => setModal({ open: false })}>
        <ModalContent title="Add/Edit custom field">
          <InputLabel
            label="Display name"
            input={
              <Input
                placeholder="My Custom Input"
                value={modal.header_name || ""}
                onChange={(e) =>
                  setModal({ ...modal, header_name: e.target.value })
                }
              />
            }
          />

          <InputLabel
            className="mt-4"
            label="Unique identifier"
            input={
              <Input
                placeholder="my_custom_input"
                value={modal.label || ""}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    label: e.target.value
                      .toLocaleLowerCase()
                      .replace(/[^a-z0-9]+/g, "_"),
                  })
                }
              />
            }
          />

          <div className="my-6 border-t border-slate-200 w-full -mx-6" />

          <InputLabel
            label="Group"
            input={
              <InputSuggestions
                single
                placeholder="My Custom Input"
                debounce={1}
                getSuggestions={async (v) => {
                  return _.uniq([v, ...fields.map((a) => a.group.trim())])
                    .filter((b) => b)
                    .map((v) => ({ value: v, label: v }));
                }}
                value={[modal.group || ""]}
                onChange={(v) => setModal({ ...modal, group: v[0] })}
              />
            }
          />
          <InputLabel
            className="mt-4"
            label="Type"
            input={
              <Select
                value={`${modal.field_type || 4}`}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    field_type: parseInt(e.target.value) as any,
                  })
                }
              >
                <option value="4">Text</option>
                <option value="2">Number</option>
                <option value="3">Boolean</option>
              </Select>
            }
          />
          <InputLabel
            className="mt-4"
            label="Format"
            input={
              <Select
                value={modal.format?.type || ""}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    format: {
                      ...modal.format,
                      type: e.target.value,
                    },
                  })
                }
              >
                <option value="">(aucun)</option>
                {
                  // @ts-ignore
                  (modal.field_type || 4) === 4 && (
                    <>
                      <option value="phone">Phone</option>
                      <option value="email">Email</option>
                      <option value="url">URL</option>
                      <option value="iban">IBAN</option>
                      <option value="" disabled></option>
                      {matrices.map((m) => (
                        <option key={m.id} value={`matrix_${m.id}`}>
                          {m.matrix_type}
                        </option>
                      ))}
                    </>
                  )
                }
                {
                  // @ts-ignore
                  modal.field_type === 2 && (
                    <>
                      <option value="currency_eur">
                        EUR, ex.{" "}
                        {Intl.NumberFormat(navigator.language, {
                          style: "currency",
                          currency: "EUR",
                        }).format(1000.54)}
                      </option>
                      <option value="currency_usd">
                        USD, ex.{" "}
                        {Intl.NumberFormat(navigator.language, {
                          style: "currency",
                          currency: "USD",
                        }).format(1000.54)}
                      </option>
                      <option value="number">
                        Formatted number, ex.{" "}
                        {Intl.NumberFormat(navigator.language, {}).format(
                          1000.54
                        )}
                      </option>
                      <option value="date_s">
                        Date (nombre de secondes), ex. 2024-05-12
                      </option>
                      <option value="date_ms">
                        Date (nombre de ms), ex. 2024-05-12
                      </option>
                      <option value="elapsed_s">
                        Temps écoulé (nombre de secondes), ex. 15 days, 16 hours
                      </option>
                      <option value="elapsed_ms">
                        Temps écoulé (nombre de ms), ex. 15 days, 16 hours
                      </option>
                    </>
                  )
                }
              </Select>
            }
          />
          <InputLabel
            className="mt-4"
            label={
              <div className="flex align-center">
                Related products (display within)
                <QuestionMarkCircleIcon
                  className="ml-1 w-4"
                  data-tooltip={
                    "Defines in which zone(s) or tab(s) of the customer page the info shall be displayed"
                  }
                />
              </div>
            }
            input={
              <SelectMultiple
                value={modal.format?.related_products || []}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    format: {
                      ...modal.format,
                      related_products: e,
                    },
                  })
                }
                options={[
                  {
                    label: "Header",
                    value: "header",
                  },
                  {
                    label: "Identity",
                    value: "identity",
                  },
                  ..._.map(
                    _.uniq(
                      plan.products_enabled.map((p) =>
                        p === "go!checker"
                          ? "go!scan"
                          : p === "go!score"
                          ? "go!risk"
                          : p
                      )
                    ),
                    (p) => ({
                      label: _.capitalize(p),
                      value: p,
                    })
                  ),
                ]}
              />
            }
          />
          {(modal.field_type || 4) === 4 && (
            <div className="mt-4">
              <Checkbox
                label="Allow multiple values separated by comma"
                value={modal.format?.multiple}
                onChange={(e) =>
                  setModal({
                    ...modal,
                    format: {
                      ...modal.format,
                      multiple: e,
                    },
                  })
                }
              />
            </div>
          )}

          <div className="my-6 border-t border-slate-200 w-full -mx-6" />

          <InputLabel
            className="mt-4"
            label="Review groups allowed to edit this field"
            input={
              <ReviewGroupInput
                wildcard
                value={modal.review_groups || []}
                onChange={(reviewGroups) =>
                  setModal({ ...modal, review_groups: reviewGroups })
                }
              />
            }
          />

          <ButtonConfirm
            confirmTitle="Save custom field"
            confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks. if you currently use the CSV customer import, you must update the CSV format accordingly."
            className="mt-6 float-right"
            theme="primary"
            loading={loading}
            disabled={
              !modal.label ||
              !modal.header_name ||
              fields.some(
                (f) => f.field_id !== modal.field_id && f.label === modal.label
              )
            }
            onClick={async () => {
              if (
                await save([
                  ...fields.filter((f) => f.field_id !== modal.field_id),
                  {
                    review_groups: modal.review_groups || [],
                    format: modal.format || {},
                    group: modal.group || "",
                    field_source: modal.field_source || 2,
                    field_type: modal.field_type || 4,
                    header_name: modal.header_name || "",
                    label: modal.label || "",
                  },
                ])
              ) {
                setModal({ ...modal, open: false });
              }
            }}
          >
            Save
          </ButtonConfirm>

          {fields.find((f) => f.field_id === modal.field_id) && (
            <ButtonConfirm
              confirmTitle="Delete custom field"
              confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks."
              className="mt-6"
              theme="danger"
              loading={loading}
              onClick={async () => {
                if (await save(fields.filter((f) => f.label !== modal.label))) {
                  setModal({ ...modal, open: false });
                }
              }}
            >
              Remove
            </ButtonConfirm>
          )}
        </ModalContent>
      </Modal>

      {hasAccess("SIDENAV_IMPORT_SETUP_SAVE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
              field_source: 3,
              review_groups: ["*"],
            })
          }
        >
          Add customer field
        </Button>
      )}
      <Section>Customers fields</Section>

      <Table
        data={_.sortBy(
          fields.filter(
            (a) =>
              a.field_source === 2 ||
              a.field_source === 4 ||
              a.field_source === 3
          ),
          [(a) => a.field_source !== 3, "group", "display_name"]
        )}
        columns={columns}
        showPagination={false}
        loading={loading}
      />

      <br />
      <br />
      <br />

      {hasAccess("SIDENAV_IMPORT_SETUP_SAVE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
              field_source: 6,
              review_groups: ["*"],
            })
          }
        >
          Add customer relation field
        </Button>
      )}
      <Section>Customers Relations fields</Section>
      <Table
        data={_.sortBy(
          fields.filter((a) => a.field_source === 6 || a.field_source === 5),
          [(a) => a.field_source !== 6, "group", "display_name"]
        )}
        columns={columns}
        showPagination={false}
        loading={loading}
      />

      <br />
      <br />
      <br />

      {hasAccess("SIDENAV_IMPORT_SETUP_SAVE") && (
        <Button
          className="float-right"
          size="sm"
          onClick={() =>
            setModal({
              open: true,
              field_source: 8,
              review_groups: ["*"],
            })
          }
        >
          Add transaction field
        </Button>
      )}
      <Section>Transactions fields</Section>

      <Table
        data={_.sortBy(
          fields.filter((a) => a.field_source === 7 || a.field_source === 8),
          [(a) => a.field_source !== 7, "group", "display_name"]
        )}
        columns={columns}
        showPagination={false}
        loading={loading}
      />
    </>
  );
};
