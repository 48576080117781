import { AppPermissions } from "@features/access";
import { AuthenticationJSON } from "@passwordless-id/webauthn/dist/esm/types";
export enum TwoFAType {
  WebAuthn = 0,
  Totp = 1,
}

export const TwoFATypes = {
  [TwoFAType.WebAuthn]: {
    label: "Access Key",
    value: TwoFAType.WebAuthn,
    path: "webauthn",
  },
  [TwoFAType.Totp]: {
    label: "Auth App",
    value: TwoFAType.Totp,
    path: "totp",
  },
};

export type Credential = {
  id: string;
  type: TwoFAType;
};

export type TwoFALoginResponse = {
  challenge: string;
  credential_ids: string[];
};

export type TwoFAChallengeData = AuthenticationJSON | Record<string, string>;

export const NeedTwoFACode = 7004;

export class NeedTwoFAError extends Error { }

export type PermissionKey = (keyof typeof AppPermissions);

export type AllowedAcosResponse = {
  acos: PermissionKey[],
  acos_to_review_groups: Partial<{ [key in PermissionKey]: string[] }>
}

export type AllowedAcos = {
  acos: string[],
  acos_to_review_groups: { [key: string]: string[] }
}