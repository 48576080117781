import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { PageBlock } from "@atoms/layout/page-block";
import { Loader } from "@atoms/loader";
import { Base, BaseSmall, Info, InfoSmall, SectionSmall } from "@atoms/text";
import { RiskFactorTable } from "@components/risk-factors-table";
import RiskFlow from "@components/risks/risk-flow";
import { useCustomerReviewAlert } from "@features/alerts/state/use-customer-review";
import { useHasAccess } from "@features/auth/state/use-access";
import { FIELD_TYPES } from "@features/custom-fields/enum";
import {
  CustomerAllDetailType,
  CustomerDetailType,
} from "@features/customers/types";
import {
  CUSTOMERS_RISKS_BACKEND_COLORS,
  CUSTOMERS_RISKS_BACKEND_LABEL,
} from "@features/customers/utils";
import { usePressReport } from "@features/press/state/use-press-report";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { ROUTES } from "@features/routes";
import { useCustomerReviewSession } from "@features/sessions/state/use-customer-review";
import { useSessionStates } from "@features/sessions/state/use-sessions-states";
import { useControlledEffect } from "@features/utils";
import { formatTime } from "@features/utils/dates";
import {
  MagnifyingGlassIcon,
  NewspaperIcon,
} from "@heroicons/react/24/outline";
import { CreateSessionModalAtom } from "@views/client/vid/scenario-details/components/create-session-modal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { useSetRecoilState } from "recoil";
import { CustomFields } from "./custom-fields";
import { PressReviewContent } from "./reviews/press";
import { ScanReviewContent } from "./reviews/scan";
import CustomerTransactionsChart from "./transaction-chart";

export const buildTransactionalQueries = (
  customer: Pick<
    CustomerDetailType["customer"],
    | "customer_type"
    | "company_name"
    | "first_name"
    | "last_name"
    | "date_of_birth"
    | "domicile_code"
    | "nationality_code"
  >
) => {
  const countries =
    [customer.domicile_code, customer.nationality_code]
      .filter((a) => a)
      ?.join(",") || "";
  return (
    "?entity_type=" +
    encodeURIComponent(customer.customer_type === "3" ? 2 : 1) +
    "&company_input_data.countries=" +
    encodeURIComponent(countries) +
    "&company_input_data.company_name=" +
    encodeURIComponent(customer.company_name) +
    "&person_input_data.first_name=" +
    encodeURIComponent(customer.first_name) +
    "&person_input_data.countries=" +
    encodeURIComponent(countries) +
    "&person_input_data.last_name=" +
    encodeURIComponent(customer.last_name) +
    "&person_input_data.date_of_birth=" +
    encodeURIComponent(customer.date_of_birth || "")
  );
};

export const buildTransactionalPressQueries = (
  customer: Pick<
    CustomerDetailType["customer"],
    | "customer_type"
    | "company_name"
    | "first_name"
    | "last_name"
    | "date_of_birth"
    | "domicile_code"
    | "nationality_code"
  >
) => {
  const countries =
    [customer.domicile_code, customer.nationality_code]
      .filter((a) => a)
      ?.join(",") || "";
  return (
    "?entity_type=" +
    encodeURIComponent(customer.customer_type === "3" ? 2 : 1) +
    "&country_codes=" +
    encodeURIComponent(countries) +
    "&date_of_birth=" +
    encodeURIComponent(customer.date_of_birth || "") +
    "&name=" +
    encodeURIComponent(
      [customer.company_name, customer.first_name, customer.last_name]
        .filter(Boolean)
        .join(" ")
    )
  );
};

export const RiskReview = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const [riskId, setRiskId] = useState<string | null>(null);
  const { riskFactors, loading } = useRiskFactors("customer");

  useEffect(() => {
    setRiskId(null);
  }, []);

  const selectedRisk = customer.risk_factors?.risk_factors?.find(
    (r) => r.id.toString() === riskId
  );

  const sortedRiskFactors = [...(customer.risk_factors?.risk_factors || [])];
  sortedRiskFactors.sort((a, b) => a.label.localeCompare(b.label));

  return (
    <>
      <PageBlock className="mb-2 !py-3">
        <SectionSmall className="mb-2">Risk scoring</SectionSmall>
        {!!customer.risk_factors.total_number_of_risk_factors && (
          <div className="mb-2">
            <Tag
              noColor
              className={
                "text-white capitalize block !px-3 !py-1 text-md bg-" +
                CUSTOMERS_RISKS_BACKEND_COLORS[
                  `${customer.details.overall_risk}`
                ]
              }
            >
              {CUSTOMERS_RISKS_BACKEND_LABEL[customer.details.overall_risk] ||
                "Undefined"}
            </Tag>
          </div>
        )}
        {!customer.risk_factors.total_number_of_risk_factors && (
          <Info className="block mt-2">The customer was not scored yet.</Info>
        )}
        {customer.details.overall_risk === 1 && (
          <Info className="block mt-2">
            No risk scoring configuration has been defined, or the customer has
            not been scored yet by our risk engine.
          </Info>
        )}
        {customer.details.overall_risk === 2 && (
          <Info className="block mt-2">
            None of the risk scoring rules have been triggered for this
            customer.
          </Info>
        )}
        {customer.details.overall_risk > 2 && (
          <Info className="block mt-2">
            One or more risk scoring rules have been triggered for this
            customer.
          </Info>
        )}
        <CustomFields customer={customer} displayByRelatedProduct="go!risk" />
      </PageBlock>
      <PageBlock>
        {customer.risk_factors && (
          <>
            <SectionSmall className="my-2">Risk scoring details</SectionSmall>
            <div className={"w-full"}>
              {!!customer.risk_factors.risk_factors?.length && (
                <GetStartedCard
                  title="Click on any risk factor to get more details."
                  text="A preview of the decision tree as well as information used for the decision will be displayed."
                  className="my-2"
                />
              )}
              {!customer.risk_factors.risk_factors?.length && (
                <Info className="block">
                  No risk factors has been found for this customer.
                </Info>
              )}
              <div className="flex flex-col">
                <RiskFactorTable
                  factors={riskFactors.map((a) => ({
                    ...a,
                    risk: customer.risk_factors?.risk_factors?.find(
                      (b) => b.label === a.label
                    )?.risk as any,
                  }))}
                  loading={loading}
                  actions={(item) => (
                    <>
                      <Button
                        size="sm"
                        theme="outlined"
                        onClick={() => {
                          setRiskId(item.id.toString());
                        }}
                      >
                        Review
                      </Button>
                    </>
                  )}
                />
              </div>
            </div>
            {riskId && (
              <div className="mt-4 h-2/3 flex flex-col border rounded">
                <div className="flex flex-row bg-slate-100 px-2 pt-2">
                  <SectionSmall className="grow">
                    {selectedRisk?.label}
                  </SectionSmall>
                  <Tag
                    noColor
                    className={
                      "text-white capitalize block !px-3 !py-1 text-md mr-1 bg-" +
                      CUSTOMERS_RISKS_BACKEND_COLORS[selectedRisk?.risk || 1]
                    }
                  >
                    {CUSTOMERS_RISKS_BACKEND_LABEL[selectedRisk?.risk || 1]}
                  </Tag>
                </div>

                <div className="bg-slate-100 border-b px-2 pb-2">
                  {((selectedRisk?.fields.length || 0) > 1 ||
                    selectedRisk?.fields?.[0]?.field_type !==
                      FIELD_TYPES.BOOLEAN) &&
                    selectedRisk?.fields.map((field) => (
                      <BaseSmall className="block" key={field.name}>
                        •{" "}
                        <span className="font-semibold capitalize">
                          {field.name}:
                        </span>{" "}
                        {field.value === false
                          ? "No"
                          : field.value === true
                          ? "Yes"
                          : field.value}
                      </BaseSmall>
                    ))}
                </div>
                <div className="h-96">
                  <ReactFlowProvider>
                    <RiskFlow editable={false} id={riskId} type={"customer"} />
                  </ReactFlowProvider>
                </div>
              </div>
            )}
          </>
        )}
      </PageBlock>
    </>
  );
};

export const KYTReview = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  return (
    <PageBlock className="mb-2 !py-3">
      <CustomerTransactionsChart
        customer={customer}
        retractableDetails={false}
      />
      <CustomFields customer={customer} displayByRelatedProduct="go!kyt" />
    </PageBlock>
  );
};

export const AlertsReview = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const hasAccess = useHasAccess();

  const { refresh: refreshAlertsReview } = useCustomerReviewAlert(
    customer?.details.customer.external_id || ""
  );

  useControlledEffect(() => {
    refreshAlertsReview();
  }, []);

  return (
    <PageBlock className="mb-2 !py-3">
      {hasAccess("SIDENAV_ALERT") && (
        <>
          <Link
            to={ROUTES.Alerts + "?id=" + customer.details.customer.external_id}
          >
            <Button size="sm" theme="outlined" className="float-right ml-2">
              Open Go!Scan
            </Button>
          </Link>
          <Link
            to={
              ROUTES.NameSearch +
              buildTransactionalQueries(customer.details.customer)
            }
          >
            <Button size="sm" theme="outlined" className="float-right">
              <MagnifyingGlassIcon className="h-5 w-5 mr-1 -ml-1" />
              Name search
            </Button>
          </Link>
        </>
      )}

      <SectionSmall>Screening</SectionSmall>
      <ScanReviewContent customer={customer} />
      <CustomFields customer={customer} displayByRelatedProduct="go!scan" />
    </PageBlock>
  );
};

export const PressReview = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const hasAccess = useHasAccess();
  const { report, refresh, focusReportBody } = usePressReport(
    customer.details.customer.id
  );

  useControlledEffect(() => {
    refresh();
  }, []);

  useControlledEffect(() => {
    if (report?.latest_revision?.report_body_id) {
      focusReportBody(report.latest_revision.report_body_id);
    }
  }, [report]);

  return (
    <PageBlock className="mb-2 !py-3">
      {hasAccess("PRESS_AGENT") && (
        <>
          <Link
            to={ROUTES.PressReportView.replace(
              ":customer_id",
              customer.details.customer.id
            )}
          >
            <Button size="sm" theme="outlined" className="float-right ml-2">
              Open Go!Press
            </Button>
          </Link>

          <Link
            to={
              ROUTES.PressInstantReport +
              buildTransactionalPressQueries(customer.details.customer)
            }
          >
            <Button
              size="sm"
              theme="outlined"
              className="float-right"
              disabled={!report?.latest_revision?.report_body_id}
            >
              <NewspaperIcon className="h-5 w-5 mr-1 -ml-1" />
              Press Report
            </Button>
          </Link>
        </>
      )}

      <SectionSmall>Press</SectionSmall>

      <PressReviewContent customer={customer} />
      <CustomFields customer={customer} displayByRelatedProduct="go!press" />
    </PageBlock>
  );
};

export const OnboardingReview = ({
  customer,
}: {
  customer: CustomerAllDetailType;
}) => {
  const hasAccess = useHasAccess();
  const { states } = useSessionStates();
  const { review, refresh } = useCustomerReviewSession(
    customer.details.customer.external_id
  );
  const setModal = useSetRecoilState(CreateSessionModalAtom);

  useControlledEffect(() => {
    refresh();
  }, []);

  return (
    <PageBlock className="mb-2 !py-3">
      {hasAccess("SIDENAV_SESSION") && (
        <>
          <Link
            className="float-right ml-2"
            to={
              ROUTES.Sessions +
              "?external_id=" +
              customer.details.customer.external_id
            }
          >
            <Button size="sm" theme="outlined" className="float-right">
              Open Go!Vid
            </Button>
          </Link>

          <Button
            size="sm"
            theme="outlined"
            className="float-right"
            onClick={() => {
              setModal({
                open: true,
                showScenarioSelector: true,
                session: {
                  scenario_code: "",
                  external_id: customer.details.customer.external_id,
                  language: "",
                },
              });
            }}
          >
            Create a Session
          </Button>
        </>
      )}

      <SectionSmall>Scenarios</SectionSmall>

      {!review && <Loader />}

      {!!review && (
        <>
          {Object.keys(review.per_state).length === 0 && (
            <>
              <Info>This customer never completed any session.</Info>
            </>
          )}

          {Object.keys(review.per_state).length > 0 && (
            <>
              <div className="mt-2">
                <Base>Overall KYC status</Base>
                <div className="-mx-1">
                  {(customer.details.vid_success.value as string)
                    .split(",")
                    .map((t, i) => (
                      <Tag
                        key={i}
                        noColor
                        className="m-1 bg-green-500 text-white"
                        data-tooltip="Complete and validated"
                      >
                        {t}
                      </Tag>
                    ))}
                  {(customer.details.vid_in_progress.value as string)
                    .split(",")
                    .map((t, i) => (
                      <Tag
                        key={i}
                        noColor
                        className="m-1 bg-yellow-500 text-white"
                        data-tooltip="In Progress"
                      >
                        {t}
                      </Tag>
                    ))}
                  {(customer.details.vid_outdated.value as string)
                    .split(",")
                    .map((t, i) => (
                      <Tag className="m-1" key={i} data-tooltip="Outdated">
                        {t}
                      </Tag>
                    ))}
                  {(customer.details.vid_failed.value as string)
                    .split(",")
                    .map((t, i) => (
                      <Tag
                        key={i}
                        noColor
                        className="m-1 bg-red-500 text-white"
                        data-tooltip="Refused"
                      >
                        {t}
                      </Tag>
                    ))}
                </div>
              </div>
              <div className="mt-4">
                <Base>Latest sessions</Base>
                {Object.keys(review.per_state).map((state) => {
                  const lastSession = review.per_state[state]?.[0];
                  const statusText = lastSession?.status;
                  const status = states.find((s) => s.label === statusText);
                  return (
                    <Link
                      key={state}
                      to={ROUTES.SessionView.replace(
                        ":id",
                        lastSession.session_id
                      )}
                    >
                      <div className="mt-2">
                        <Info>{state}</Info>
                        <InfoSmall>
                          {" "}
                          • Last session {formatTime(lastSession.end_timestamp)}
                        </InfoSmall>
                        <br />
                        <Tag
                          noColor
                          className={
                            "text-white " +
                            (status?.type === "POSITIVE"
                              ? "bg-green-500"
                              : status?.type === "NEGATIVE"
                              ? "bg-red-500"
                              : status?.type === "NEUTRAL"
                              ? "bg-slate-500"
                              : "bg-yellow-500")
                          }
                        >
                          <InfoSmall noColor>Latest:</InfoSmall>{" "}
                          {status?.label || "No status yet"}
                        </Tag>
                      </div>
                    </Link>
                  );
                })}
              </div>
            </>
          )}
          <CustomFields customer={customer} displayByRelatedProduct="go!vid" />
        </>
      )}
    </PageBlock>
  );
};
