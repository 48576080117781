import { Checkbox } from "@atoms/input/input-checkbox";
import { Role } from "@components/roles-manager";
import { Table } from "@components/table";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { AgentRole } from "@features/agents/types";

type RoleReviewGroupsProps = {
  roles: AgentRole[];
  defaultReviewGroups: string[];
  onChange: (role: AgentRole) => void;
};

export function RoleReviewGroups({
  roles,
  defaultReviewGroups,
  onChange,
}: RoleReviewGroupsProps) {
  const { all: reviewGroups } = useReviewGroups({});
  return (
    <Table
      showPagination={false}
      columns={[
        {
          render: (role) => (
            <Role code={role.code} className="h-8 px-3 font-semibold" />
          ),
        },
        ...reviewGroups.map((reviewGroup) => ({
          title: reviewGroup,
          render: (role: AgentRole) => (
            <Checkbox
              disabled={!role.review_groups || (reviewGroup != "*" && role.review_groups.includes("*"))}
              value={
                role.review_groups
                  ? role.review_groups.includes("*") ||
                    role.review_groups.includes(reviewGroup)
                  : defaultReviewGroups.includes("*") || defaultReviewGroups.includes(reviewGroup)
              }
              onChange={(value) => {
                const newRole: AgentRole = {
                  code: role.code,
                  review_groups: role.review_groups,
                };
                if (!value) {
                  newRole.review_groups = role.review_groups?.filter(
                    (review_group) => review_group != reviewGroup
                  );
                } else if (reviewGroup == "*") {
                  newRole.review_groups = ["*"];
                } else {
                  newRole.review_groups = [...role.review_groups!, reviewGroup];
                }

                onChange(newRole);
              }}
            />
          ),
        })),
        {
          render: (role) => (
            <Checkbox
              value={role.review_groups == null}
              onChange={(value) => {
                const newRole: AgentRole = { code: role.code };
                if (!value) {
                  newRole.review_groups = defaultReviewGroups;
                }

                onChange(newRole);
              }}
            />
          ),
          title: "Default",
        },
      ]}
      data={roles}
    />
  );
}
