import countries from "@assets/countries.json";
import { Button } from "@atoms/button/button";
import InputCopiable from "@atoms/input/input-copiable";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Info } from "@atoms/text";
import { ScenariosApiClient } from "@features/scenarios/api-client/api-client";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import {
  SCENARIOS_LANGUAGES,
  getAvailableLanguages,
} from "@features/scenarios/utils";
import { SessionsApiClient } from "@features/sessions/api-client/api-client";
import { CreateSessionInput } from "@features/sessions/types";
import CustomerSearchInput from "@views/client/inbox/components/customer-search-input";
import { useCallback, useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

export const CreateSessionModalAtom = atom<{
  session: CreateSessionInput | undefined;
  showScenarioSelector: boolean;
  open: boolean;
}>({
  key: "CreateSessionModalAtom",
  default: {
    session: undefined,
    showScenarioSelector: false,
    open: false,
  },
});

export default function CreateSessionModal() {
  const [modal, setModal] = useRecoilState(CreateSessionModalAtom);
  const { scenarios, refresh } = useScenarios();
  const [scenarioLanguages, setScenarioLanguages] = useState<string[]>([]);
  const [loadingGeneration, setloadingGeneration] = useState(false);
  const [sessionUrl, setSessionUrl] = useState<string | null>(null);

  useEffect(() => {
    setSessionUrl(null);
  }, [modal.open]);

  useEffect(() => {
    if (!scenarios) {
      refresh();
    }
  }, [refresh, scenarios]);

  useEffect(() => {
    // find the scenario
    if (!modal.session?.scenario_code) return;
    const scenario = scenarios?.data.find(
      (scenario) => scenario.code === modal.session?.scenario_code
    );
    if (!scenario) {
      setScenarioLanguages([]);
    }
    // fetch the details -- [IZR] Improve this by integration Languages in ScenarioList
    ScenariosApiClient.getScenario(scenario!.id).then((scenarioDetails) => {
      const langs = getAvailableLanguages(scenarioDetails);
      // set Languages
      setScenarioLanguages(langs);
    });
  }, [modal.session?.scenario_code]);

  const showCustomerSelector = useCallback(() => {
    return (
      !modal.session?.input_data?.firstname &&
      !modal.session?.input_data?.lastname &&
      !modal.session?.input_data?.birthdate &&
      !modal.session?.input_data?.country
    );
  }, [
    modal.session?.input_data?.firstname,
    modal.session?.input_data?.lastname,
    modal.session?.input_data?.birthdate,
    modal.session?.input_data?.country,
  ]);

  const generateSession = useCallback(async () => {
    const result = await SessionsApiClient.createSessionAndGetHostURL(
      modal.session!
    );
    if (result) {
      setSessionUrl(result);
    }
  }, [modal.session]);

  return (
    <Modal
      open={modal.open}
      onClose={() => {
        setModal({ ...modal, open: false });
      }}
    >
      <ModalContent title="Create Session">
        {sessionUrl ? (
          <div className="flex flex-col space-y-4">
            <Info>Session created successfully.</Info>
            <div className="w-full">
              <InputCopiable value={sessionUrl} readOnly />
            </div>
          </div>
        ) : (
          <div className="flex flex-col space-y-4">
            {modal.showScenarioSelector && (
              <InputLabel
                label="Scenario Session"
                input={
                  <Select
                    value={modal.session?.scenario_code ?? ""}
                    onChange={(e) =>
                      setModal({
                        ...modal,
                        session: {
                          ...modal.session!,
                          scenario_code: e.target.value,
                        },
                      })
                    }
                  >
                    <option disabled value="">
                      Select Scenario
                    </option>
                    {scenarios?.data
                      .filter((scenario) => scenario.enabled)
                      .map((scenario) => (
                        <option key={scenario.code} value={scenario.code}>
                          {scenario.label}
                        </option>
                      ))}
                  </Select>
                }
              />
            )}
            {showCustomerSelector() && (
              <InputLabel
                label="Choose a customer for the session"
                input={
                  <CustomerSearchInput
                    value={
                      modal.session?.external_id
                        ? [modal.session?.external_id]
                        : []
                    }
                    useExternalId
                    onCustomersChange={(customers: string[]) => {
                      setModal({
                        ...modal,
                        session: {
                          ...modal.session!,
                          external_id: customers[customers.length - 1],
                        },
                      });
                    }}
                  />
                }
              />
            )}
            {showCustomerSelector() && !modal.session?.external_id && (
              <Info className="font-bold self-center">OR</Info>
            )}
            {!modal.session?.external_id && (
              <InputLabel
                label="Specify session customer information:"
                input={
                  <div className="flex flex-col space-y-2 mt-2">
                    <InputLabel
                      label="First Name"
                      input={
                        <Input
                          type="text"
                          value={modal.session?.input_data?.firstname ?? ""}
                          placeholder="First Name"
                          onChange={(e) => {
                            setModal({
                              ...modal,
                              session: {
                                ...modal.session!,
                                input_data: {
                                  ...modal.session?.input_data,
                                  firstname: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      label="Last Name"
                      input={
                        <Input
                          type="text"
                          value={modal.session?.input_data?.lastname ?? ""}
                          placeholder="Last Name"
                          onChange={(e) => {
                            setModal({
                              ...modal,
                              session: {
                                ...modal.session!,
                                input_data: {
                                  ...modal.session?.input_data,
                                  lastname: e.target.value,
                                },
                              },
                            });
                          }}
                        />
                      }
                    />
                    <InputLabel
                      label="Birth date"
                      input={
                        <InputDate
                          value={modal.session?.input_data?.birthdate ?? ""}
                          onChange={(date) =>
                            setModal({
                              ...modal,
                              session: {
                                ...modal.session!,
                                input_data: {
                                  ...modal.session?.input_data,
                                  birthdate: date ?? undefined,
                                },
                              },
                            })
                          }
                        />
                      }
                    />
                    <InputLabel
                      label="Country"
                      input={
                        <Select
                          value={modal.session?.input_data?.country ?? ""}
                          onChange={(e) =>
                            setModal({
                              ...modal,
                              session: {
                                ...modal.session!,
                                input_data: {
                                  ...modal.session?.input_data,
                                  country: e.target.value,
                                },
                              },
                            })
                          }
                        >
                          <option disabled value="">
                            Select Country
                          </option>
                          {countries.map((country) => (
                            <option
                              key={country["alpha-3"]}
                              value={country["alpha-3"]}
                            >
                              {country.name + ` (${country["alpha-3"]})`}
                            </option>
                          ))}
                        </Select>
                      }
                    />
                    <InputLabel
                      label="Language"
                      input={
                        <Select
                          value={modal.session?.language ?? ""}
                          onChange={(e) =>
                            setModal({
                              ...modal,
                              session: {
                                ...modal.session!,
                                language: e.target.value,
                              },
                            })
                          }
                        >
                          <option disabled value="">
                            Select Language
                          </option>
                          {Object.keys(SCENARIOS_LANGUAGES)
                            .filter((lang) => scenarioLanguages.includes(lang))
                            .map((lang) => (
                              <option key={lang} value={lang}>
                                {SCENARIOS_LANGUAGES[lang]}
                              </option>
                            ))}
                        </Select>
                      }
                    />
                  </div>
                }
              />
            )}
            <Button
              disabled={!modal.session?.scenario_code}
              theme="primary"
              size="sm"
              loading={loadingGeneration}
              onClick={async () => {
                setloadingGeneration(true);
                await generateSession();
                setloadingGeneration(false);
              }}
            >
              Generate Session
            </Button>
          </div>
        )}
      </ModalContent>
    </Modal>
  );
}
