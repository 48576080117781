import { useCurrencies } from "./state/use-currencies";

export function linkArc(d: any) {
  const r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
  return `
    M${d.source.x},${d.source.y}
    A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
  `;
}
export const sqlQuickQueries = [
  {
    code: "topTransactions",
    label: "Top Transactions",
    query: `
      SELECT *
      FROM kyt_transactions
      ORDER BY amount DESC
      LIMIT 50;
    `,
  },
  {
    code: "topCryptoTransactions",
    label: "Top Crypto Transactions",
    query: `
      SELECT *
      FROM kyt_transactions
      WHERE crypto->>'blockchain' IS NOT NULL AND crypto->>'blockchain' != ''
      ORDER BY amount DESC
      LIMIT 50;
    `,
  },
  {
    code: "interCountryTransactions",
    label: "Top Transactions Inter Countries",
    query: `
      SELECT *
      FROM kyt_transactions
      WHERE "from"->>'domicile_code' IS NOT NULL AND to->>"domicile_code" IS NOT NULL
      AND "from"->>'domicile_code' != "to"->>'domicile_code'
      ORDER BY amount DESC
      LIMIT 50;
    `,
  },
  {
    code: "delayedTransactions",
    label: "Transactions Delayed",
    query: `
      SELECT id, state, date, amount, currency
      FROM kyt_transactions
      WHERE state = 2
      ORDER BY date DESC
      LIMIT 10;
    `,
  },
];

export const cypherQuickQueries = [
  {
    code: "topCustomersByVolume",
    label: "Top Customers by Volume",
    query: `MATCH (n)
            RETURN n.node_id AS NodeID, n.volume_allowed AS AllowedTotalVolume
            ORDER BY n.volume_allowed DESC
            LIMIT 20`,
  },
  {
    code: "topCustomersEdges",
    label: "Top Customers Edges",
    query: `MATCH (n)-[r]->(m)
            RETURN n AS FromNode, r AS Edge, m AS ToNode
            ORDER BY r.allowed_total DESC
            LIMIT 20`,
  },
  {
    code: "topCountTransactions",
    label: "Top Count Transactions",
    query: `MATCH (n)
            RETURN n.node_id AS NodeID, n.count_allowed_total AS CountAllowedTotal
            ORDER BY n.count_allowed_total DESC
            LIMIT 20`,
  },
];

export const kytPatterns: Record<string, string> = {
  triangle: "Triangle Pattern",
  roundTrip: "Round-Trip Pattern",
  multipleAccounts: "Multiple Accounts Pattern",
  rapidMovement: "Rapid Movement Pattern",
};

export function getQueryByPattern(pattern: string): string {
  switch (pattern) {
    case "triangle":
      return "MATCH p=(a:Node)-[:TRANSACTION_ALL]->(b:Node)-[:TRANSACTION_ALL]->(c:Node)-[:TRANSACTION_ALL]->(a) RETURN nodes(p) AS Nodes, relationships(p) AS Edges";
    case "roundTrip":
      return "MATCH p=(a:Node)-[:TRANSACTION_ALL]->(b:Node)-[:TRANSACTION_ALL]->(a) RETURN nodes(p) AS Nodes, relationships(p) AS Edges";
    case "multipleAccounts":
      return "MATCH p=(start:Node)-[:TRANSACTION_ALL*..5]->(end:Node) WHERE start <> end RETURN nodes(p) AS Nodes, relationships(p) AS Edges";
    case "rapidMovement":
      return "MATCH p=(start:Node)-[:TRANSACTION_ALL*..5]->(end:Node) WHERE all(rel in relationships(p) WHERE rel.updated_at >= min([r in relationships(p) | r.updated_at]) AND rel.updated_at <= min([r in relationships(p) | r.updated_at]) + duration('PT24H')) RETURN nodes(p) AS Nodes, relationships(p) AS Edges";
    default:
      return "";
  }
}

// BLOCKED: 3,
export const KYT_TRANSACTION_STATES: {
  [key: number]: string;
} = {
  0: "allowed",
  1: "blocked",
  2: "delayed",
};

export const KYT_TRANSACTION_STATES_COLORS: {
  [key: number]: string;
} = {
  0: "green-500",
  1: "red-500",
  2: "orange-500",
};
export const KYT_TRANSACTION_STATES_LABELS: {
  [key: number]: string;
} = {
  0: "Allowed",
  1: "Blocked",
  2: "Pending",
};

const blockchain = {
  Ethereum: [
    { code: "ETH", label: "Ether (ETH)" },
    { code: "UNI", label: "Uniswap (UNI)" },
    { code: "LINK", label: "Chainlink (LINK)" },
    { code: "WBTC", label: "Wrapped Bitcoin (WBTC)" },
    { code: "AAVE", label: "Aave (AAVE)" },
  ],
  Bitcoin: [
    { code: "BTC", label: "Bitcoin (BTC)" },
    { code: "ETH", label: "Ethereum (ETH)" },
    { code: "ADA", label: "Cardano (ADA)" },
    { code: "SOL", label: "Solana (SOL)" },
    { code: "DOT", label: "Polkadot (DOT)" },
  ],
  "Binance Smart Chain": [
    // { code: "BNB", label: "Binance Coin (BNB)" },
    // { code: "CAKE", label: "PancakeSwap (CAKE)" },
    // { code: "XVS", label: "Venus (XVS)" },
    { code: "BUSD", label: "Binance USD (BUSD)" },
    // { code: "RUNE", label: "THORChain (RUNE)" },
  ],
  Cardano: [
    { code: "ADA", label: "ADA" },
    // { code: "CNT", label: "Cardano Native Token (CNT)" },
    // { code: "MELD", label: "Meld Gold (MELD)" },
    // { code: "LIQ", label: "Liquid Finance (LIQ)" },
    // { code: "SUN", label: "Sunday Swap (SUN)" },
  ],
  Solana: [
    { code: "SOL", label: "Sol (SOL)" },
    // { code: "SRM", label: "Serum (SRM)" },
    // { code: "RAY", label: "Raydium (RAY)" },
    { code: "FIDA", label: "Bonfida (FIDA)" },
    // { code: "SBR", label: "Saber (SBR)" },
  ],
};

export const newBlockchainCurrencies = (): {
  [key: string]: { code: string; label: string }[];
} => {
  const { currencies } = useCurrencies();
  if (!currencies || !currencies.data) {
    console.error("Currencies or currencies.data  is null/undefined");
    return {};
  }

  const list = blockchain["Ethereum"];
  const addCustomCurrencies = currencies.data.map((i: any) => ({
    // Add the word CUSTOM_ so that in a select we don't select, for example, BTC (Bitcoin) and BTC (custom).
    code: "CUSTOM_" + i.code,
    label: i.label,
  }));
  list.push(...addCustomCurrencies);

  const newBlockchain = list.filter(
    (value, index, self) =>
      index ===
      self.findIndex(
        (t: any) => t.code === value.code && t.label === value.label
      )
  );

  blockchain["Ethereum"] = newBlockchain;
  return blockchain;
};
