import {
  DowJonesNameValue,
  DowJonesRecord,
} from "@features/alerts-namesearch/types";
import _ from "lodash";
import { AlertListType, AlertRevision, AlertType } from "./types";

export const defaultAlertStates = {
  new: 2,
  pending: 3,
  true_hit: 4,
  true_hit_accepted: 5,
  true_hit_rejected: 6,
  false_positive: 7,
};

export const ALERTS_STATES_NAMES = {
  2: "New",
  3: "Pending",
  4: "True Hit",
  5: "True Hit Accepted",
  6: "True Hit Rejected",
  7: "False Positive",
  8: "False Positive (Definitive)",
};

export const ALERTS_STATES_COLORS = {
  2: "#FB923C",
  3: "#FCD34D",
  4: "#EF4444",
  5: "#22C5FE",
  6: "#EF4444",
  7: "#475569",
};

export const categoryToColor: any = {
  pep: "blue-500",
  sip: "yellow-500",
  sie: "yellow-500",
  rca: "slate-500",
  rca_pep: "blue-500",
  rca_sip: "yellow-500",
  rca_sie: "yellow-500",
  ame: "rose-500",
  san: "red-500",
  rca_san: "red-500",
  soe: "orange-500",
  rca_soe: "orange-500",
  sop: "orange-500",
  rca_sop: "orange-500",
};

export const categoryToName: any = {
  pep: "PEP",
  sip: "SIP",
  sie: "SIE",
  rca: "RCA",
  rca_pep: "RCA PEP",
  rca_sip: "RCA SIP",
  rca_sie: "RCA SIE",
  ame: "AME",
  san: "SAN",
  rca_san: "RCA SAN",
  soe: "SOE",
  rca_soe: "RCA SOE",
  sop: "SOP",
  rca_sop: "RCA SOP",
};

export const categoryToDescription: any = {
  pep: "Politically Exposed Person",
  sip: "Special Interest Person",
  sie: "Special Interest Entity",
  rca: "Relative or Close Associate",
  rca_pep: "Relative or Close Associate of a Politically Exposed Person",
  rca_sip: "Relative or Close Associate of a Special Interest Person",
  rca_sie: "Relative or Close Associate of a Special Interest Entity",
  ame: "Adverse Media Entities",
  san: "Sanctioned",
  rca_san: "Relative or Close Associate of a Sanctioned Person or Entity",
  soe: "State-owned Entity",
  rca_soe: "Relative or Close Associate of a State-owned Entity",
  sop: "State-owned entity Person (member)",
  rca_sop:
    "Relative or Close Associate of a State-owned entity Person (member)",
};

export const extractNames = (
  profile: AlertType["last_alert_revision"]["list_profile"],
  type: keyof DowJonesNameValue,
  secondLevelType?:
    | "Primary Name"
    | "Also Known As"
    | "Spelling Variation"
    | string
) => {
  return _.uniq(
    (profile.names || [])
      .filter((name) => !secondLevelType || name.name_type === secondLevelType)
      .map((a) =>
        _.uniq(
          a.name_values
            .map((b) =>
              _.uniq(b[type] || [])
                .filter((c) => c)
                .join(", ")
            )
            .filter((c) => c)
        ).join(", ")
      )
      .filter((c) => c)
  ).join(", ");
};

export const extractCategoriesFromRecord = (
  record?: DowJonesRecord,
  categories?: any
) => {
  categories = categories || {};
  const desc1 = (record?.descriptions?.descriptions1 || [])
    .map((a) => a.text)
    .join(" ")
    .toLocaleLowerCase();
  return {
    is_pep: desc1.includes("(pep)") || categories.is_pep,
    is_sip: desc1.includes("(sip)") || categories.is_sip,
    is_sie: desc1.includes("(sie)") || categories.is_sie,
    is_rca: desc1.includes("(rca)") || categories.is_rca,
    is_soe: desc1.includes("(soe)") || categories.is_soe,
    is_sop: desc1.includes("(sop)") || categories.is_sop,
    is_ame: desc1.includes("(ame)") || categories.is_ame,
    is_rca_sip: desc1.includes("(rca sip)") || categories.is_rca_sip,
    is_rca_sie: desc1.includes("(rca sie)") || categories.is_rca_sie,
    is_rca_pep: desc1.includes("(rca pep)") || categories.is_rca_pep,
    is_rca_sop: desc1.includes("(rca sop)") || categories.is_rca_sop,
    is_rca_soe: desc1.includes("(rca soe)") || categories.is_rca_soe,
    is_rca_san: desc1.includes("(rca san)") || categories.is_rca_san,
    is_san: !!record?.sanctions?.length || categories.is_san,
  };
};

export const convertMonth = (month: string) => {
  return (
    {
      Jan: "01",
      Feb: "02",
      Mar: "03",
      Apr: "04",
      May: "05",
      Jun: "06",
      Jul: "07",
      Aug: "08",
      Sep: "09",
      Oct: "10",
      Nov: "11",
      Dec: "12",
    }[month] || month
  );
};

export const extractDate = (
  date: any,
  type: "since" | "to",
  fallback?: string
) => {
  const res = (
    type === "since"
      ? [date.since_year, convertMonth(date.since_month), date.since_day]
      : [date.to_year, convertMonth(date.to_month), date.to_day]
  )
    .filter((a) => a.trim())
    .join("-");
  if (!res.match(/[1-9]/) && fallback !== undefined) return fallback;
  return res;
};

export const extractDates = (
  profile: AlertType["last_alert_revision"]["list_profile"],
  type: "Date of Birth"
) => {
  return _.uniq(
    (profile.dates || [])
      .filter((a) => a.date_type === type)
      .map((a) =>
        _.uniq(
          a.date_values.map((b) =>
            [b.year, convertMonth(b.month), b.day].filter((c) => c).join("-")
          )
        ).join(", ")
      )
  ).join(", ");
};

export const extractCountries = (
  profile: AlertType["last_alert_revision"]["list_profile"]
) => {
  const res = _.uniq(
    (profile.countries || []).map(
      (a) => a.iso3_codes || _.uniq(a.codes).join(", ")
    )
  ).join(", ");
  if (res === "NOTK") return "";
  return res;
};

export const isFirm = (type: string) => {
  return type === "Firm";
};

export const extractCustomerAlertFullName = (
  item: AlertRevision | AlertListType
) => {
  return [
    item.customer_company_name,
    item.customer_trading_name,
    item.customer_firstname,
    item.customer_middlename,
    item.customer_lastname,
  ]
    .filter((a) => a)
    .join(" ");
};

export const getLastToDate = (
  activities: { to_day: string; to_month: string; to_year: string }[]
) => {
  return activities.reduce(
    (acc, curr) => {
      if (!acc) return curr;
      if (acc.to_year > curr.to_year) return acc;
      if (acc.to_year < curr.to_year) return curr;
      if (acc.to_month > curr.to_month) return acc;
      if (acc.to_month < curr.to_month) return curr;
      if (acc.to_day > curr.to_day) return acc;
      if (acc.to_day < curr.to_day) return curr;
      return acc;
    },
    {
      to_day: "0",
      to_month: "0",
      to_year: "0",
    }
  );
};
