import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ButtonConfirm } from "@atoms/button/confirm";
import { PageBlock } from "@atoms/layout/page-block";
import { Section, SectionSmall } from "@atoms/text";
import { Table } from "@components/table";
import { useContexts } from "@features/kyt/state/use-contexts";
import { KytContext } from "@features/kyt/types";
import _ from "lodash";
import { useEffect, useState } from "react";
import { ContextModal } from "./context-modal";

export const KytContextsPage = () => {
  const { contexts: fetchContexts, loading, deleteContext } = useContexts();
  const [contexts, setContexts] = useState<KytContext[]>([]);
  const [contextModal, setContextModal] = useState<KytContext | null>(null);

  useEffect(() => {
    if (fetchContexts) {
      setContexts(fetchContexts.contexts);
    }
  }, [fetchContexts]);

  const detectedAndNotInContexts =
    fetchContexts?.detected.filter(
      (item) => !contexts.map((context) => context.name).includes(item)
    ) || [];

  return (
    <div className="w-full">
      <Section>Transaction contexts</Section>
      <div className="mt-4" />

      <div className="flex justify-between mt-8">
        <SectionSmall>Managed contexts</SectionSmall>
        <Button
          theme="primary"
          onClick={() =>
            setContextModal({
              name: "",
              review_groups: [],
            })
          }
          size="sm"
        >
          Add context
        </Button>
      </div>
      <PageBlock className="mb-6 mt-2">
        <Table
          loading={loading}
          showPagination={false}
          columns={[
            {
              title: "Name",
              render: (item) => (
                <div className="flex items-center">{item.name}</div>
              ),
            },
            {
              title: "Review groups",
              render: (item) => (
                <div className="flex gap-2">
                  {(item.review_groups || []).map((group, i) => (
                    <Tag key={i}>{group}</Tag>
                  ))}
                </div>
              ),
            },
            ...[
              {
                title: "Actions",
                headClassName: "justify-end",
                className: "text-right",
                thClassName: "w-24",
                render: (item: KytContext) => (
                  <div className="flex gap-2">
                    <Button
                      theme="outlined"
                      size="sm"
                      onClick={() => {
                        setContextModal(item);
                      }}
                    >
                      Edit
                    </Button>
                    <ButtonConfirm
                      theme="danger"
                      confirmTitle="Delete context"
                      confirmMessage={`The context ${item.name} will be deleted, are you sure to continue ?`}
                      size="sm"
                      loading={loading}
                      onClick={async () => {
                        await deleteContext(item);
                      }}
                    >
                      Delete
                    </ButtonConfirm>
                  </div>
                ),
              },
            ],
          ]}
          data={_.sortBy(contexts, "name")}
        />
        {contextModal && (
          <ContextModal
            onClose={() => {
              setContextModal(null);
            }}
            context={contextModal}
          />
        )}
      </PageBlock>

      {fetchContexts &&
        fetchContexts.detected &&
        detectedAndNotInContexts.length > 0 && (
          <>
            <SectionSmall>Detected contexts</SectionSmall>
            <PageBlock className="py-3 mt-2">
              {detectedAndNotInContexts.map((name, index) => (
                <Button
                  key={index}
                  theme="outlined"
                  size={"sm"}
                  className="m-1"
                  onClick={() => setContextModal({ name, review_groups: [] })}
                >
                  Add "{name}" to configuration
                </Button>
              ))}
            </PageBlock>
          </>
        )}
    </div>
  );
};
