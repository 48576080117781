import { KytApiClient } from "../api-client/api-client";
import { useQuery } from "react-query";

export const useCurrencies = () => {
  const currencies = useQuery({
    queryKey: ["currencies"],
    queryFn: async () => await KytApiClient.getCurrencies(),
  });

  const fetchCurrencies = async () => {
    return await KytApiClient.getCurrencies();
  };

  return { currencies, fetchCurrencies };
};
