import { Tag } from "@atoms/badge/tag";
import { Modal, ModalContent } from "@atoms/modal/modal";
import { Base, InfoSmall } from "@atoms/text";
import { useAgents } from "@features/agents/state/use-agents";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { CustomerDetailType } from "@features/customers/types";
import { formatTime } from "@features/utils/dates";
import _ from "lodash";
import { useEffect, useState } from "react";
import { atom, useRecoilState, useRecoilValue } from "recoil";

/**
 * Update multiple alerts statuses for a customer or by ids
 */

export const EventsTimelineModalAtom = atom<{
  customer_id?: string;
}>({
  key: "EventsTimelineModalAtom",
  default: {},
});

export const EventsTimelineModal = () => {
  const [open, setOpen] = useRecoilState(EventsTimelineModalAtom);

  return (
    <Modal open={!!open.customer_id} onClose={() => setOpen({})}>
      {!!open.customer_id && <EventsTimelineModalContent />}
    </Modal>
  );
};

function getObjectDiff(obj1: any, obj2: any) {
  obj1 = _.omit(obj1, ["created_at", "revision_number"]);
  obj2 = _.omit(obj2, ["created_at", "revision_number"]);
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key);
    } else if (_.isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key);
      result.splice(resultKeyIndex, 1);
    }
    return result;
  }, Object.keys(obj2));

  return diff;
}

const keysNames = {
  first_name: "First name",
  middle_name: "Middle name",
  last_name: "Last name",
  date_of_birth: "Date of birth",
  nationality_code: "Nationality",
  domicile_code: "Domicile",
  company_name: "Company name",
  trading_name: "Trading name",
  registration_number: "Registration number",
  account_type: "Account type",
  review_groups: "Review groups",
};

export const EventsTimelineModalContent = () => {
  const open = useRecoilValue(EventsTimelineModalAtom);
  const { getAgentForClient } = useAgents();
  const agents = getAgentForClient(true);
  const [_history, setHistory] = useState<CustomerDetailType["customer"][]>([]);
  const history = _history.map((h) => ({
    ...h,
    ...h.custom_fields,
    custom_fields: null,
    date_of_birth: h.date_of_birth?.split("T")[0],
    name_variations: h.name_variations?.map((v) => ({
      ...v,
      date_of_birth: v.date_of_birth?.split("T")[0],
    })),
  }));

  useEffect(() => {
    (async () => {
      setHistory(await CustomersApiClient.getRevisions(open.customer_id!));
    })();
  }, [open.customer_id]);

  return (
    <ModalContent title={"Revisions"}>
      {history.map((h, index) => {
        const changedKeys = (
          index < history.length - 1 ? getObjectDiff(history[index + 1], h) : []
        ).filter((a) => a !== "name_variations");
        const addedVariations = _.differenceBy(
          h.name_variations || [],
          history[index + 1]?.name_variations || [],
          JSON.stringify
        );
        const removedVariations = _.differenceBy(
          history[index + 1]?.name_variations || [],
          h.name_variations || [],
          JSON.stringify
        );
        const showType = (obj: any) => {
          if (typeof obj === "object") {
            return JSON.stringify(obj);
          }
          if (typeof obj === "string") {
            return obj || "''";
          }
          if (obj === null || obj === undefined) {
            return "null";
          }
          return obj;
        };
        return (
          <div className="" key={index}>
            {index !== 0 && <hr className="my-3 -mx-6" />}
            <InfoSmall>
              Revision {h.revision_number} - {formatTime(h.created_at)} by{" "}
              {agents.find((a) => a.agent_id === h.created_by)?.name || "N/A"}
            </InfoSmall>
            <br />
            {index !== history.length - 1 && (
              <Base>
                {changedKeys.map((key) => {
                  return (
                    <Base key={key} className="block w-full">
                      <Tag noColor className="bg-yellow-500 text-white">
                        <b>{(keysNames as any)[key] || key}</b> changed
                      </Tag>{" "}
                      <i>{showType((history[index + 1] as any)[key])}</i>
                      {" -> "}
                      <b>{showType((h as any)[key])}</b>
                    </Base>
                  );
                })}
                {addedVariations.map((variation, i) => (
                  <Base className="w-full flex flex-row items-start" key={i}>
                    <div>
                      <Tag noColor className="bg-green-500 text-white">
                        <b>Variation</b> added
                      </Tag>
                    </div>
                    <div className="grow ml-4">
                      {Object.keys(variation)
                        .filter((a) => (variation as any)[a])
                        .map((k) => (
                          <>
                            <i>{(keysNames as any)[k] || k}</i>:{" "}
                            <b>{showType((variation as any)[k])}</b>
                            <br />
                          </>
                        ))}
                    </div>
                  </Base>
                ))}
                {removedVariations.map((variation, i) => (
                  <Base className="w-full flex flex-row items-start" key={i}>
                    <div>
                      <Tag noColor className="bg-red-800 text-white">
                        <b>Variation</b> removed
                      </Tag>
                    </div>
                    <div className="grow ml-4 line-through">
                      {Object.keys(variation)
                        .filter((a) => (variation as any)[a])
                        .map((k) => (
                          <>
                            <i>{(keysNames as any)[k] || k}</i>:{" "}
                            <b>{(variation as any)[k]}</b>
                            <br />
                          </>
                        ))}
                    </div>
                  </Base>
                ))}
              </Base>
            )}
            {index === history.length - 1 && <Base>Customer created</Base>}
          </div>
        );
      })}
    </ModalContent>
  );
};
