import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import { Base, Info, Section, Title } from "@atoms/text";
import { Table } from "@components/table";

import { ButtonConfirm } from "@atoms/button/confirm";
import Tabs from "@atoms/tabs";
import { useHasAccess } from "@features/auth/state/use-access";
import { KytApiClient } from "@features/kyt/api-client/api-client";
import { useCurrencies } from "@features/kyt/state/use-currencies";
import { KytCurrenciesWithID } from "@features/kyt/types";
import { formatTime } from "@features/utils/dates";
import { useState } from "react";
import toast from "react-hot-toast";
import { useRecoilState } from "recoil";
import { KytContextsPage } from "./contexts";
import { CurrencyEditionModalAtom } from "./edition-modal";

const KytConfigurationPage = () => {
  const hasAccess = useHasAccess();
  const [, setCurrencyModal] = useRecoilState(CurrencyEditionModalAtom);
  const { currencies } = useCurrencies();
  const [tab, setTab] = useState("currencies");

  return (
    <Page>
      <Title className="mb-5">KYT Configuration</Title>
      <Tabs
        className="mb-4"
        tabs={[
          {
            label: "Currencies",
            value: "currencies",
          },
          ...(hasAccess("KYT_MANAGE")
            ? [
                {
                  label: "Contexts",
                  value: "contexts",
                },
              ]
            : []),
        ]}
        value={tab}
        onChange={(t) => setTab(t as string)}
      />

      {tab === "contexts" && hasAccess("KYT_MANAGE") && <KytContextsPage />}

      {tab === "currencies" && (
        <>
          <div className="flex flex-row justify-between items-center w-full">
            <Section>Custom currencies</Section>
            {hasAccess("KYT_MANAGE") && (
              <Button
                theme="primary"
                onClick={() => {
                  setCurrencyModal({
                    open: true,
                    currency: undefined,
                  });
                }}
              >
                New currency
              </Button>
            )}
          </div>
          {currencies.isFetched && (
            <Table
              showPagination={false}
              data={(currencies.data as KytCurrenciesWithID[]) || []}
              columns={[
                {
                  title: "Code",
                  render: (item) => item.code,
                },
                {
                  title: "Label",
                  render: (item) => item.label,
                },
                {
                  title: "Price",
                  render: (item) =>
                    item.price && (
                      <div className="flex flex-col items-end w-full font-mono  whitespace-nowrap">
                        <Base>
                          <Info>
                            1 {item.label} ({item.code}) = {item.price} €
                          </Info>
                        </Base>
                      </div>
                    ),
                },
                {
                  title: "Created at",
                  render: (item) =>
                    item.created_at && (
                      <Info>
                        {formatTime(item.created_at, undefined, {
                          keepDate: true,
                          keepTime: true,
                        })}
                      </Info>
                    ),
                },
                {
                  title: "Updated at",
                  render: (item) =>
                    item.updated_at && (
                      <Info>
                        {formatTime(item.updated_at, undefined, {
                          keepDate: true,
                          keepTime: true,
                        })}
                      </Info>
                    ),
                },
                ...(hasAccess("KYT_MANAGE")
                  ? [
                      {
                        title: "Actions",
                        headClassName: "justify-end",
                        className: "text-right",
                        thClassName: "w-24",
                        render: (item: KytCurrenciesWithID) => (
                          <div className="flex flex-row gap-2">
                            <Button
                              theme="outlined"
                              size="sm"
                              onClick={() => {
                                setCurrencyModal({
                                  open: true,
                                  currency: item,
                                });
                              }}
                            >
                              Modify
                            </Button>
                            <ButtonConfirm
                              className="float-right"
                              theme="danger"
                              onClick={async () => {
                                try {
                                  !item?.ID
                                    ? toast.error(
                                        "Error: currency identifier not found"
                                      )
                                    : await KytApiClient.deleteCurrency(
                                        item?.ID
                                      );
                                  currencies.refetch();
                                  setCurrencyModal({
                                    open: false,
                                    currency: undefined,
                                  });
                                } catch (error) {
                                  toast.error("Currency deletion error");
                                }
                              }}
                            >
                              Deleted
                            </ButtonConfirm>
                          </div>
                        ),
                      },
                    ]
                  : []),
              ]}
            />
          )}
        </>
      )}
    </Page>
  );
};

export default KytConfigurationPage;
