import { Checkbox } from "@atoms/input/input-checkbox";
import Select from "@atoms/select";
import { useCustomMatrix } from "@features/custom-matrix/state/use-custom-matrix";
import {
  RiskNodeField,
  RiskResourceType,
  RiskTreeNode,
} from "@features/risk-decisions/types";
import {
  NODE_INVISIBLE_UNDEFINED,
  RISK_CODES_LIST,
  RiskStatus,
} from "@features/risk-decisions/utils";
import { TableCellsIcon } from "@heroicons/react/24/outline";
import { CSSProperties, useEffect } from "react";
import { Handle, HandleProps, NodeProps, Position } from "reactflow";
import { NodeHeader } from "./components/_node_header";
import { NodeLayout } from "./components/_node_layout";
import { FieldSelector } from "./components/field-selector";

const handleStyle: CSSProperties = {
  height: 15,
  width: 15,
};

const fromProps: HandleProps = {
  type: "target",
  id: "from",
  position: Position.Left,
};
const lowProps: HandleProps = {
  type: "source",
  id: "on_low_risk_node_id",
  position: Position.Right,
};
const mediumProps: HandleProps = {
  type: "source",
  id: "on_medium_risk_node_id",
  position: Position.Right,
};
const highProps: HandleProps = {
  type: "source",
  id: "on_high_risk_node_id",
  position: Position.Right,
};
const criticalProps: HandleProps = {
  type: "source",
  id: "on_critical_risk_node_id",
  position: Position.Right,
};
const undefinedProps: HandleProps = {
  type: "source",
  id: "on_undefined_node_id",
  position: Position.Right,
};

const validateNode = (node: RiskTreeNode) => {
  if (!node.name) return "Name is missing";
  if (!node.matrix?.field?.id) return "Field is missing";
  if (!node.matrix?.matrix_id) return "Matrix is missing";
  if (
    !Object.values(node.output_node_ids).some(
      (v) => v !== NODE_INVISIBLE_UNDEFINED.id
    )
  )
    return "At least one output is required";
  return "";
};

export default function MatrixNode(
  props: NodeProps<
    RiskTreeNode & {
      field: RiskNodeField;
      resource: RiskResourceType;
      withRelations: boolean;
      editable: boolean;
      onChange: (data: RiskTreeNode) => void;
    }
  >
) {
  const {
    matrices,
    loading: loadingMatrices,
    refresh: refreshMatrices,
  } = useCustomMatrix();

  useEffect(() => {
    if (matrices.length === 0 && !loadingMatrices) {
      refreshMatrices();
    }
  }, [matrices, loadingMatrices, refreshMatrices]);

  return (
    <NodeLayout
      selected={props.selected}
      invalidMessage={validateNode(props.data)}
    >
      <NodeHeader
        disabled={!props.data.editable}
        icon={(p) => <TableCellsIcon {...p} />}
        type="Matrix Component"
        value={props.data.name || ""}
        onChange={(value) =>
          props.data.onChange({
            ...props.data,
            name: value,
          })
        }
      />
      <FieldSelector
        type={props.data.resource}
        withRelations={props.data.withRelations}
        editable={props.data.editable}
        value={props.data.matrix?.field || { id: 0, entity: "customer" }}
        onChange={(value) => {
          return props.data.onChange({
            ...props.data,
            matrix: {
              ...props.data.matrix!,
              field: value,
            },
          });
        }}
      />
      <div>
        <Select
          disabled={!props.data.editable}
          className="disabled:opacity-50 nowheel nodrag rounded-none w-full border-gray-200 border border-l-0 border-t-0 border-r-0 text-sm dark:bg-slate-800 dark:text-white dark:border-slate-600"
          placeholder="Select a matrix"
          value={props.data.matrix?.matrix_id || ""}
          onChange={(e) => {
            props.data.onChange({
              ...props.data,
              matrix: {
                ...props.data.matrix!,
                matrix_id: e.target.value,
              },
            });
          }}
          name="Matrix"
        >
          <option value="" className="text-gray-200" disabled>
            Select a matrix
          </option>
          {!loadingMatrices &&
            matrices.map((matrix) => (
              <option key={"matrix" + matrix.id} value={matrix.id}>
                {matrix.matrix_type}
              </option>
            ))}
        </Select>
      </div>
      <div className="p-2 border-b">
        <Checkbox
          disabled={!props.data.editable}
          label="Use RegEx"
          value={props.data.matrix?.use_regex ?? false}
          onChange={(s) =>
            props.data.onChange({
              ...props.data,
              matrix: {
                ...props.data.matrix!,
                use_regex: s,
              },
            })
          }
        />
      </div>

      <div className="h-2"></div>

      <div className="relative">
        <small className="mx-4">from</small>
        <Handle {...fromProps} style={{ ...handleStyle, left: -8 }} />
      </div>
      <div className="relative text-right">
        <small className="mx-4">
          {RISK_CODES_LIST.find((a) => a.id === RiskStatus.Low)?.digit}
        </small>
        <Handle
          onConnect={(params) => {
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [lowProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...lowProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="relative text-right">
        <small className="mx-4">
          {RISK_CODES_LIST.find((a) => a.id === RiskStatus.Medium)?.digit}
        </small>
        <Handle
          onConnect={(params) => {
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [mediumProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...mediumProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="relative text-right">
        <small className="mx-4">
          {RISK_CODES_LIST.find((a) => a.id === RiskStatus.High)?.digit}
        </small>
        <Handle
          onConnect={(params) => {
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [highProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...highProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="relative text-right">
        <small className="mx-4">
          {RISK_CODES_LIST.find((a) => a.id === RiskStatus.Critical)?.digit}
        </small>
        <Handle
          onConnect={(params) => {
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [criticalProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...criticalProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="relative text-right">
        <small className="mx-4 opacity-30">undefined</small>
        <Handle
          onConnect={(params) => {
            props.data.onChange({
              ...props.data,
              output_node_ids: {
                ...props.data.output_node_ids,
                [undefinedProps.id!]: params.target!,
              },
            });
            return true;
          }}
          {...undefinedProps}
          style={{ ...handleStyle, right: -8 }}
        />
      </div>
      <div className="h-4"></div>
    </NodeLayout>
  );
}
