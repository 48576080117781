import { FIELD_TYPES } from "@features/custom-fields/enum";
import { useCustomFields } from "@features/custom-fields/state/use-custom-fields";
import {
  RiskNodeFormulaDefinition,
  RiskNodeFormulaExpression,
  RiskResourceType,
} from "@features/risk-decisions/types";
import {
  relationsAggregate,
  useFormulaEditor,
} from "@features/risk-decisions/use-formula-editor";
import {
  AccessorNode,
  ConstantNode,
  FunctionNode,
  MathNode,
  OperatorNode,
  parse,
} from "mathjs";

const isCustomerRelationEntity = (entity: string) => {
  return ["customer_relations"].includes(entity);
};

const isTransactionEntity = (entity: string) => {
  return ["transaction"].includes(entity);
};

const isCustomerEntity = (entity: string) => {
  return [
    "customer",
    "from",
    "to",
    "from_institution",
    "to_institution",
  ].includes(entity);
};

const isConstant = (entity: string) => {
  return ["constant"].includes(entity);
};

export const useFormulaEditorComponent = (
  type: RiskResourceType,
  withRelations: boolean,
  onChange: (value: RiskNodeFormulaDefinition | null) => void
) => {
  const { fields } = useCustomFields();
  const { loading, id } = useFormulaEditor(type, withRelations);

  const customerFields = fields.filter(
    (f) =>
      f.field_type === FIELD_TYPES.NUMBER && ![5, 6].includes(f.field_source)
  );

  const customerRelationsFields = fields.filter(
    (f) =>
      f.field_type === FIELD_TYPES.NUMBER && [5, 6].includes(f.field_source)
  );

  function mapToDefinitionExpression(
    node: MathNode
  ): Partial<Partial<RiskNodeFormulaExpression>> | null {
    if (node.type === "OperatorNode") {
      const op = node as OperatorNode;
      let operator: "sum" | "minus" | "mul" | "div";
      switch (op.op) {
        case "+":
          operator = "sum";
          break;
        case "-":
          operator = "minus";
          break;
        case "*":
          operator = "mul";
          break;
        case "/":
          operator = "div";
          break;
        default:
          console.error("unknown operator", op.op);
          return null;
      }
      const expressions = op.args.map(mapToDefinitionExpression);
      if (expressions.some((e) => e === null)) return null; // error
      return {
        formula: {
          aggregation_operator: operator,
          expressions: expressions as RiskNodeFormulaExpression[],
        },
      };
    }

    if (node.type === "FunctionNode") {
      // average, max, min
      const fnct = node as FunctionNode;
      const expressions = fnct.args.map(mapToDefinitionExpression);
      if (expressions.some((e) => e === null)) return null; // error
      return {
        formula: {
          aggregation_operator: fnct.fn.name,
          expressions: expressions as RiskNodeFormulaExpression[],
        },
      };
    }

    if (node.type === "ConstantNode") {
      const cst = node as ConstantNode;
      return {
        constant: cst.value,
      };
    }

    if (node.type === "AccessorNode") {
      // either customer.field or customer_relations.field or customer_relations.field.aggregate
      const fieldAccessor = node as AccessorNode;

      // Get full field path
      const path = convertKytKeysFromFormulaKey(fieldAccessor.toString());
      const entity = path.split(".")[0];
      const field = path.split(".").slice(1).join(".");

      const hasAggregate = relationsAggregate.includes(
        path.split(".").pop() || ""
      );
      let relationAggregate: string | null =
        (hasAggregate ? path.split(".").pop() : null) || "none";
      if (!isCustomerRelationEntity(entity)) relationAggregate = null;

      if (
        !isConstant(entity) &&
        !isCustomerEntity(entity) &&
        !isCustomerRelationEntity(entity) &&
        !isTransactionEntity(entity)
      ) {
        return null;
      }

      const customField = (
        isCustomerEntity(entity) ? customerFields : customerRelationsFields
      ).find((f) => f.label === field);

      if (
        !isConstant(entity) &&
        !customField &&
        field.indexOf("kyt") !== 0 &&
        !isTransactionEntity(entity)
      ) {
        console.error("unknown field", entity, fieldAccessor.name);
        return null;
      }

      return {
        field: {
          entity: isConstant(entity)
            ? "constant"
            : isCustomerEntity(entity)
            ? (entity as
                | "customer"
                | "from"
                | "to"
                | "from_institution"
                | "to_institution")
            : isTransactionEntity(entity)
            ? "transaction"
            : "relation",
          id: customField?.field_id,
          string_identifier: customField?.field_id ? undefined : field,
          relation_aggregate: relationAggregate as
            | "none"
            | "and"
            | "or"
            | "sum"
            | "average"
            | "max"
            | "min",
        },
      };
    }

    return null;
  }

  function mapFromDefinitionExpression(
    expression: RiskNodeFormulaExpression
  ): string | null {
    console.log(expression);
    if (expression.constant) {
      return expression.constant.toString();
    }
    if (expression.field) {
      if (expression.field.string_identifier) {
        return (
          expression.field.entity + "." + expression.field.string_identifier
        );
      }

      const entity = expression.field.entity;
      const field = (
        isCustomerEntity(entity) ? customerFields : customerRelationsFields
      ).find((f) => f.field_id === expression.field!.id);
      let aggregate;
      if (
        expression.field.relation_aggregate &&
        expression.field.relation_aggregate !== "none" &&
        isCustomerRelationEntity(entity)
      ) {
        aggregate = expression.field.relation_aggregate;
      }

      if (aggregate) {
        return `${entity}.${field?.label}.${aggregate}`;
      }
      return `${entity}.${field?.label}`;
    }
    if (expression.formula) {
      // "sum" / "average" / "max" / "min" / "minus" / "mul" / "div"
      // case +, -, *, /
      if (
        ["sum", "minus", "mul", "div"].includes(
          expression.formula.aggregation_operator
        )
      ) {
        let separator;
        switch (expression.formula.aggregation_operator) {
          case "sum":
            separator = "+";
            break;
          case "minus":
            separator = "-";
            break;
          case "mul":
            separator = "*";
            break;
          case "div":
            separator = "/";
            break;
          default:
            console.error(
              "unknown operator",
              expression.formula.aggregation_operator
            );
            return null;
        }
        return `${expression.formula.expressions
          .map(mapFromDefinitionExpression)
          .join(" " + separator + " ")}`;
      } else {
        // case average, max, min
        return `${
          expression.formula.aggregation_operator
        }(${expression.formula.expressions
          .map(mapFromDefinitionExpression)
          .join(", ")})`;
      }
    }
    return null;
  }

  const onValueChange = (value: string | undefined) => {
    // Remove line breaks
    value = value?.trim()?.replace(/\n/g, " ");
    let node: MathNode;
    try {
      node = parse(convertKytKeysToFormulaKey(value || ""));
    } catch (error: any) {
      console.log("path error", value, error);
      console.warn("error parsing expression", value, error.message);
      onChange(null);
      return;
    }
    const definition = mapToDefinitionExpression(node);
    if (!definition?.formula) {
      console.warn(
        "error parsing expression, it should be formula",
        value,
        definition
      );
      onChange(null);
      return;
    }

    onChange(definition as RiskNodeFormulaDefinition | null);
  };

  return {
    id,
    loading,
    onValueChange,
    mapFromDefinitionExpression,
    mapToDefinitionExpression,
  };
};

const convertKytKeysToFormulaKey = (str: string) => {
  return str.replace(/\.([0-9]+)/g, ".digit_$1");
};

const convertKytKeysFromFormulaKey = (str: string) => {
  return str.replace(/\.digit_([0-9]+)/g, ".$1");
};
