import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { Info } from "@atoms/text";
import { Table } from "@components/table";
import { Column } from "@components/table/table";
import { ROUTES } from "@features/routes";
import { useScenarios } from "@features/scenarios/state/use-scenarios";
import { SCENARIOS_LANGUAGES } from "@features/scenarios/utils";
import { useCustomerSessions } from "@features/sessions/state/use-customer-sessions";
import { useSessionStates } from "@features/sessions/state/use-sessions-states";
import { SessionListType } from "@features/sessions/types";
import { formatTime } from "@features/utils/dates";
import { EyeIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import SessionScore from "../../sessions/components/session-score";

export const CustomerSessionsModalAtom = atom<{
  open: boolean;
  external_id: string | null;
  heighlighted_session_id?: string;
}>({
  key: "CustomerSessionsModalAtom",
  default: {
    open: false,
    external_id: null,
  },
});

function CustomerSessionsModalContent({
  sessions,
  loading
}: {
  sessions: SessionListType[];
  loading: boolean;
}) {
  const [modal, setModal] = useRecoilState(CustomerSessionsModalAtom);
  const { states } = useSessionStates();
  const { scenarios } = useScenarios();

  const columns: Column<SessionListType>[] = [
    {
      title: "Date",
      render: (row) => (
        <div className="flex items-center flex-row capitalize whitespace-nowrap">
          {<Info className="text-sm">{formatTime(row.start_timestamp)}</Info>}
        </div>
      ),
    },
    {
      title: "Scenario",
      render: (row) => (
        <Info>
          {row.scenario_ref} (
          {SCENARIOS_LANGUAGES[row.language] || row.language})
        </Info>
      ),
    },
    {
      title: "Category",
      render: (row) => {
        const scenario = scenarios?.data.find(
          (scenario) => scenario.code === row.scenario_ref
        );
        if (!scenario) return <Info>-</Info>;
        return <Tag>{scenario.result_label || "-"}</Tag>;
      },
    },
    {
      title: "Score",
      render: (row) => (
        <SessionScore
          text={row.score !== null ? row.score.toFixed(0) + " %" : "-"}
          status={
            row.score === null
              ? "slate"
              : row.fail
              ? "red"
              : row.success
              ? "green"
              : "yellow"
          }
        />
      ),
    },
    {
      title: "Status",
      render: (row) => {
        const type = states.find((s) => s.label === row.status)?.type;
        if (row.score === null) {
          return (
            <Tag noColor className={"text-white bg-slate-500"}>
              Unfinished
            </Tag>
          );
        }
        return (
          states.length > 0 && (
            <Tag
              noColor
              className={
                "text-white " +
                (type === "NEGATIVE"
                  ? "bg-red-500"
                  : type === "POSITIVE"
                  ? "bg-green-500"
                  : "bg-yellow-500")
              }
            >
              {row.status}
            </Tag>
          )
        );
      },
    },
    {
      title: "Actions",
      headClassName: "justify-end",
      className: "justify-end h-12",
      thClassName: "w-24",
      render: (row) => (
        <Link to={ROUTES.SessionView.replace(":id", row.session_id)}>
          <Button
            size="sm"
            theme="outlined"
            className="px-2"
            data-tooltip="Open Session"
            onClick={() => {
              setModal({
                open: false,
                external_id: null,
                heighlighted_session_id: undefined,
              });
            }}
          >
            <EyeIcon className="w-4 h-4" />
          </Button>
        </Link>
      ),
    },
  ];


  return (
    <div style={{ maxWidth: "800px", width: "100vw" }}>
      <Table
        cellClassName={(row) =>
          row.session_id === modal.heighlighted_session_id
            ? "bg-blue-200 content-center"
            : "content-center"
        }
        data={_.sortBy(sessions || [], ["start_timestamp"])}
        columns={columns}
        showPagination={false}
        loading={loading}
      />
    </div>
  );
}

export default function CustomerSessionsModal() {
  const [modal, setModal] = useRecoilState(CustomerSessionsModalAtom);
  const { sessions, loading, refresh } = useCustomerSessions(
    modal.external_id ?? ""
  );

  useEffect(() => {
    if (modal.external_id) {
      refresh();
    }
  }, [modal.external_id]);

  return (
    <SideModal
      open={modal.open}
      onClose={() => setModal({ ...modal, open: false })}
    >
      <ModalContent
        title={`Customer Sessions '${modal.external_id}'`}
      >
        <CustomerSessionsModalContent sessions={sessions} loading={loading} />
      </ModalContent>
    </SideModal>
  );
}
