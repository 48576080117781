import { Page } from "@atoms/layout/page";
import { Title } from "@atoms/text";
import { Table } from "@components/table";
import { ReportsQueryInputAtom } from "@features/press/state/store";
import { usePressReports } from "@features/press/state/use-press-reports";
import { ReportSearchResultType } from "@features/press/types";
import _ from "lodash";
import { useRecoilState } from "recoil";
import { ReportsListFilters } from "./filters";
import { availableColumns } from "./columns";
import { Pagination } from "@components/table/table";
import { useHasAccess } from "@features/auth/state/use-access";

export const PressReportsListPage = () => {
  const [advancedQuery, setAdvancedQuery] = useRecoilState(
    ReportsQueryInputAtom
  );

  const hasAccess = useHasAccess();

  const { reports, fetchReport, loading } = usePressReports();
  const columns = [
    "primary_external_id",
    "full_name",
    "review_groups",
    "customer_date_of_birth",
    "customer_nationality_code",
    "last_search_at",
    "updated_at",
    "robot_overall_risk_level",
    "active_overall_risk_level",
    "edd_state",
    "assignees",
    "actions",
  ];
  const renderColumns = availableColumns;

  return (
    <Page>
      <Title>Reports</Title>
      <div className="mt-4" />

      <ReportsListFilters value={advancedQuery} onChange={setAdvancedQuery} />

      <Table
        page={
          1 +
          Math.ceil(
            (advancedQuery?.options?.offset || 0) /
              (advancedQuery?.options?.limit || 1)
          )
        }
        total={reports.total || 0}
        showPagination={true}
        initialPagination={{
          page: 1,
          perPage: advancedQuery.options.limit ?? 10,
          orderBy: columns.indexOf(advancedQuery.options.order_by as string),
          order: advancedQuery.options.order_way,
        }}
        options={{
          exportCsv: hasAccess("PRESS_MANAGE")
            ? {
                fetchData: async (params: Pagination) => {
                  const result = await fetchReport({
                    ...advancedQuery.options,
                    offset: (params.page - 1) * params.perPage,
                    limit: params.perPage,
                    order_by: columns[params.orderBy!],
                    order_way: params.order ?? "ASC",
                  });

                  return result?.data || { data: [], total: 0 };
                },
              }
            : {},
        }}
        data={reports.data || []}
        columns={columns.map(
          (col) =>
            renderColumns[col] || {
              title: col,
              orderable: true,
              className: "text-right",
              headClassName: "justify-end",
              thClassName: "w-24",
              render: (row: ReportSearchResultType) => _.get(row, col),
            }
        )}
        onRequestData={async ({ page, perPage, orderBy, order }) => {
          setAdvancedQuery({
            ...advancedQuery,
            options: {
              ...advancedQuery.options,
              offset: (page - 1) * perPage,
              limit: perPage,
              order_by: columns[orderBy!] || undefined,
              order_way: order ?? "ASC",
            },
          });
        }}
        loading={loading}
      />
    </Page>
  );
};
