import { Tag } from "@atoms/badge/tag";
import { Base, Info, InfoSmall, SectionSmall } from "@atoms/text";
import { Table } from "@components/table";
import {
  CustomerRiskStatus,
  RISK_CUSTOMER_CODES_LIST,
  RiskType,
} from "@features/customers/utils";
import { RiskFactorElement } from "@features/risk-decisions/types";
import {
  getSubLabels,
  RISKS_COLORS,
  RiskStatus,
} from "@features/risk-decisions/utils";
import _ from "lodash";
import { Fragment, ReactNode } from "react";

export const RiskFactorTable = ({
  factors,
  loading,
  actions,
}: {
  factors: (RiskFactorElement & { risk?: RiskType; score?: number })[];
  loading?: boolean;
  actions?: (item: RiskFactorElement) => ReactNode;
}) => {
  const sortedRiskFactors = [...(factors || [])].filter(
    (a) => a.risk !== "Undefined"
  );
  sortedRiskFactors.sort((a, b) => a.label.localeCompare(b.label));
  const groupedRiskFactors = _.groupBy(
    sortedRiskFactors,
    (a) => getSubLabels(a.label)[0]
  );

  const totalWeight = factors.reduce((acc, item) => acc + item.weight, 0);

  return (
    <>
      {_.sortBy(Object.entries(groupedRiskFactors), (a) => a[0]).map(
        ([k, v]) => {
          const groupTotalWeight = factors
            .filter(
              (a) =>
                a.use_weight &&
                (v[0].group || getSubLabels(v[0].label)[0]) ===
                  (a.group || getSubLabels(a.label)[0])
            )
            .reduce((a, acc) => a + acc.weight, 0);
          return (
            <Fragment key={k}>
              {(Object.keys(groupedRiskFactors).length > 1 ||
                getSubLabels(v[0].label)[0]) && (
                <SectionSmall className="mt-4 mb-2">
                  {v[0].group || getSubLabels(v[0].label)[0] || "General"}
                  {!!groupTotalWeight && (
                    <Base className="float-right">
                      {Math.round((100 * groupTotalWeight) / totalWeight)}%
                      <Info className="ml-2">({groupTotalWeight})</Info>
                    </Base>
                  )}
                </SectionSmall>
              )}
              <Table
                className="mb-2"
                loading={loading}
                showPagination={false}
                data={v}
                columns={[
                  ...(factors?.[0]?.code
                    ? [
                        {
                          thClassName: "w-40",
                          render: (item: any) => <Info>{item.code}</Info>,
                        } as any,
                      ]
                    : []),
                  {
                    thClassName: "w-full",
                    render: (item: any) => (
                      <div className="flex flex-col items-start w-full font-mono  whitespace-nowrap">
                        <Base>{getSubLabels(item.label)[1]}</Base>
                        <Info>
                          <span data-tooltip={item.describe}>
                            <InfoSmall>
                              {item?.describe?.slice(0, 50)}
                              {item?.describe?.length > 50 && "..."}
                            </InfoSmall>
                          </span>
                        </Info>
                      </div>
                    ),
                  },
                  {
                    thClassName: "w-40 whitespace-nowrap",
                    render: (item) => {
                      const useWeight = factors.find(
                        (a) => a.id === item.id
                      )?.use_weight;
                      const weight =
                        factors.find((a) => a.id === item.id)?.weight || 0;

                      if (weight === 0 && useWeight) {
                        return (
                          <>
                            <Tag
                              color="gray"
                              data-tooltip="This rule won't participate to the final score."
                            >
                              Excluded
                            </Tag>
                          </>
                        );
                      }
                      return (
                        <>
                          {!useWeight && <Info>[max]</Info>}
                          {useWeight && (
                            <>
                              <Info>
                                {!!groupTotalWeight &&
                                  Math.round((100 * weight) / groupTotalWeight)}
                                %
                                <InfoSmall className="ml-2">
                                  ({weight})
                                </InfoSmall>
                              </Info>
                            </>
                          )}
                        </>
                      );
                    },
                  },
                  ...(factors?.[0]?.risk || factors?.[0]?.score !== undefined
                    ? [
                        {
                          thClassName: "w-24",
                          render: (
                            item: RiskFactorElement & {
                              risk: CustomerRiskStatus;
                              score: number;
                            }
                          ) => {
                            return (
                              <Tag
                                noColor
                                className={
                                  "text-white capitalize block text-md bg-" +
                                  RISKS_COLORS[
                                    `${
                                      item.score === undefined
                                        ? item.risk
                                        : convertToRiskId(item.score)
                                    }`
                                  ]
                                }
                              >
                                {item.score === undefined
                                  ? (item.risk &&
                                      RISK_CUSTOMER_CODES_LIST.filter(
                                        (i) => i.id === item.risk
                                      )[0].digit) ||
                                    "Undefined"
                                  : item.score + "%" || "Undefined"}
                              </Tag>
                            );
                          },
                        } as any,
                      ]
                    : []),
                  ...(actions
                    ? [
                        {
                          thClassName: "w-24",
                          render: actions,
                        },
                      ]
                    : []),
                ]}
              />
            </Fragment>
          );
        }
      )}
    </>
  );
};

const convertToRiskId = (kytScore: number) => {
  let score = 0;
  if (kytScore >= 90) {
    score = RiskStatus.Low;
  } else if (kytScore >= 50) {
    score = RiskStatus.Medium;
  } else if (kytScore >= 25) {
    score = RiskStatus.High;
  } else {
    score = RiskStatus.Critical;
  }
  return score;
};
