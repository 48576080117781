import { Button } from "@atoms/button/button";
import { DropdownButton } from "@atoms/dropdown";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Loader } from "@atoms/loader";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Info } from "@atoms/text";
import { delay } from "@features/utils/delay";
import { flattenKeys } from "@features/utils/flatten";
import {
  ArrowSmallDownIcon,
  ArrowSmallUpIcon,
} from "@heroicons/react/24/outline";
import _, { flatten } from "lodash";
import Papa from "papaparse";
import { FormEvent, ReactNode, useEffect, useState } from "react";
import SimpleBar from "simplebar-react";
import { twMerge } from "tailwind-merge";
import * as XLSX from "xlsx";
import { TablePagination } from "./pagination";

export type Column<T> = {
  title?: string | ReactNode;
  className?: string;
  thClassName?: string;
  headClassName?: string;
  orderable?: boolean;
  render: (item: T) => string | ReactNode;
};

export type Pagination = {
  total: number;
  page: number;
  perPage: number;
  orderBy?: number;
  order?: "ASC" | "DESC";
};

type PropsType<T> = {
  columns: Column<T>[];
  data: T[];
  rowIndex?: string;
  cellClassName?: (row: T) => string;
  tableClassName?: string;
  className?: string;
  showPagination?: boolean;
  pagination: Pagination;
  scrollable?: boolean;
  loading?: boolean;
  onSelect?:
    | {
        icon?: (args: { className?: string }) => ReactNode;
        label: string | ReactNode;
        callback: (items: T[]) => void;
      }[]
    | ((items: T[]) => void);
  options?: {
    exportCsv?: {
      isActive?: boolean;
      fileName?: string;
      fetchData?: (pagination: Pagination) => Promise<T[]>;
    };
  };
  onClick?: (item: T, e: MouseEvent) => void;
  onChangeOrder?: (columnIndex: number, direction: "ASC" | "DESC") => void;
  onChangePage?: (page: number) => void;
  onChangePageSize?: (size: number) => void;
};

export function RenderedTable<T>({
  columns,
  data,
  rowIndex,
  pagination,
  showPagination = true,
  loading,
  scrollable,
  onSelect,
  options,
  onClick,
  onChangeOrder,
  onChangePage,
  onChangePageSize,
  cellClassName,
  tableClassName,
  className,
}: PropsType<T>) {
  const [selected, setSelected] = useState<T[]>([]);

  useEffect(() => {
    setSelected([]);
  }, [data.length, pagination?.page, pagination?.perPage]);

  useEffect(() => {
    if (onSelect && typeof onSelect === "function") onSelect(selected);
  }, [selected, onSelect]);

  // Export data
  const [exportData, setExportData] = useState({
    fileName: `${
      options?.exportCsv?.fileName || window.location.pathname.split("/").pop()
    }_${new Date().toISOString().slice(0, 10)}`,
    total: 100,
    format: "csv",
  });

  const pageSize = 50;
  const total = exportData.total;
  const pagesToFetch = Math.ceil(total / pageSize);

  const promises: (() => Promise<T[]>)[] = [];
  const [openModal, setOpenModal] = useState<boolean>();
  const [loadingCsv, setLoadingCsv] = useState(false);
  const [progress, setProgress] = useState(0);

  async function getDataForPage() {
    if (!options?.exportCsv?.fetchData) {
      return data;
    }

    for (let i = 1; i <= pagesToFetch; i++) {
      const call = () => {
        if (!options?.exportCsv?.fetchData) {
          return Promise.resolve([]);
        }
        return options.exportCsv.fetchData({
          ...pagination,
          page: i,
          perPage: pageSize,
        });
      };
      promises.push(call);
    }

    const numberPromiseRequest = 10;
    let allListPromise: T[] = [];
    let completedPromises = 0;
    for (let n = 0; n <= promises.length / numberPromiseRequest; n++) {
      const result = await Promise.all(
        promises
          .slice(
            n * numberPromiseRequest,
            n * numberPromiseRequest + numberPromiseRequest
          )
          .map((promise) =>
            promise().then((res) => {
              completedPromises++;
              setProgress((100 * completedPromises) / promises.length);
              return res;
            })
          )
      );
      allListPromise = [...allListPromise, ...flatten(result)];
      await delay(1000);
    }

    return allListPromise;
  }

  async function exportToCsv(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    e.stopPropagation();
    setLoadingCsv(true);
    const data: any[] = (await getDataForPage()).map((a: any) =>
      flattenKeys(a)
    );
    if (!data) return;
    if (exportData.format === "xlsx") {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, exportData.fileName);
      XLSX.writeFile(workbook, `${exportData.fileName}.${exportData.format}`, {
        compression: true,
      });
      setProgress(0);
      setLoadingCsv(false);
    } else {
      const csv = Papa.unparse(data);
      const url = URL.createObjectURL(
        new Blob(["\uFEFF" + csv], { type: "text/csv;charset=utf-8" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.download = `${exportData.fileName}.csv`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setProgress(0);
      setLoadingCsv(false);
    }
  }

  return (
    <div
      className={
        "z-0 not-prose text-left border-slate-200 dark:border-slate-700 relative overflow-hidden " +
        (className || "")
      }
    >
      <SimpleBar
        className={
          "relative overflow-auto " + (scrollable ? "h-full " : "rounded")
        }
      >
        {loading && (
          <div className="absolute m-auto left-0 top-0 right-0 bottom-0 w-6 h-6 text-center z-10">
            <Loader color="text-blue-500" />
          </div>
        )}

        <table
          className={
            "border-collapse table-fixed w-auto min-w-full " +
            (loading ? " opacity-75 animate-pulse " : "") +
            (scrollable ? " scrollable h-full " : "") +
            " " +
            (tableClassName || "")
          }
        >
          {columns.map((c) => c.title || "").join("") && (
            <thead>
              <tr>
                {onSelect && (
                  <th
                    className={
                      "w-8 shrink-0 relative " +
                      (scrollable
                        ? " sticky top-0 bg-slate-50 dark:bg-slate-800 "
                        : "")
                    }
                  >
                    <div
                      className="absolute z-10 mt-1 top-0 left-0 "
                      style={{
                        boxShadow: "40px 0 20px #F8FAFC",
                      }}
                    >
                      {selected.length > 0 &&
                        typeof onSelect !== "function" && (
                          <DropdownButton
                            options={onSelect.map((a) => ({
                              onClick: () => a.callback(selected),
                              icon: a.icon,
                              label: a.label,
                            }))}
                            theme="primary"
                            size="sm"
                          >
                            {selected.length || 0} item(s)
                          </DropdownButton>
                        )}
                    </div>
                  </th>
                )}
                {columns.map((column, i) => (
                  <th
                    key={i}
                    className={twMerge(
                      "table-hover-sort-container font-medium p-4 py-3",
                      column.orderable && "pr-0",
                      scrollable &&
                        " sticky top-0 bg-slate-50 dark:bg-slate-800 z-10 ",
                      column.thClassName || ""
                    )}
                  >
                    <div
                      className={twMerge(
                        "items-center flex text-blue-500 table-hover-sort-container",
                        column.headClassName || ""
                      )}
                    >
                      <Info
                        onClick={() => {
                          if (column.orderable) {
                            onChangeOrder &&
                              onChangeOrder(
                                i,
                                pagination?.order === "ASC" ? "DESC" : "ASC"
                              );
                          }
                        }}
                        className={
                          "uppercase " +
                          (column.orderable
                            ? "cursor-pointer hover:opacity-75 "
                            : "")
                        }
                        noColor={pagination?.orderBy === i}
                      >
                        {column.title}
                      </Info>
                      {column.orderable && (
                        <div className="w-4 flex items-center -mr-1">
                          {pagination?.orderBy === i &&
                            pagination.order === "DESC" && (
                              <ArrowSmallUpIcon className="h-4 w-4 text-blue-500 inline" />
                            )}
                          {pagination?.orderBy === i &&
                            pagination.order !== "DESC" && (
                              <ArrowSmallDownIcon className="h-4 w-4 text-blue-500 inline" />
                            )}
                          {pagination?.orderBy !== i && column.orderable && (
                            <ArrowSmallDownIcon className="table-hover-sort h-4 w-4 text-slate-500 opacity-50 inline" />
                          )}
                        </div>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody className="overflow-hidden ">
            {data.length === 0 && (
              <tr>
                <td colSpan={columns.length + (onSelect ? 1 : 0)}>
                  <div
                    className={
                      " p-4 text-center" +
                      (scrollable
                        ? ""
                        : "bg-white dark:bg-slate-700 border rounded border-slate-200 dark:border-slate-600")
                    }
                  >
                    <Info>No data</Info>
                  </div>
                </td>
              </tr>
            )}
            {data.map((row, i) => {
              if (onSelect && !rowIndex)
                throw new Error(
                  "rowIndex is required when onSelect is defined"
                );
              const isSelected = selected
                .map((a) => (a as any)[rowIndex || "id"])
                .includes((row as any)[rowIndex || "id"]);
              return (
                <tr
                  key={i}
                  onClick={(e) => onClick && onClick(row, e as any)}
                  className={onClick ? "cursor-pointer hover:opacity-75" : ""}
                >
                  {onSelect && (
                    <td>
                      <Checkbox
                        className="mr-2"
                        value={isSelected}
                        onChange={(a, e) => {
                          //Code to manage shift click range
                          if (
                            (e.shiftKey || e.ctrlKey) &&
                            selected.length > 0
                          ) {
                            const anchor = selected[selected.length - 1];
                            let start = false;
                            const newSelection: T[] = [];
                            for (const d of data) {
                              if (
                                (d as any)[rowIndex || "id"] ===
                                  (anchor as any)[rowIndex || "id"] ||
                                (d as any)[rowIndex || "id"] ===
                                  (row as any)[rowIndex || "id"]
                              ) {
                                if (start) {
                                  newSelection.push(d);
                                  break;
                                }
                                if (!start) start = true;
                              }
                              if (start) {
                                newSelection.push(d);
                              }
                            }
                            setSelected(
                              _.uniqBy(
                                [
                                  ...selected.filter(
                                    (s) => !newSelection.includes(s)
                                  ),
                                  ...(a ? newSelection : []),
                                  anchor,
                                ],
                                (s) => (s as any)[rowIndex || "id"]
                              )
                            );
                          } else {
                            if (a) {
                              setSelected(
                                _.uniqBy(
                                  [...selected, row],
                                  (s) => (s as any)[rowIndex || "id"]
                                )
                              );
                            } else {
                              setSelected(selected.filter((s) => s !== row));
                            }
                          }
                        }}
                      />
                    </td>
                  )}
                  {columns.map((cell, j) => {
                    const jFirst = j === 0;
                    const jLast = j === columns.length - 1;
                    const iFirst = i === 0;
                    const iLast = i === data.length - 1;
                    return (
                      <td
                        key={j}
                        className={
                          "m-0 p-0 height-table-hack overflow-hidden " +
                          cell.thClassName
                        }
                      >
                        <div
                          className={twMerge(
                            "h-full w-full flex items-center border-t border-slate-200 dark:border-slate-600 py-1 px-3",
                            (i % 2
                              ? isSelected
                                ? "dark:bg-opacity-90 bg-opacity-90 "
                                : "dark:bg-opacity-25 bg-opacity-25 "
                              : "") +
                              ((!scrollable && jFirst && " border-l ") || "") +
                              ((!scrollable && jLast && " border-r ") || "") +
                              ((!scrollable && iLast && " border-b ") || "") +
                              ((!scrollable &&
                                iFirst &&
                                jFirst &&
                                " rounded-tl ") ||
                                "") +
                              ((!scrollable &&
                                iFirst &&
                                jLast &&
                                " rounded-tr ") ||
                                "") +
                              ((!scrollable &&
                                iLast &&
                                jFirst &&
                                " rounded-bl ") ||
                                "") +
                              ((!scrollable &&
                                iLast &&
                                jLast &&
                                " rounded-br ") ||
                                "") +
                              (isSelected
                                ? " bg-blue-200 dark:bg-blue-800 "
                                : " bg-white dark:bg-slate-700 "),
                            cell.className,
                            cellClassName && cellClassName(row)
                          )}
                        >
                          {cell.render(row)}
                        </div>
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          {showPagination && !!pagination && (
            <tfoot>
              <tr>
                {(options?.exportCsv?.isActive ||
                  options?.exportCsv?.fetchData) && (
                  <Modal
                    open={openModal}
                    onClose={() => setOpenModal(!openModal)}
                  >
                    <ModalContent title={`Download Data `}>
                      <form
                        className="flex flex-col gap-4"
                        onSubmit={exportToCsv}
                      >
                        <InputLabel
                          label="File Name"
                          input={
                            <Input
                              required
                              placeholder="Code"
                              value={exportData.fileName}
                              onChange={(e) =>
                                setExportData({
                                  ...exportData,
                                  fileName: e.target.value,
                                })
                              }
                            />
                          }
                        />
                        <InputLabel
                          label="Amount of data to download"
                          input={
                            <Select
                              required
                              value={exportData.total}
                              onChange={(e) =>
                                setExportData({
                                  ...exportData,
                                  total: parseInt(e.target.value),
                                })
                              }
                            >
                              <option value={100}>100</option>
                              <option value={200}>200</option>
                              <option value={500}>500</option>
                              <option value={1000}>1000</option>
                              <option value={5000}>5000</option>
                              <option value={10000}>10000</option>
                            </Select>
                          }
                        />
                        <InputLabel
                          label="Amount of data to download"
                          input={
                            <Select
                              required
                              value={exportData.format}
                              onChange={(e) =>
                                setExportData({
                                  ...exportData,
                                  format: e.target.value,
                                })
                              }
                            >
                              <option defaultValue="csv">
                                CSV (Comma-separated values)
                              </option>
                              <option value="xlsx">Excel</option>
                            </Select>
                          }
                        />
                        <Button size="sm" loading={loadingCsv} type="submit">
                          Export data{" "}
                          {progress >= 1 ? progress.toString() + "%" : ""}
                        </Button>
                      </form>
                    </ModalContent>
                  </Modal>
                )}
                <td
                  colSpan={columns.length + (onSelect ? 1 : 0)}
                  className={
                    "items-center " +
                    (scrollable
                      ? " sticky bottom-0 bg-slate-50 dark:bg-slate-800 z-10 "
                      : "")
                  }
                >
                  <div
                    className={
                      "w-full pl-2 py-2 text-slate-500 dark:text-slate-400 " +
                      (scrollable
                        ? "pr-2 border-t border-slate-200 dark:border-slate-600"
                        : "pr-0")
                    }
                  >
                    <TablePagination
                      pagination={pagination}
                      dataLength={data.length}
                      setOpenModal={setOpenModal}
                      onChangePage={onChangePage}
                      onChangePageSize={
                        scrollable ? undefined : onChangePageSize
                      }
                      loading={loading}
                      showExport={
                        !!options?.exportCsv?.isActive ||
                        !!options?.exportCsv?.fetchData
                      }
                    />
                  </div>
                </td>
              </tr>
            </tfoot>
          )}
        </table>
      </SimpleBar>
    </div>
  );
}
