import Select from "@atoms/select";
import { RiskResourceType, RiskTreeNode } from "@features/risk-decisions/types";
import {
  RISKS_COLORS,
  RISKS_DIGIT,
  RISK_CODES_LIST,
  RISK_CUSTOMERS_ACTIONS,
  RiskStatus,
} from "@features/risk-decisions/utils";
import {
  CheckBadgeIcon,
  ExclamationCircleIcon,
  FunnelIcon,
  NoSymbolIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/20/solid";
import { CSSProperties } from "react";
import { Handle, HandleProps, NodeProps, Position } from "reactflow";
import { NodeLayout } from "./components/_node_layout";
import { useAlertsPostFilters } from "@features/alerts-filters/state/use-alerts-filters";
import { Input } from "@atoms/input/input-text";
const handleStyle: CSSProperties = {
  height: 15,
  width: 15,
  left: -15 - 8,
};

const fromProps: HandleProps = {
  type: "target",
  id: "from",
  position: Position.Left,
};

export default function LeafNode(
  props: NodeProps<
    RiskTreeNode & {
      withRelations: boolean;
      editable: boolean;
      resource: RiskResourceType;
      onChange: (data: RiskTreeNode) => void;
    }
  >
) {
  // TODO: make it more generic here
  // const { states } = useCustomerStates();
  const { filters: postFilters } = useAlertsPostFilters();

  const isScan = props.data.resource === "scan";
  const isPress = props.data.resource === "press";
  const isKYT = props.data.resource === "kyt";
  const isRisk = !isScan && !isPress && !isKYT;

  // case "customer risks factor" (score/action)
  let score;
  switch (props.data.leaf!.outputs["score"]) {
    case "L":
      score = RiskStatus.Low;
      break;
    case "M":
      score = RiskStatus.Medium;
      break;
    case "H":
      score = RiskStatus.High;
      break;
    case "C":
      score = RiskStatus.Critical;
      break;
    default:
      score = RiskStatus.Undefined;
  }
  if (isKYT) {
    const kytScore = parseFloat(props.data.leaf!.outputs["score"] || "0");
    if (kytScore >= 90) {
      score = RiskStatus.Low;
    } else if (kytScore >= 50) {
      score = RiskStatus.Medium;
    } else if (kytScore >= 25) {
      score = RiskStatus.High;
    } else {
      score = RiskStatus.Critical;
    }
  }
  if (isPress) {
    if (props.data.leaf!.outputs["trigger"] === "true") {
      score = RiskStatus.Low;
    } else {
      score = RiskStatus.Critical;
    }
  }

  const state = props.data.leaf!.outputs["action"];

  // case "scan decision tree" (alias)
  let filter;
  if (props.data.resource === "scan") {
    filter = postFilters.find(
      (f) => f.name === props.data.leaf!.outputs["alias"]
    );
  }

  return (
    <NodeLayout
      selected={props.selected}
      className={
        "border-" + RISKS_COLORS[score] + " dark:bg-slate-800 dark:text-white "
      }
    >
      <div className="p-4 space-x-4 flex items-start flex-row">
        <div>
          {isKYT && (
            <div>
              <small className="text-gray-500">Score</small>
              <Select
                className="rounded-b-none focus:z-10 relative"
                value={
                  parseInt(props.data.leaf!.outputs["score"] || "0") >= 50
                    ? parseInt(props.data.leaf!.outputs["score"] || "0") >= 90
                      ? "100"
                      : "50"
                    : "0"
                }
                onChange={(e) => {
                  props.data.onChange({
                    ...props.data,
                    leaf: {
                      ...props.data.leaf!,
                      outputs: {
                        ...props.data.leaf!.outputs,
                        score: e.target.value,
                      },
                    },
                  });
                }}
              >
                <option value="0">Blocked</option>
                <option value="50">Pending</option>
                <option value="100">Passed</option>
              </Select>
              <Input
                className="rounded-t-none -mt-px focus:z-10 relative"
                placeholder="0-100"
                pattern="[0-9]*"
                value={props.data.leaf!.outputs["score"]}
                onChange={(e) =>
                  props.data.onChange({
                    ...props.data,
                    leaf: {
                      ...props.data.leaf!,
                      outputs: {
                        ...props.data.leaf!.outputs,
                        score: `${
                          parseInt(e.target.value) === 0
                            ? "0"
                            : parseInt(e.target.value) || ""
                        }`,
                      },
                    },
                  })
                }
              />
            </div>
          )}
          {isPress && (
            <div>
              <small className="text-gray-500">Automatic report</small>
              <Select
                disabled={!props.data.editable}
                className={"disabled:opacity-50 nowheel nodrag"}
                value={props.data.leaf!.outputs["trigger"] || "false"}
                onChange={(e) =>
                  props.data.onChange({
                    ...props.data,
                    leaf: {
                      ...props.data.leaf!,
                      outputs: {
                        ...props.data.leaf!.outputs,
                        trigger: e.target.value,
                      },
                    },
                  })
                }
              >
                <option value={"true"}>YES</option>
                <option value={"false"}>NO</option>
              </Select>
            </div>
          )}
          {isScan && (
            <div>
              <small className="text-gray-500">Filter</small>
              <Select
                disabled={!props.data.editable}
                className={"disabled:opacity-50 nowheel nodrag"}
                value={filter?.name ?? "default"}
                onChange={(e) =>
                  props.data.onChange({
                    ...props.data,
                    leaf: {
                      ...props.data.leaf!,
                      outputs: {
                        ...props.data.leaf!.outputs,
                        alias: e.target.value ?? "",
                      },
                    },
                  })
                }
              >
                {postFilters.map((f) => (
                  <option
                    key={f.name != "" ? f.name : "default"}
                    value={f.name != "" ? f.name : "default"}
                  >
                    {f.name != "" ? f.name : "default"}
                  </option>
                ))}
              </Select>
            </div>
          )}
          {isRisk && (
            <div className="">
              <Select
                disabled={!props.data.editable}
                className="disabled:opacity-50 font-bold nowheel nodrag rounded-b-none"
                value={RISKS_DIGIT[score]}
                onChange={(e) => {
                  const value = parseInt(e.target.value);
                  return props.data.onChange({
                    ...props.data,
                    leaf: {
                      ...props.data.leaf!,
                      outputs: {
                        ...props.data.leaf!.outputs,
                        score:
                          RISK_CODES_LIST.filter((i) => i.digit === value)[0]
                            .value ?? "",
                      },
                    },
                  });
                }}
              >
                {RISK_CODES_LIST.map((item) => (
                  <option key={item.id} value={item.digit}>
                    {item.digit || item.label}
                  </option>
                ))}
              </Select>
              <Select
                disabled={!props.data.editable}
                className={
                  "disabled:opacity-50 nowheel nodrag rounded-t-none -mt-px " +
                  (!state || state === "not_required" ? "" : "font-bold")
                }
                value={state}
                onChange={(e) =>
                  props.data.onChange({
                    ...props.data,
                    leaf: {
                      ...props.data.leaf!,
                      outputs: {
                        ...props.data.leaf!.outputs,
                        action: e.target.value ?? "",
                      },
                    },
                  })
                }
              >
                <option value={"not_required"}>No EDD triggered</option>
                {Object.keys(RISK_CUSTOMERS_ACTIONS).map((state) => (
                  <option key={state} value={state}>
                    Trigger '{RISK_CUSTOMERS_ACTIONS[state]}'
                  </option>
                ))}
              </Select>
            </div>
          )}
          <div className="relative">
            <small>from</small>
            <Handle {...fromProps} style={handleStyle} />
          </div>
        </div>
        {isScan && (
          <div
            className={
              "shrink-0 h-12 w-12 flex items-center justify-center rounded bg-blue-400 p-2 my-2"
            }
          >
            <FunnelIcon className="h-full w-8 text-white" />
          </div>
        )}
        {!isScan && (
          <div
            className={
              "shrink-0 h-12 w-12 flex items-center justify-center rounded bg-" +
              RISKS_COLORS[score] +
              " p-2 my-2"
            }
          >
            {RISKS_DIGIT[score] === RISKS_DIGIT[RiskStatus.Low] && (
              <CheckBadgeIcon className="h-full w-8 text-white" />
            )}
            {RISKS_DIGIT[score] === RISKS_DIGIT[RiskStatus.Medium] && (
              <ExclamationCircleIcon className="h-full w-8 text-white" />
            )}
            {RISKS_DIGIT[score] === RISKS_DIGIT[RiskStatus.High] && (
              <ExclamationCircleIcon className="h-full w-8 text-white" />
            )}
            {RISKS_DIGIT[score] === RISKS_DIGIT[RiskStatus.Critical] && (
              <ShieldExclamationIcon className="h-full w-8 text-white" />
            )}
            {RISKS_DIGIT[score] === RISKS_DIGIT[RiskStatus.Undefined] && (
              <NoSymbolIcon className="h-full w-8 text-white" />
            )}
            {/* {RISK_LABEL[score] === "Error" && (
              <XMarkIcon className="h-full w-8 text-white" />
            )} */}
          </div>
        )}
      </div>
    </NodeLayout>
  );
}
