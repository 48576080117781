import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { SearchBar } from "@components/search-bar";
import {
  OutputQuery,
  RestSearchQuery,
  RestSearchRequest,
} from "@components/search-bar/utils/types";
import { buildQueryFromMap } from "@components/search-bar/utils/utils";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { AuthJWT } from "@features/auth/jwt";
import { useAuth } from "@features/auth/state/hooks";
import { CustomerSearchField } from "@features/customers/types";
import { usePressReports } from "@features/press/state/use-press-reports";
import {
  REPORT_EDD_STATE,
  REPORT_OVERALL_RISK_LEVEL,
} from "@features/press/utils";
import { useControlledEffect } from "@features/utils";
import _, { isEqual, upperFirst } from "lodash";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { atomFamily, useRecoilState } from "recoil";

type SearchFiltersType = Partial<{
  search: string;
  review_groups: string[] | null;
  only_without_review_groups: boolean;
  edd_state: string[];
  robot_press_risk: string[];
  active_press_risk: string[];
  assigned_to_member_id: number[] | null;
  only_without_assignment: boolean;
}>;

const ReportsSearchAtom = atomFamily<SearchFiltersType, number>({
  key: "ReportsSearchAtom",
  default: (clientId: number) =>
    JSON.parse(localStorage.getItem(`filters-${clientId}-reports`) || "[]"),
  effects_UNSTABLE: (clientId: number) => [
    ({ onSet }) => {
      onSet((newValue) => {
        localStorage.setItem(
          `filters-${clientId}-reports`,
          JSON.stringify(newValue)
        );
      });
    },
  ],
});

export const ReportsListFilters = ({
  value,
  onChange,
}: {
  value: RestSearchRequest;
  onChange: (value: RestSearchRequest) => void;
}) => {
  const { clientId } = useAuth();
  const { schema } = usePressReports();
  const [advancedQuery, setAdvancedQuery] = useState<RestSearchQuery[]>([]);
  const [filters, setFilters] = useRecoilState(
    ReportsSearchAtom(clientId || 0)
  );
  const reportRiskLevels = REPORT_OVERALL_RISK_LEVEL;
  const reportEDDStates = REPORT_EDD_STATE;
  const { reviewGroups } = useReviewGroups({access: "PRESS"});
  const location = useLocation();
  const { members } = useAgents();

  useEffect(() => {
    onChange({
      query: [
        ...advancedQuery,
        ...buildQueryFromMap({
          review_groups: filters.only_without_review_groups
            ? undefined
            : filters.review_groups,
          edd_state: filters.edd_state,
          robot_overall_risk_level: filters.robot_press_risk,
          active_overall_risk_level: filters.active_press_risk,
          assignees: filters.only_without_assignment
            ? undefined
            : filters.assigned_to_member_id,
        }),
        ...buildQueryFromMap({
          review_groups: filters.only_without_review_groups
            ? reviewGroups.map((a) => a)
            : undefined,
          assignees: filters.only_without_assignment
            ? members.map((a) => a.id)
            : undefined,
        }).map((a) => ({ ...a, not: true })),
      ],
      options: { ...value.options, offset: 0 },
    });
  }, [JSON.stringify(advancedQuery), JSON.stringify(filters)]);

  useControlledEffect(() => {
    const a = new URLSearchParams(window.location.search);
    if (a.get("search"))
      setFilters({
        ...filters,
        search: a.get("search") || "",
      });
  }, [location, setFilters]);

  const membersActives = members.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  return (
    <div className="flex flex-col space-y-2 xl:flex-row xl:space-x-2 xl:space-y-0">
      <div className="grow">
        <InputLabel
          label="Search"
          className="grow"
          input={
            <SearchBar
              schema={{
                table: "reports",
                fields: [
                  ...schema.filter(
                    (field) => !field.possible_additional_keys?.length
                  ),
                  ...schema
                    .filter((field) => field.possible_additional_keys?.length)
                    .reduce((acc: any[], field) => {
                      return [
                        ...acc,
                        ...field.possible_additional_keys.map((key) => ({
                          value_type: key.value_type,
                          external_key: `${field.external_key}.${key.key}`,
                          display_name: `${field.external_key}: ${key.display_name}`,
                          allowed_ops: field.allowed_ops,
                        })),
                      ];
                    }, [] as CustomerSearchField[]),
                ]
                  .filter((field) => field.external_key !== "assignees") // Due to a strange bug with this one
                  .map((field) => {
                    let type:
                      | "number"
                      | "boolean"
                      | "text"
                      | "date"
                      | `type:${string}`;

                    switch (field.value_type) {
                      case "string":
                        type = "text";
                        break;
                      case "number":
                        type = "number";
                        break;
                      case "boolean":
                        type = "boolean";
                        break;
                      case "date":
                        type = "date";
                        break;
                      // Add more cases if needed
                      default:
                        type = `type:${field.value_type}`;
                    }
                    return {
                      key: field.external_key,
                      label: field.display_name,
                      keywords: (
                        field.display_name +
                        " " +
                        field.display_name.replace(/ /gm, "") +
                        " " +
                        field.external_key
                      ).split(/( |_)/),
                      allowed_ops: field.allowed_ops,
                      type,
                    };
                  }),
              }}
              onSuggest={async (_table: string, column: string) => {
                if (column === "review_groups") {
                  return reviewGroups.map((a) => ({ value: a, label: a }));
                }

                const field = schema.find((f) => f.external_key === column);
                if (!field?.possible_values) return [];
                return [
                  ...field.possible_values.map((v) => ({
                    value: v,
                    label: v,
                  })),
                  ...((field.allowed_ops || []).includes("is_null")
                    ? [{ value: null, label: "None" }]
                    : []),
                ];
              }}
              onChange={(str: OutputQuery) => {
                if (str.valid && !isEqual(str.fields, advancedQuery)) {
                  setAdvancedQuery(str.fields);
                }
              }}
            />
          }
        />
      </div>
      <div className="flex space-y-2 flex-col lg:flex-row lg:space-x-2 lg:space-y-0 w-full xl:w-1/2">
        <InputLabel
          className="w-full"
          label="Review groups"
          input={
            <SelectMultiple
              highlight
              value={
                filters.only_without_review_groups
                  ? ["none"]
                  : filters.review_groups || []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  reviewGroups.map((c) => ({
                    value: c,
                    label: c,
                  })),
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_review_groups:
                    e.includes("none") && filters.only_without_review_groups
                      ? // aka : already included "none"
                        false // unselect "none"
                      : e.includes("none"),
                  review_groups:
                    e.includes("none") && filters.only_without_review_groups
                      ? e.splice(e.indexOf("none"), 1) && e
                      : !e.includes("none")
                      ? e
                      : null,
                })
              }
            />
          }
        />
        <InputLabel
          className="w-full"
          label="Assigned to"
          input={
            <SelectMultiple
              highlight
              value={
                filters.only_without_assignment
                  ? ["none"]
                  : filters.assigned_to_member_id
                  ? filters.assigned_to_member_id.map((a) => `${a}`)
                  : []
              }
              options={[
                { value: "none", label: "(None)" },
                ..._.sortBy(
                  membersActives.map((agent) => ({
                    value: agent.id.toString(),
                    label: agent.name,
                  })) || [],
                  "label"
                ),
              ]}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  only_without_assignment:
                    e.includes("none") && filters.only_without_assignment // aka : already included "none"
                      ? false // unselect "none"
                      : e.includes("none"),
                  assigned_to_member_id:
                    e.includes("none") && filters.only_without_assignment
                      ? e.splice(e.indexOf("none"), 1) &&
                        e.map((i) => {
                          return parseInt(i);
                        })
                      : !e.includes("none")
                      ? e.map((i) => {
                          return parseInt(i);
                        })
                      : null,
                })
              }
            />
          }
        />
        <InputLabel
          label="Status"
          className="w-full"
          input={
            <SelectMultiple
              highlight
              value={filters.edd_state?.map((a) => `${a}`) || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  edd_state: e || [],
                })
              }
              options={Object.values(reportEDDStates).map((c) => ({
                value: c,
                label: c.replaceAll("_", " "),
              }))}
            />
          }
        />
        <InputLabel
          label="Robot-estimated overall risk"
          className="w-full"
          input={
            <SelectMultiple
              highlight
              value={filters.robot_press_risk?.map((risk) => `${risk}`) || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  robot_press_risk: e || [],
                })
              }
              options={Object.values(reportRiskLevels).map((risk) => ({
                value: risk,
                label: upperFirst(risk),
              }))}
            />
          }
        />
        <InputLabel
          label="Active overall risk"
          className="w-full"
          input={
            <SelectMultiple
              highlight
              value={filters.active_press_risk?.map((risk) => `${risk}`) || []}
              onChange={(e) =>
                setFilters({
                  ...filters,
                  active_press_risk: e || [],
                })
              }
              options={Object.values(reportRiskLevels).map((risk) => ({
                value: risk,
                label: upperFirst(risk),
              }))}
            />
          }
        />
      </div>
    </div>
  );
};
