import { Button } from "@atoms/button/button";
import { GetStartedCard } from "@atoms/card/get-started";
import { FullScreenPage } from "@atoms/layout/page";
import Link from "@atoms/link";
import { Info, Title } from "@atoms/text";
import RiskFactorsView from "@components/risks";
import { useHasAccess } from "@features/auth/state/use-access";
import { useRiskElement } from "@features/risk-decisions/user-risk-element";
import { ROUTES } from "@features/routes";
import toast from "react-hot-toast";

export default function ScanDecisionTreePage() {
  const hasAccess = useHasAccess();
  const {
    element: decisionTree,
    loading,
    update,
  } = useRiskElement("scan", "-1");

  const createDecisionTree = async () => {
    try {
      await update({
        id: 0,
        group: "",
        label: "Scan Decision Tree",
        describe: "",
        weight: 0,
        type: "customer",
        use_weight: true,
        tree_data: {
          start_node_id: "scan_root",
          nodes: [
            {
              id: "scan_root",
              name: "low",
              leaf: {
                outputs: {
                  alias: "L",
                },
              },
              output_node_ids: {},
            },
          ],
        },
      });
    } catch (error) {
      toast.error("Error creating decision tree");
      console.log("Error creating decision tree", error);
    }
  };

  return (
    <FullScreenPage>
      <div className="h-full w-full flex flex-col">
        <div className="flex flex-col w-full mx-auto text-black dark:text-white">
          <div className="p-4 pb-2 pt-6">
            {hasAccess("SCAN_SETTINGS") && !decisionTree && (
              <Button
                className="float-right"
                size="sm"
                onClick={createDecisionTree}
              >
                Configure a decision tree
              </Button>
            )}
            <Link to={ROUTES.PostFiltering}>
              <Button theme="outlined" className="float-right" size="sm">
                Back to filters
              </Button>
            </Link>
            <Title>Filters decision tree</Title>
            <Info>
              You can configure which filter to apply depending on customer
              data.
            </Info>
          </div>
        </div>
        <div className="grow ">
          <GetStartedCard
            className="w-1/2 mx-auto my-4"
            show={!decisionTree && !loading}
            title={<>Scan Decision Tree</>}
            text={
              <>
                The Go!Scan filters decision tree let you configure which filter
                to apply depending on customer data.
                {hasAccess("SCAN_SETTINGS") && (
                  <>
                    <br />
                    <Button
                      className="mt-4 block"
                      onClick={createDecisionTree}
                      theme="primary"
                      size="md"
                    >
                      Configure a decision tree
                    </Button>
                  </>
                )}
              </>
            }
          />
          {decisionTree && !loading && (
            <RiskFactorsView
              editable={hasAccess("SCAN_SETTINGS")}
              type={"scan"}
            />
          )}
        </div>
      </div>
    </FullScreenPage>
  );
}
