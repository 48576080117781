import { useFetch } from "@features/utils";
import { AgentRole, AgentType, Agentsrole_type } from "../types";

export class AgentsApiClient {
  static getRoles = async () => {
    const response = await useFetch(`/web/roles`, {
      method: "GET",
    });
    const data = await response.json();
    return data as Agentsrole_type[];
  };

  static getAgents = async () => {
    const response = await useFetch(`/web/agents`, {
      method: "GET",
    });
    const data = await response.json();
    if (!data?.length) return [];
    return data as AgentType[];
  };

  static createAgent = async (email: string, name: string) => {
    const response = await useFetch(`/web/agents`, {
      method: "POST",
      body: JSON.stringify({
        email,
        name,
      }),
    });
    const data = await response.json();
    return data as { password?: string; agent_id: number };
  };

  static inviteAgent = async (email: string) => {
    const response = await useFetch(`/web/agents/invite_member`, {
      method: "POST",
      body: JSON.stringify({
        member_email: email,
        review_groups: "",
      }),
    });
    const data = await response.json();
    return data as { agent_id: number };
  };

  static updateAgent = async (id: number, reviewGroups: string[]) => {
    await useFetch(`/web/agents/${id}`, {
      method: "POST",
      body: JSON.stringify({
        review_groups: (reviewGroups || []).join(","),
      }),
    });
  };

  static setRoles = async (id: number, roles: AgentRole[]) => {
    await useFetch(`/web/aro-groups`, {
      method: "PUT",
      body: JSON.stringify([
        {
          agent_id: id,
          roles: roles.map(role => ({...role, review_groups: role.review_groups?.join(",")})),
        },
      ]),
    });
  };

  static resetPassword = async (id: number) => {
    const response = await useFetch(`/web/agents/${id}/password`, {
      method: "PUT",
    });
    const data = await response.json();
    return data as { password: string };
  };

  static disableAgent = async (id: number) => {
    await useFetch(`/web/agents/${id}`, {
      method: "DELETE",
    });
  };

  static enableAgent = async (id: number) => {
    await useFetch(`/web/agents/${id}/activation`, {
      method: "PUT",
    });
  };
}
