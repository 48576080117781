import { Badge } from "@atoms/badge";
import { BaseSmall, InfoSmall } from "@atoms/text";
import { useHasAccess } from "@features/auth/state/use-access";
import { useBilling } from "@features/billing/use-billing";
import {
  MonitoringRiskEddEnum,
  MonitoringScanStateEnum,
} from "@features/dashboard/enums";
import { useDashboard } from "@features/dashboard/state/hooks";
import { ROUTES } from "@features/routes";
import {
  AdjustmentsVerticalIcon,
  Bars4Icon,
  BookmarkIcon,
  ChartBarSquareIcon,
  ChatBubbleLeftRightIcon,
  CodeBracketIcon,
  CogIcon,
  CpuChipIcon,
  CreditCardIcon,
  DocumentArrowDownIcon,
  FolderIcon,
  FunnelIcon,
  IdentificationIcon,
  InboxIcon,
  ListBulletIcon,
  MagnifyingGlassIcon,
  NewspaperIcon,
  PencilSquareIcon,
  PuzzlePieceIcon,
  RectangleGroupIcon,
  ShareIcon,
  ShieldCheckIcon,
  TableCellsIcon,
  TagIcon,
  UsersIcon,
  VideoCameraIcon,
  WrenchIcon,
} from "@heroicons/react/24/outline";
import { ReactNode } from "react";
import { Link, useLocation } from "react-router-dom";
import { atom, useRecoilState } from "recoil";
import SimpleBar from "simplebar-react";

export const SidebarOpenAtom = atom({
  key: "SidebarOpenAtom",
  default: false,
});

export const Sidebar = () => {
  const hasAccess = useHasAccess();
  const { risk, scan, onboarding, chat, press } = useDashboard();
  const {
    isScanEnabled,
    isRiskEnabled,
    isOnboardingEnabled,
    isChatEnabled,
    isKYTEnabled,
    isPressEnabled,
  } = useBilling();
  const [menuOpen] = useRecoilState(SidebarOpenAtom);

  const accessHome = hasAccess("SIDENAV_CUSTOMER");
  const accessScreening =
    hasAccess("SIDENAV_POST_FILTERING") || hasAccess("SIDENAV_ALERT");
  const accessKyt = hasAccess("KYT");
  const accessChat = hasAccess("CHAT");
  const accessVideoOnboarding =
    hasAccess("SIDENAV_SESSION") || hasAccess("SIDENAV_SCENARIO");
  const accessPress = hasAccess("PRESS");
  const accessPreferences =
    hasAccess("SIDENAV_RISK_SETTINGS") || hasAccess("SIDENAV_SCENARIO");
  const accessAccount =
    hasAccess("SIDENAV_AGENTS") ||
    hasAccess("SIDENAV_RISK_REPORTING") ||
    hasAccess("SIDENAV_ALERT_REPORTING") ||
    hasAccess("SIDENAV_SESSION_REPORTING");

  return (
    <div
      className={
        (menuOpen ? "" : "hidden sm:block ") +
        "relative shrink-0 flex flex-col overflow-hidden border-r dark:border-slate-700"
      }
    >
      <SimpleBar
        className={
          "transition-all z-50 sm:z-0 sm:relative sm:ring-0 absolute ring-black ring-[1000px] sm:translate-x-0 h-full overflow-auto left-0 grow shrink-0 flex flex-col w-64 bg-white dark:bg-slate-800 p-4 " +
          (menuOpen
            ? "ring-opacity-50 left-0 translate-x-0"
            : "ring-opacity-0 -translate-x-full")
        }
      >
        <MenuSection show={accessHome} title="HOME">
          {hasAccess("MONITORING") && (
            <MenuItem
              to={ROUTES.Dashboard}
              icon={(p) => <ChartBarSquareIcon {...p} />}
              text="Dashboard"
            />
          )}

          <MenuItem
            show={hasAccess("SIDENAV_CUSTOMER")}
            to={ROUTES.Customers}
            icon={(p) => <Bars4Icon {...p} />}
            text="Customers"
            suffix={
              isRiskEnabled ? (
                <Badge>
                  {
                    (risk?.global_risk_info?.edd_states || []).find(
                      (a) => a.id === MonitoringRiskEddEnum.REQUIRED
                    )?.value
                  }
                </Badge>
              ) : (
                <></>
              )
            }
          />
        </MenuSection>

        <MenuSection show={accessKyt} title="GO!RISK - CUSTOMERS">
          <MenuItem
            show={hasAccess("SIDENAV_AGENTS")}
            to={ROUTES.CustomerRiskFactors}
            icon={(p) => <CpuChipIcon {...p} />}
            text="Risk Factors"
          />
          <MenuItem
            show={hasAccess("SIDENAV_AGENTS")}
            to={ROUTES.Matrix}
            icon={(p) => <TableCellsIcon {...p} />}
            text="Matrices"
          />
        </MenuSection>

        <MenuSection
          show={accessChat && isChatEnabled}
          title="GO!CHAT - MESSAGING"
        >
          <MenuItem
            show={hasAccess("CHAT")}
            to={ROUTES.InboxHome}
            icon={(p) => <InboxIcon {...p} />}
            text="Inbox"
            suffix={
              <Badge>
                {chat?.all_threads_by_status?.open_threads_count || 0}
              </Badge>
            }
          />
          <MenuItem
            show={hasAccess("CHAT_MANAGE")}
            to={ROUTES.InboxTemplates}
            icon={(p) => <RectangleGroupIcon {...p} />}
            text="Templates"
          />
          <MenuItem
            show={hasAccess("CHAT_MANAGE")}
            to={ROUTES.InboxMessageTagsSetup}
            icon={(p) => <ChatBubbleLeftRightIcon {...p} />}
            text="Messages tags"
          />
          <MenuItem
            show={hasAccess("CHAT_MANAGE")}
            to={ROUTES.InboxMessageSetting}
            icon={(p) => <CogIcon {...p} />}
            text="Messages parameters"
          />
        </MenuSection>

        <MenuSection
          show={accessScreening && isScanEnabled}
          title="GO!SCAN - SCREENING"
        >
          <MenuItem
            show={hasAccess("SIDENAV_ALERT")}
            to={ROUTES.Alerts}
            icon={(p) => <ShieldCheckIcon {...p} />}
            text="Alerts"
            suffix={
              <Badge>
                {(scan?.global_scan_info || []).find(
                  (a) => a.alert_state === MonitoringScanStateEnum.NEW
                )?.value || 0}
              </Badge>
            }
          />
          <MenuItem
            show={hasAccess("SIDENAV_POST_FILTERING")}
            to={ROUTES.PostFiltering}
            icon={(p) => <FunnelIcon {...p} />}
            text="Filters"
          />
          <MenuItem
            show={hasAccess("ALERT_CASEMANAGEMENT")}
            to={ROUTES.NameSearch}
            icon={(p) => <MagnifyingGlassIcon {...p} />}
            text="Name Search"
          />
        </MenuSection>

        <MenuSection
          show={accessKyt && isKYTEnabled}
          title="GO!KYT - TRANSACTIONS"
        >
          <MenuItem
            show={hasAccess("KYT")}
            to={ROUTES.LiveTransactions}
            icon={(p) => <ListBulletIcon {...p} />}
            text="Transactions"
          />
          {!!document.location.host.match(/localhost|int\.algoreg/) && (
            <>
              <MenuItem
                show={hasAccess("KYT_AGENT")}
                to={ROUTES.Explore}
                icon={(p) => <ShareIcon {...p} />}
                text="Graph"
              />
              <MenuItem
                show={hasAccess("KYT")}
                to={ROUTES.SavedSearches}
                icon={(p) => <BookmarkIcon {...p} />}
                text="Saved Searches"
              />
            </>
          )}
          <MenuItem
            show={hasAccess("KYT_MANAGE")}
            to={ROUTES.KytRules}
            icon={(p) => <CpuChipIcon {...p} />}
            text="Rules"
          />
          <MenuItem
            show={hasAccess("KYT_MANAGE")}
            to={ROUTES.KytConfiguration}
            icon={(p) => <WrenchIcon {...p} />}
            text="KYT Configuration"
          />
        </MenuSection>

        <MenuSection
          show={accessVideoOnboarding && isOnboardingEnabled}
          title="GO!VID - VIDEO ONBOARDING"
        >
          <MenuItem
            show={hasAccess("SIDENAV_SESSION")}
            to={ROUTES.Sessions}
            icon={(p) => <VideoCameraIcon {...p} />}
            text="Sessions"
            suffix={
              <Badge>
                {(onboarding?.status_statistics?.total_without_status || 0) +
                  (onboarding?.status_statistics?.items
                    .filter((a) => a.status.type === "NEUTRAL")
                    .reduce((a, b) => b.total + a, 0) || 0)}
              </Badge>
            }
          />
          <MenuItem
            show={hasAccess("SIDENAV_SCENARIO")}
            to={""}
            icon={(p) => <IdentificationIcon {...p} />}
            text="Identity Check"
          />
          <MenuItem
            show={hasAccess("SIDENAV_SCENARIO")}
            to={ROUTES.Scenarios}
            icon={(p) => <PuzzlePieceIcon {...p} />}
            text="Scenarios"
          />
          <MenuItem
            show={hasAccess("SIDENAV_SCENARIO")}
            to={ROUTES.SessionStatusSetup}
            icon={(p) => <TagIcon {...p} />}
            text="Sessions status"
          />
          <MenuItem
            show={hasAccess("SIDENAV_SCENARIO")}
            to={ROUTES.ScenarioIntegrationSettings}
            icon={(p) => <WrenchIcon {...p} />}
            text="Scenarios integration"
          />
        </MenuSection>

        <MenuSection
          show={accessPress && isPressEnabled}
          title="GO!PRESS - BAD PRESS REPORTING"
        >
          <MenuItem
            show={hasAccess("PRESS")}
            to={ROUTES.PressReportsSearch}
            icon={(p) => <NewspaperIcon {...p} />}
            text="Reports"
            suffix={
              <Badge>
                {press?.reports_by_edd_status?.action_required_count || 0}
              </Badge>
            }
          />
          <MenuItem
            show={hasAccess("PRESS")}
            to={ROUTES.PressInstantReport}
            icon={(p) => <MagnifyingGlassIcon {...p} />}
            text="Transactional Report"
          />
          <MenuItem
            show={hasAccess("PRESS_MANAGE")}
            to={ROUTES.PressSettings}
            icon={(p) => <WrenchIcon {...p} />}
            text="Press Settings"
          />
        </MenuSection>

        <div className="grow" />

        <MenuSection show={accessPreferences} title="PREFERENCES">
          <MenuItem
            show={hasAccess("RISK_SETTINGS")}
            to={ROUTES.CustomFields}
            icon={(p) => <PencilSquareIcon {...p} />}
            text="Custom fields"
          />
          <MenuItem
            show={hasAccess("RISK_SETTINGS_WRITE")}
            to={ROUTES.DocumentTagsSetup}
            icon={(p) => <FolderIcon {...p} />}
            text="Documents categories"
          />
        </MenuSection>

        <MenuSection noMargin show={accessAccount} title="ACCOUNT">
          <MenuItem
            show={hasAccess("SIDENAV_AGENTS")}
            to={ROUTES.Agents}
            icon={(p) => <UsersIcon {...p} />}
            text="Users"
          />
          <MenuItem
            show={hasAccess("AGENT_EDIT")}
            to={ROUTES.Developers}
            icon={(p) => <CodeBracketIcon {...p} />}
            text="Developers"
          />
          <MenuItem
            show={
              hasAccess("SIDENAV_RISK_REPORTING") ||
              hasAccess("SIDENAV_ALERT_REPORTING") ||
              hasAccess("SIDENAV_SESSION_REPORTING") ||
              hasAccess("PRESS_MANAGE") ||
              hasAccess("KYT_MANAGE") ||
              hasAccess("CHAT_MANAGE")
            }
            to={ROUTES.Reporting}
            icon={(p) => <DocumentArrowDownIcon {...p} />}
            text="Audit & Reporting"
          />
          <MenuItem
            show={hasAccess("ADMIN_SETTINGS")}
            to={ROUTES.Administration}
            icon={(p) => <AdjustmentsVerticalIcon {...p} />}
            text="Administration"
          />
          <MenuItem
            show={hasAccess("AGENT_EDIT")}
            to={ROUTES.Billing}
            icon={(p) => <CreditCardIcon {...p} />}
            text="Billing and plans"
          />
        </MenuSection>
      </SimpleBar>
    </div>
  );
};

const MenuSection = (props: {
  show?: boolean;
  title: string;
  children: ReactNode;
  noMargin?: boolean;
}) => {
  if (props.show === false) {
    return <></>;
  }
  return (
    <>
      <InfoSmall className="text-slate-400 mb-2" noColor>
        {props.title}
      </InfoSmall>
      {props.children}
      {!props.noMargin && <div className="mt-4" />}
    </>
  );
};

export const MenuItem = (props: {
  show?: boolean;
  to?: string;
  onClick?: () => void;
  icon: (args: { className?: string }) => ReactNode;
  text: ReactNode;
  suffix?: ReactNode;
}) => {
  const location = useLocation();
  const [, setMenuOpen] = useRecoilState(SidebarOpenAtom);

  const selected = props.to && location.pathname.startsWith(props.to || "");

  if ((!props.to && !props.onClick) || props.show === false) {
    return <></>;
  }

  return (
    <Link
      to={props.to || ""}
      onClick={() => setMenuOpen(false)}
      className={
        "rounded px-2 py-1 -mx-2 cursor-pointer hover:bg-blue-500 flex flex-row dark:text-white items-center " +
        (selected
          ? " bg-blue-500 text-white "
          : "hover:bg-opacity-25 text-slate-900 ")
      }
    >
      {!!props.icon && props.icon({ className: "h-5 w-5 mr-2" })}
      <BaseSmall noColor>{props.text}</BaseSmall>
      <div className="grow" />
      {props.suffix}
    </Link>
  );
};
