import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Page } from "@atoms/layout/page";
import {
  PageBlock,
  PageBlockHr,
  PageResponsiveColumns,
} from "@atoms/layout/page-block";
import Link from "@atoms/link";
import {
  Base,
  Info,
  InfoSmall,
  Section,
  SectionSmall,
  Title,
} from "@atoms/text";
import Assignments from "@components/assignations";
import { AssigneeTag } from "@components/assignations/tag";
import { PageLoader } from "@components/page-loader";
import { RiskFactorTable } from "@components/risk-factors-table";
import { Table } from "@components/table";
import { useHasAccess } from "@features/auth/state/use-access";
import { TransactionFiltersAtom } from "@features/kyt/state/store";
import { useTransaction } from "@features/kyt/state/use-transaction";
import { useTransactions } from "@features/kyt/state/use-transactions";
import {
  KYT_TRANSACTION_STATES_COLORS,
  KYT_TRANSACTION_STATES_LABELS,
} from "@features/kyt/utils";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { ROUTES } from "@features/routes";
import { formatTime } from "@features/utils/dates";
import { formatAmount } from "@features/utils/strings";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import SessionScore from "@views/client/vid/sessions/components/session-score";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import TransactionAmount from "../components/transaction-amount";
import TransactionParticipant from "../components/transaction-participant";
import TransactionTimeline from "./timeline";
import { TransactionFields } from "./transaction-fields";
import { TransactionNavigation } from "./navigation";

export default function TransactionDetailsPage() {
  const { id } = useParams();
  const { transaction, revisions, loading, refresh, from, to } = useTransaction(
    id ?? ""
  );
  const { riskFactors } = useRiskFactors("kyt");

  const [filters] = useRecoilState(TransactionFiltersAtom);
  const { transactions, refresh: ref, loading: load } = useTransactions();

  const hasAccess = useHasAccess();
  const [viewMore, setViewMore] = useState(false);

  useEffect(() => {
    refresh();
    ref({
      ...filters,
      customers: [transaction?.from.external_id!, transaction?.to.external_id!],
    });
  }, [id, transaction?.from.external_id, refresh]);

  return (
    <Page>
      <Title>
        <div className="flex flex-row items-center">
          <div className="grow flex items-center">
            <span>Transaction "{transaction?.external_id}"</span>
            {transaction?.state !== undefined && (
              <Tag
                noColor
                className={
                  "bg-" +
                  KYT_TRANSACTION_STATES_COLORS[transaction.state] +
                  " text-white ml-2"
                }
              >
                {KYT_TRANSACTION_STATES_LABELS[transaction.state]}
              </Tag>
            )}
          </div>
          <div>
            {/* <Button
              size="sm"
              theme="outlined"
              onClick={() => {
                // navigate to graph explorer
              }}
            >
              <Link
                target="_blank"
                to={ROUTES.ExploreTransaction.replace(
                  ":id",
                  transaction?.id ?? ""
                )}
              >
                Show in graph explorer
              </Link>
            </Button> */}
          </div>
          <TransactionNavigation id={id!} />
        </div>
      </Title>
      {!transaction && loading && (
        <div style={{ marginTop: "30vh" }}>
          <PageLoader />
        </div>
      )}
      {!transaction && !loading && <Info>This transaction was not found.</Info>}
      {transaction && (
        <>
          <div className="grid grid-cols-2 gap-4 mt-5">
            <TransactionParticipant
              id={from?.data?.id}
              participant={transaction.from}
              full={viewMore}
              direction="from"
              className="border-l-2 border-l-blue-500"
              onShowMore={(viewMore) => setViewMore(viewMore)}
            />
            <TransactionParticipant
              id={to?.data?.id}
              participant={transaction.to}
              full={viewMore}
              direction="to"
              className="border-l-2 border-l-red-500"
              onShowMore={(viewMore) => setViewMore(viewMore)}
            />
          </div>

          <div className="flex flex-row space-x-10">
            <div className="w-1/4 flex flex-col mt-4">
              <Assignments
                type="transaction"
                id={transaction.id}
                groups={transaction.review_groups ?? []}
                readonly={!hasAccess("KYT_MANAGE")}
              />
              <TransactionTimeline
                transaction={transaction}
                revisions={revisions ?? []}
              />
            </div>
            <div className="grow flex flex-col">
              <Section className="mt-4">Transaction details</Section>
              <PageBlock>
                <PageResponsiveColumns>
                  <div className="grow">
                    <Info className="mt-4">Amount</Info>
                    <TransactionAmount transaction={transaction} />
                  </div>
                  <div className="grow">
                    <Info>Date</Info>
                    <br />
                    <Base>
                      {formatTime(transaction.date, undefined, {
                        keepDate: true,
                        keepTime: true,
                      })}
                    </Base>
                  </div>
                </PageResponsiveColumns>
                <PageBlockHr />
                <PageResponsiveColumns>
                  <div className="grow w-2/3 shrink-0">
                    <Info>Label</Info>
                    <br />
                    <Base>{transaction.label || "-"}</Base>
                  </div>
                  <div className="w-1/3 shrink-0">
                    <Info>Context</Info>
                    <br />
                    <Base>{transaction.context || "-"}</Base>
                  </div>
                </PageResponsiveColumns>
                <PageBlockHr />
                <div className="flex flex-row mb-1">
                  <SectionSmall>Transaction Scoring</SectionSmall>
                  <div className="grow" />
                  <Tag
                    className={
                      "text-white block px-3 py-2 mr-2 text-sm bg-" +
                      KYT_TRANSACTION_STATES_COLORS[transaction.state]
                    }
                  >
                    {transaction.score}%
                  </Tag>
                  <div>
                    <Tag
                      noColor
                      className={
                        "text-white block px-3 py-2 text-sm bg-" +
                        KYT_TRANSACTION_STATES_COLORS[transaction.state]
                      }
                    >
                      {KYT_TRANSACTION_STATES_LABELS[transaction.state] ||
                        "Undefined"}
                    </Tag>
                  </div>
                </div>
                <RiskFactorTable
                  factors={riskFactors.map(
                    (a) =>
                      ({
                        ...a,
                        score: transaction.rules.find((b) => b.code === a.code)
                          ?.score,
                      } as any)
                  )}
                />

                {!!(
                  transaction.crypto?.blockchain || transaction.crypto?.tx_hash
                ) && (
                  <>
                    <PageBlockHr />
                    <SectionSmall>Blockchain</SectionSmall>
                    <Base>
                      <Info>Blockchain: </Info>
                      {transaction.crypto?.blockchain ?? "-"}
                    </Base>
                    <br />
                    <Base>
                      <Info>Transaction hash: </Info>
                      {transaction.crypto?.tx_hash ?? "-"}
                    </Base>
                  </>
                )}

                {transaction.fields && (
                  <TransactionFields fields={transaction.fields} />
                )}
              </PageBlock>
              <Section className="mt-4">Previous transactions</Section>
              {!load && (
                <PageBlock>
                  <Table
                    rowIndex="external_id"
                    total={transactions.total ?? 0}
                    initialPagination={{
                      page: 1,
                      perPage: filters.per_page,
                    }}
                    data={transactions.data || []}
                    columns={[
                      {
                        title: "#",
                        className: "overflow-hidden text-ellipsis",
                        thClassName: "w-40",
                        render: (row) => <Info>{row.external_id}</Info>,
                      },
                      {
                        title: "From",
                        render: (row) => (
                          <div className="flex items-center">
                            {row.from.account_type === "F" ? (
                              <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                            ) : (
                              <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                            )}
                            {row.from.full_name}
                          </div>
                        ),
                      },
                      {
                        title: "To",
                        render: (row) => (
                          <div className="flex items-center">
                            {row.to.account_type === "F" ? (
                              <BuildingOfficeIcon className="h-5 w-5 inline mr-2 shrink-0" />
                            ) : (
                              <UserIcon className="h-5 w-5 inline mr-2 shrink-0" />
                            )}
                            {row.to.full_name}
                          </div>
                        ),
                      },
                      {
                        title: "Amount",
                        render: (row) => (
                          <div className="flex flex-col items-end w-full font-mono">
                            <Base>
                              {formatAmount(row.amount, row.currency)}{" "}
                              <Info>{row.currency}</Info>
                            </Base>
                            {row.converted_currency !== row.currency && (
                              <Info>
                                ~{" "}
                                {formatAmount(
                                  row.converted_amount,
                                  row.converted_currency
                                )}
                                <InfoSmall className="ml-2">
                                  {row.converted_currency}
                                </InfoSmall>
                              </Info>
                            )}
                          </div>
                        ),
                      },
                      {
                        title: "Date",
                        className:
                          "overflow-hidden text-ellipsis text-right justify-end",
                        thClassName: "w-70",
                        render: (row) => (
                          <Info>
                            {formatTime(row.date, undefined, {
                              keepDate: true,
                              keepTime: true,
                            })}
                          </Info>
                        ),
                      },

                      {
                        title: "Score",
                        className:
                          "overflow-hidden text-ellipsis text-right justify-end",
                        thClassName: "w-40",
                        render: (row) => (
                          <SessionScore
                            text={
                              row.score !== null
                                ? row.score.toFixed(0) + " %"
                                : "-"
                            }
                            status={
                              row.score === null
                                ? "slate"
                                : row.state === 1
                                ? "red"
                                : row.state === 2
                                ? "yellow"
                                : "green"
                            }
                          />
                        ),
                      },
                      {
                        title: "Status",
                        thClassName: "w-40",
                        render: (row) => (
                          <div className="flex items-center flex-row capitalize whitespace-nowrap">
                            <Tag
                              noColor
                              className={
                                "mx-1 my-1 text-white bg-" +
                                (KYT_TRANSACTION_STATES_COLORS[row.state] ||
                                  "slate-500")
                              }
                            >
                              {KYT_TRANSACTION_STATES_LABELS[row.state]}
                            </Tag>
                          </div>
                        ),
                      },
                      {
                        title: "",
                        className: "text-right",
                        headClassName: "justify-end",
                        thClassName: "w-24",
                        render: (row) => (
                          <>
                            <AssigneeTag type="transaction" id={row.id} />
                          </>
                        ),
                      },
                      {
                        title: "Actions",
                        className: "text-right",
                        headClassName: "justify-end",
                        thClassName: "w-24",
                        render: (row) => (
                          <Link
                            to={ROUTES.TransactionView.replace(":id", row.id)}
                          >
                            <Button size="sm" theme="outlined">
                              View
                            </Button>
                          </Link>
                        ),
                      },
                    ]}
                    loading={load}
                  />
                </PageBlock>
              )}
            </div>
          </div>
        </>
      )}
    </Page>
  );
}
