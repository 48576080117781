import { Tag } from "@atoms/badge/tag";
import { Button } from "@atoms/button/button";
import { Base, BaseSmall } from "@atoms/text";
import { useCustomer } from "@features/customers/state/use-customer";
import {
  extractCustomerFullName,
  RELATIONSHIP,
  CUSTOMERS_RISKS_BACKEND_COLORS,
  CUSTOMERS_RISKS_BACKEND_LABEL,
} from "@features/customers/utils";
import { ROUTES } from "@features/routes";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { Link } from "react-router-dom";
import { useRecoilState } from "recoil";
import { CustomerGraphSelectionAtom } from ".";

export const HoverDetails = ({ id }: { id: string }) => {
  const [selection, setSelection] = useRecoilState(CustomerGraphSelectionAtom);
  const isFirm = selection.selectedCustomer?.account_type === 3;
  const { relations } = useCustomer(id || "");

  const customer =
    relations?.relations.find(
      (r) => r.child.customer.id === selection.selectedCustomer?.id
    )?.child ||
    relations?.relations.find(
      (r) => r.parent.customer.id === selection.selectedCustomer?.id
    )?.parent;
  const customerRelations = _.groupBy(
    relations?.relations.filter(
      (r) =>
        r.child.customer.id === customer?.customer?.id ||
        r.parent.customer.id === customer?.customer?.id
    ),
    (a) => [a.child.customer.id, a.parent.customer.id].sort().join(",")
  );

  return (
    <div className="absolute top-4 left-4 p-2 max-w-xs border dark:border-slate-500 rounded-sm bg-white/60 dark:bg-slate-800/60">
      <div className="flex flex-row items-center">
        {!isFirm && <UserIcon className="inline-block w-5 h-5" />}
        {isFirm && <BuildingOfficeIcon className="inline-block w-5 h-5" />}
        <Base className="ml-1">
          {extractCustomerFullName(customer?.customer!)}
        </Base>
        <div className="grow ml-2">
          <Link
            to={ROUTES.CustomerView.replace(
              ":id",
              customer?.customer?.id || ""
            )}
          >
            <Button size="sm" theme="default" className="float-right">
              Details
            </Button>
          </Link>
        </div>
      </div>

      <Tag
        noColor
        className={
          "text-white capitalize block text-md bg-" +
          CUSTOMERS_RISKS_BACKEND_COLORS[`${customer?.risk ?? 1}`]
        }
      >
        {CUSTOMERS_RISKS_BACKEND_LABEL[customer?.risk ?? 1] || "Undefined"}
      </Tag>

      <hr className="my-2 -mx-2" />

      {Object.values(customerRelations).map((row, i) => {
        const relNames = row.map((r) => {
          const name = RELATIONSHIP[r.relation_type] || "Unknown";
          const suffix =
            r.parent.customer?.id === customer?.customer.id ? "" : " of";
          return name + suffix;
        });
        const relCustomer =
          row[0].child.customer?.id === customer?.customer.id
            ? row[0].parent.customer
            : row[0].child.customer;
        const name = extractCustomerFullName(relCustomer);
        const relIsFirm = relCustomer?.account_type === 3;
        const color = relIsFirm ? "blue" : "slate";
        const rounded = relIsFirm ? "sm" : "full";

        return (
          <div
            key={i}
            className={
              "flex flex-row items-center mt-1 hover:bg-blue-50 dark:hover:bg-blue-50 rounded-md cursor-pointer " +
              (selection?.selectedRelations
                ?.map((a) => a.relation_external_id)
                .includes(row[0].relation_external_id || "")
                ? " bg-blue-50 dark:bg-blue-900 "
                : "")
            }
            onClick={() => {
              setSelection({
                ...selection,
                selectedRelations: row,
                selectedCustomer:
                  row[0].child.customer?.id === customer?.customer.id
                    ? row[0].parent.customer
                    : row[0].child.customer,
              });
            }}
          >
            <div
              className={
                "bg-" +
                color +
                "-500 rounded-" +
                rounded +
                " w-5 h-5 mr-1 flex items-center justify-center text-white"
              }
            >
              {!relIsFirm && <UserIcon className="inline-block w-3 h-3" />}
              {relIsFirm && (
                <BuildingOfficeIcon className="inline-block w-3 h-3" />
              )}
            </div>
            {relNames.map((relName, j) => (
              <Tag
                key={j}
                noColor
                className="bg-blue-200 dark:gb-blue-800 mr-1"
              >
                {relName}
              </Tag>
            ))}
            <BaseSmall className="mr-2">{name}</BaseSmall>
          </div>
        );
      })}
    </div>
  );
};
