import { Tag } from "@atoms/badge/tag";
import { Base } from "@atoms/text";
import { ChildParentCustomerRelation } from "@features/customers/types";
import {
  extractCustomerFullName,
  CUSTOMERS_RISKS_BACKEND_COLORS,
  CUSTOMERS_RISKS_BACKEND_LABEL,
} from "@features/customers/utils";
import { BuildingOfficeIcon, UserIcon } from "@heroicons/react/24/outline";
import { Handle, NodeProps, Position } from "reactflow";
import { useRecoilValue } from "recoil";
import { CustomerGraphSelectionAtom } from "..";

export const NodeComponent = ({
  data,
}: NodeProps<ChildParentCustomerRelation["child"] & { known: boolean }>) => {
  const selection = useRecoilValue(CustomerGraphSelectionAtom);

  const node = data;
  const isFirm = node.customer.account_type === 3;
  const color = isFirm ? "blue" : "slate";
  const rounded = isFirm ? "lg" : "full";
  return (
    <div
      className={
        "relative transition-all cursor-pointer rounded-md p-1 " +
        (selection?.rootId === data.customer.id
          ? " bg-orange-100 dark:bg-orange-800 "
          : " bg-slate-100 dark:bg-slate-900 ") +
        (selection?.selectedCustomer?.id === data.customer.id
          ? " ring-2 ring-offset-2 ring-blue-300"
          : " ")
      }
    >
      <Handle
        type="source"
        position={Position.Right}
        style={{
          opacity: 0,
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      />
      <Handle
        type="target"
        position={Position.Right}
        style={{
          opacity: 0,
          position: "absolute",
          left: "50%",
          top: "50%",
        }}
      />
      <div
        className={
          "flex items-center space-x-2 rounded-md z-10 " +
          (data.known ? " " : " opacity-50 ")
        }
        style={{ width: 200 }}
      >
        <div
          className={
            "shrink-0 h-11 w-11  bg-" + color + "-500 rounded-" + rounded
          }
        >
          <div className="h-full flex justify-center align-middle">
            {!isFirm && (
              <UserIcon color="white" className="inline m-2 shrink-0" />
            )}
            {isFirm && (
              <BuildingOfficeIcon
                color="white"
                className="inline m-2 shrink-0"
              />
            )}
          </div>
        </div>
        <div className="grow">
          <Base className="text-ellipsis whitespace-nowrap overflow-hidden block -mb-0.5">
            {extractCustomerFullName(node.customer as any)}
          </Base>
          <Tag
            noColor
            className={
              "text-white capitalize block text-md bg-" +
              CUSTOMERS_RISKS_BACKEND_COLORS[`${node.risk ?? 1}`]
            }
          >
            {CUSTOMERS_RISKS_BACKEND_LABEL[node.risk ?? 1] || "Undefined"}
          </Tag>
        </div>
      </div>
    </div>
  );
};

export const NodeInvisible = () => {
  return (
    <>
      <div className={"relative w-1 h-1 bg-slate-50 opacity-0"}>
        <Handle
          type="source"
          position={Position.Left}
          style={{
            opacity: 0,
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        />
        <Handle
          type="target"
          position={Position.Left}
          style={{
            opacity: 0,
            position: "absolute",
            left: "50%",
            top: "50%",
          }}
        />
      </div>
    </>
  );
};
