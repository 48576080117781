import { useAuth } from "@features/auth/state/hooks";
import { useGlobalEffect } from "@features/utils";
import _ from "lodash";
import { useAgents } from "./use-agents";
import { AppFeatureAccess } from "@features/access";
import { useMemo } from "react";
import { PermissionKey } from "@features/auth/types";

export const useReviewGroups = ({ access, additionalReviewGroups }: { access?: keyof typeof AppFeatureAccess, additionalReviewGroups?: string[] }) => {
  const { getAgentForClient, refresh } = useAgents();
  const agents = getAgentForClient();
  const { clientId, agent, user } = useAuth();

  const reviewGroups = useMemo(() => {
    if (access) {
      const acos = user?.permissions.acos || [];
      const aco = AppFeatureAccess[access].find((p) => acos.includes(p as PermissionKey));
      const reviewGroups = _.uniq([
        ...(user?.permissions.acos_to_review_groups[aco as PermissionKey] || [])
      ]);
      if (!reviewGroups.includes("*")) {
        return reviewGroups;
      } 
    }
    return _.uniq([
      ...(additionalReviewGroups || []),
      ...agents
        .map((a) => a?.clients?.find((c) => c.id === clientId)?.review_groups!)
        .reduce((acc, val) => acc.concat(val), []),
    ]);
  }, [access, additionalReviewGroups, agents, _, user]);

  useGlobalEffect(
    "useReviewGroups-" + access,
    () => {
      refresh();
    },
    [access]
  );

  return {
    current: agent?.review_groups || [],
    all: reviewGroups,
    reviewGroups: reviewGroups,
    refresh,
  };
};
