export const RISKS_COLORS: any = {
  ERR: "red-800",
  NA: "slate-400",
  L: "green-500",
  M: "yellow-500",
  H: "orange-500",
  C: "red-500",
};
export const RISKS_LABELS: any = {
  ERR: "Error",
  NA: "Undefined",
  L: "1",
  M: "2",
  H: "3",
  C: "4",
};

export enum RisksCustomMatrix {
  "ERR" = "ERR",
  "NA" = "NA",
  "L" = 1,
  "M" = 2,
  "H" = 3,
  "C" = 4,
}
