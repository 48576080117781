import countries from "@assets/countries.json";
import { Button } from "@atoms/button/button";
import { Checkbox } from "@atoms/input/input-checkbox";
import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import Select from "@atoms/select";
import { useAlertsPostFilters } from "@features/alerts-filters/state/use-alerts-filters";
import { NameSearchRequestOptions } from "@features/alerts-namesearch/types";
import { useControlledEffect } from "@features/utils";
import { unflatten } from "flat";
import _ from "lodash";
import { useState } from "react";

export const NameSearchFilters = ({
  value,
  onChange,
  loading,
}: {
  value: Partial<NameSearchRequestOptions>;
  onChange: (params: Partial<NameSearchRequestOptions>) => void;
  loading?: boolean;
}) => {
  const [filters, setFilters] =
    useState<Partial<NameSearchRequestOptions>>(value);
  const { filters: postFilters } = useAlertsPostFilters();

  //Load url params if exists
  useControlledEffect(() => {
    const fromUrl: any = unflatten(
      Object.fromEntries(new URLSearchParams(window.location.search))
    );

    fromUrl.post_filtering_alias = fromUrl.post_filtering_alias || null;
    fromUrl.exact_match =
      fromUrl.exact_match ===
      ["1", "true"].includes(fromUrl.exact_match?.toLowerCase() || "");
    if (fromUrl.company_input_data) {
      fromUrl.company_input_data.countries =
        (fromUrl.company_input_data.countries || "")
          .split(",")
          .filter((a: string) => a) || [];
    }
    if (fromUrl.person_input_data) {
      fromUrl.person_input_data.countries =
        (fromUrl.person_input_data.countries || "")
          .split(",")
          .filter((a: string) => a) || [];
      fromUrl.person_input_data.exact_date_of_birth = ["1", "true"].includes(
        fromUrl.person_input_data?.exact_date_of_birth?.toLowerCase() || ""
      );
      fromUrl.person_input_data.date_of_birth = (
        fromUrl.person_input_data?.date_of_birth || ""
      ).split("T")[0];
    }
    fromUrl.entity_type = parseInt(fromUrl.entity_type || "1");
    if (fromUrl?.entity_type) {
      setFilters(fromUrl);
      onChange(fromUrl);
    }
  }, []);

  return (
    <>
      <div className="flex flex-row w-full mt-4">
        <div className="w-64">
          <InputLabel
            label="Filtering"
            input={
              <Select
                value={
                  filters.post_filtering_alias === null
                    ? "null"
                    : filters.post_filtering_alias
                }
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    post_filtering_alias:
                      e.target.value === "null"
                        ? null
                        : (e.target.value as string),
                  });
                }}
              >
                <option value={"null"}>No preset</option>
                {_.sortBy(postFilters, (a) => a.name).map((f) => (
                  <option key={f.name} value={f.name}>
                    Preset '{f.name || "default"}'
                  </option>
                ))}
              </Select>
            }
          />

          {filters.post_filtering_alias === null && (
            <>
              <InputLabel
                className="mt-2"
                label="Exact match"
                input={
                  <Checkbox
                    label={"Names must match exactly"}
                    value={filters.exact_match}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        exact_match: e,
                      });
                    }}
                  />
                }
              />

              {filters.entity_type === 1 && (
                <InputLabel
                  className="mt-2"
                  label="Fuzzy dates"
                  input={
                    <Checkbox
                      label={"Ignore month and day"}
                      value={!filters.person_input_data?.exact_date_of_birth}
                      onChange={(e) => {
                        setFilters({
                          ...filters,
                          person_input_data: {
                            ...filters.person_input_data,
                            exact_date_of_birth: !e,
                          },
                        });
                      }}
                    />
                  }
                />
              )}
            </>
          )}
        </div>
        <div className="w-64 ml-4">
          <InputLabel
            label="Type"
            input={
              <Select
                value={filters.entity_type || 1}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    entity_type: e.target.value === "2" ? 2 : 1,
                  })
                }
              >
                <option value={1}>Person</option>
                <option value={2}>Company</option>
              </Select>
            }
          />
          {
            <InputLabel
              className="mt-2"
              label="Countries"
              input={
                <SelectMultiple
                  value={
                    ((filters.person_input_data?.countries || []).filter(
                      (a) => a
                    ) || []) as string[]
                  }
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      company_input_data: {
                        ...filters.company_input_data,
                        countries: e,
                      },
                      person_input_data: {
                        ...filters.person_input_data,
                        countries: e,
                      },
                    });
                  }}
                  options={countries.map((c) => ({
                    label: c.name + " (" + c["alpha-3"] + ")",
                    value: c["alpha-3"],
                  }))}
                />
              }
            />
          }
        </div>
        {filters.entity_type === 1 && (
          <div className="w-64 ml-4">
            <InputLabel
              label="First name"
              input={
                <Input
                  value={filters.person_input_data?.first_name || ""}
                  placeholder={"Barack"}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      person_input_data: {
                        ...filters.person_input_data,
                        first_name: e.target.value,
                      },
                    })
                  }
                />
              }
            />
            <InputLabel
              className="mt-2"
              label="Last name"
              input={
                <Input
                  value={filters.person_input_data?.last_name || ""}
                  placeholder={"Obama"}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      person_input_data: {
                        ...filters.person_input_data,
                        last_name: e.target.value,
                      },
                    })
                  }
                />
              }
            />
            <InputLabel
              className="mt-2"
              label="Date of birth"
              input={
                <InputDate
                  value={filters.person_input_data?.date_of_birth || ""}
                  placeholder={"1961-08-04"}
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      person_input_data: {
                        ...filters.person_input_data,
                        date_of_birth: e
                          ? new Date(
                              new Date(e).getTime() -
                                new Date(e).getTimezoneOffset() * 60000
                            ) //We don't care about timezone here
                              .toISOString()
                              .split("T")[0]
                          : null,
                      },
                    });
                  }}
                />
              }
            />
          </div>
        )}
        {filters.entity_type === 2 && (
          <div className="w-64 ml-4">
            <InputLabel
              label="Company name"
              input={
                <Input
                  value={filters.company_input_data?.company_name || ""}
                  placeholder={"Microsoft"}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      company_input_data: {
                        ...filters.company_input_data,
                        company_name: e.target.value,
                      },
                    })
                  }
                />
              }
            />
          </div>
        )}

        <div className="grow"></div>
      </div>
      <Button
        className="mt-4"
        loading={loading}
        disabled={
          (!filters.company_input_data?.company_name?.trim() &&
            filters.entity_type === 2) ||
          (!filters.person_input_data?.first_name?.trim() &&
            !filters.person_input_data?.last_name?.trim() &&
            filters.entity_type === 1)
        }
        onClick={() => {
          onChange(filters);
        }}
      >
        Search
      </Button>
    </>
  );
};
