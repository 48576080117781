import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import InputSuggestions from "@atoms/input/input-suggestions";
import { Input } from "@atoms/input/input-text";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { Info } from "@atoms/text";
import {
  RiskFactorElement,
  RiskResourceType,
  RiskTreeNode,
} from "@features/risk-decisions/types";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { useRiskElement } from "@features/risk-decisions/user-risk-element";
import {
  getRiskRoute,
  getRiskRouteDetails,
  getSubLabels,
} from "@features/risk-decisions/utils";
import _ from "lodash";
import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { atomFamily, useRecoilState } from "recoil";

export const RiskElementEditModalAtom = atomFamily<
  {
    open?: boolean;
    id?: string;
    duplicate?: boolean;
  },
  RiskResourceType
>({
  key: "RiskElementEditModalAtom",
  default: {
    open: false,
    id: "",
    duplicate: false,
  },
});

export const RiskElementEditModal = ({
  resource,
}: {
  resource: RiskResourceType;
}) => {
  const [{ open, id }, setOpen] = useRecoilState(
    RiskElementEditModalAtom(resource)
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        return setOpen({});
      }}
    >
      {open && (
        <RiskFactorEditModalContent resource={resource} key={id || ""} />
      )}
    </Modal>
  );
};

export const RiskFactorEditModalContent = ({
  resource,
}: {
  resource: RiskResourceType;
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const { riskFactors } = useRiskFactors(resource);
  const [{ id, duplicate }, setOpen] = useRecoilState(
    RiskElementEditModalAtom(resource)
  );
  const { element, update, remove } = useRiskElement(resource, id || "");

  let defaultNode: RiskTreeNode = {
    id: "node_" + id,
    name: "Undefined",
    leaf: {
      outputs: {
        score: "U",
        action: "not_required",
        trigger: "false",
      },
    },
    output_node_ids: {},
  };
  if (resource === "kyt") {
    defaultNode = {
      id: "node_" + id,
      name: "Pass",
      leaf: {
        outputs: {
          score: "100",
        },
      },
      output_node_ids: {},
    };
  }

  const [form, setForm] = useState<RiskFactorElement>({
    id: 0,
    tree_data: element?.tree_data ?? {
      start_node_id: "node_" + id,
      nodes: [defaultNode],
    },
    ...(element || {}),
    ...(duplicate ? { id: undefined } : {}),
    type: element?.type || "customer",
    group: getSubLabels(
      (element?.label || "") + (duplicate ? " - Copy" : "")
    )[0],
    label: getSubLabels(
      (element?.label || "") + (duplicate ? " - Copy" : "")
    )[1],
    describe: "",
    code: (element?.code || "") + (duplicate ? "_copy" : ""),
    use_weight: element?.use_weight || false,
    weight: element?.weight != null ? element.weight : 1,
  });

  const confirmSave = async () => {
    setLoading(true);
    const newVersion = await update(form);
    if (location.pathname !== getRiskRoute(resource)) {
      navigate(
        getRiskRouteDetails(resource).replace(
          /:id/,
          newVersion?.id.toString() || ""
        )
      );
    }
    setOpen({});
    setLoading(false);
  };

  const confirmRemove = async () => {
    setLoading(true);
    await remove();
    navigate(getRiskRoute(resource));
    setOpen({});
    setLoading(false);
  };

  const availableGroups = _.sortBy(
    _.uniq(
      riskFactors
        .map((a) => a.group || getSubLabels(a.label)[0])
        .filter((a) => a)
    ),
    (a) => a
  );

  const resourceName = resource === "kyt" ? "rule" : "risk factor";

  const saveDisabled = useMemo(() => {
    const codeAlreadyExist = riskFactors.find((a) => a.code === form.code);
    const previous = riskFactors.find((a) => a.id === id);

    if (previous) {
      return (
        (codeAlreadyExist && previous.code !== codeAlreadyExist.code) ||
        (form.weight === previous.weight &&
          form.use_weight === previous.use_weight &&
          form.code === previous.code &&
          form.label === previous.label)
      );
    }
    return (
      !form.label || !!("kyt" === resource && (!form.code || codeAlreadyExist))
    );
  }, [
    id,
    form.group,
    resource,
    form.label,
    riskFactors,
    form.code,
    form.weight,
    form.use_weight,
  ]);

  return (
    <ModalContent
      title={
        (id ? (duplicate ? "Duplicate " : "Create ") : "Edit ") + resourceName
      }
    >
      <InputLabel
        label={resource === "kyt" ? "Rule name" : "Risk factor name"}
        input={
          <div className="flex flex-row">
            <InputSuggestions
              debounce={1}
              single
              placeholder="Group"
              className="focus:z-10 relative rounded-r-none"
              getSuggestions={async (query) => {
                return [
                  ...availableGroups.map((a) => ({ label: a, value: a })),
                  ...(query && !availableGroups.includes(query)
                    ? [{ label: query, value: query }]
                    : []),
                ];
              }}
              value={[form.group]}
              onChange={(value) =>
                setForm(
                  (prevState) =>
                    ({
                      ...prevState,
                      group: value[0],
                    } as RiskFactorElement)
                )
              }
            />
            <Input
              className="focus:z-10 relative rounded-l-none -ml-px"
              placeholder="Rule name"
              value={form.label}
              onChange={(e) =>
                setForm(
                  (prevState) =>
                    ({
                      ...prevState,
                      label: e.target.value,
                    } as RiskFactorElement)
                )
              }
            />
          </div>
        }
      />
      <InputLabel
        className="mt-4"
        label="Describe"
        input={
          <Input
            placeholder="Describe"
            value={form.describe}
            onChange={(e) =>
              setForm(
                (prevState) =>
                  ({
                    ...prevState,
                    describe: e.target.value,
                  } as RiskFactorElement)
              )
            }
          />
        }
      />
      {resource === "kyt" && (
        <>
          <InputLabel
            className="mt-4"
            label="Code"
            input={
              <Input
                placeholder="Code"
                value={form?.code}
                onChange={(e) =>
                  setForm(
                    (prevState) =>
                      ({
                        ...prevState,
                        code:
                          e.target.value
                            ?.toLocaleLowerCase()
                            .replace(/[^a-z0-9]/g, "_") || "",
                      } as RiskFactorElement)
                  )
                }
              />
            }
          />
          {riskFactors.find((a) => a.code === form.code && a.id !== id) && (
            <Info className="text-red-500" noColor>
              Code already used for another rule
            </Info>
          )}
        </>
      )}

      <InputLabel
        className="mt-4"
        label={"Type"}
        input={
          <Select
            disabled={element?.id !== undefined}
            value={form.type}
            onChange={(e) =>
              setForm(
                (prevState) =>
                  ({
                    ...prevState,
                    type: e.target.value,
                  } as RiskFactorElement)
              )
            }
          >
            {resource === "kyt" && (
              <>
                <option value="transaction">Transaction</option>
              </>
            )}
            {resource !== "kyt" && (
              <>
                <option value="customer">Customer</option>
                <option value="customer_relation">Customer's relations</option>
              </>
            )}
          </Select>
        }
      />

      <div className="my-6">
        <Checkbox
          label="Use weight"
          value={form.use_weight && form.weight > 0}
          onChange={(e) =>
            setForm(
              (prevState) =>
                ({
                  ...prevState,
                  use_weight: e,
                  weight: prevState.weight || 1,
                } as RiskFactorElement)
            )
          }
        />
        {form.use_weight && form.weight > 0 && (
          <Input
            className="mt-2"
            placeholder="Weight"
            value={form.weight}
            onChange={(e) =>
              setForm(
                (prevState) =>
                  ({
                    ...prevState,
                    weight: parseInt(e.target.value || "0") || 0,
                  } as RiskFactorElement)
              )
            }
          />
        )}
        <Info className="block mt-2">
          By default the {resource === "kyt" ? "minimum" : "maximum"}{" "}
          {resourceName} result will be used. Instead you can configure several{" "}
          {resourceName}s to compute a weighted average score.
        </Info>
      </div>

      <div className="my-6">
        <Checkbox
          label="Exclude from scoring"
          value={form.use_weight && form.weight === 0}
          onChange={(e) =>
            setForm(
              (prevState) =>
                ({
                  ...prevState,
                  use_weight: e,
                  weight: e ? 0 : 1,
                } as RiskFactorElement)
            )
          }
        />
        <Info className="block mt-2">
          Excluded {resourceName}s will be displayed but won't have an effect on
          the final result.
        </Info>
      </div>

      <ButtonConfirm
        confirmTitle={"Save " + resourceName}
        confirmMessage="This will be applied now"
        className="mt-6 float-right"
        theme="primary"
        loading={loading}
        disabled={saveDisabled}
        onClick={confirmSave}
      >
        {!id || duplicate ? "Create" : "Save"}
      </ButtonConfirm>

      {!!(id && !duplicate) && (
        <ButtonConfirm
          confirmTitle={"Delete " + resourceName}
          confirmMessage="This will be applied now and have an effect on all ongoing risk scoring tasks or scanning tasks."
          className="mt-6"
          theme="danger"
          loading={loading}
          onClick={confirmRemove}
        >
          Remove
        </ButtonConfirm>
      )}
    </ModalContent>
  );
};
