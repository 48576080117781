import { ButtonConfirm } from "@atoms/button/confirm";
import { Checkbox } from "@atoms/input/input-checkbox";
import { InputLabel } from "@atoms/input/input-decoration-label";
import { Input } from "@atoms/input/input-text";
import { Loader } from "@atoms/loader";
import { Modal, ModalContent } from "@atoms/modal/modal";
import Select from "@atoms/select";
import { CustomersApiClient } from "@features/customers/api-client/api-client";
import { UpdateCustomersRequest } from "@features/customers/types";
import { useControlledEffect } from "@features/utils";
import _ from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { atom, useRecoilState } from "recoil";
import { useAllFields, validateContent } from "./utils";

const CHUNK_SIZE = 1000;

export const CustomersUploadStateAtom = atom<{
  current: number;
  total: number;
  done: boolean;
}>({
  key: "CustomersUploadStateAtom",
  default: {
    current: 0,
    total: 0,
    done: false,
  },
});

export const ColumnsConfirmModalAtom = atom<{
  data?: any[];
  fields?: string[];
  mapping: { [key: string]: string };
  mode?: "relations" | "customers";
} | null>({
  key: "ColumnsConfirmModalAtom",
  default: null,
});

export const ImportColumnModal = () => {
  const [fileUpdateModal, openFileUpdateModal] = useRecoilState(
    ColumnsConfirmModalAtom
  );
  const [uploadState, setUploadState] = useRecoilState(
    CustomersUploadStateAtom
  );

  return (
    <>
      <Modal
        open={uploadState.total > 0}
        closable={uploadState.done}
        onClose={() =>
          setUploadState({
            total: 0,
            current: 0,
            done: true,
          })
        }
      >
        <ModalContent title="Importing...">
          <div className="flex flex-col items-center">
            We are importing your customers. <strong>Please note</strong> that
            the scanning and risk scoring result will only be applied on the
            next ongoing scanning and risk scoring event, usually overnight.
            <br />
            <br />
            <div className="text-gray-500 flex items-center">
              <Loader />
              <span className="ml-4">
                {((uploadState.current / uploadState.total) * 100).toFixed(2)} %
              </span>
            </div>
          </div>
        </ModalContent>
      </Modal>
      <Modal
        open={!!fileUpdateModal}
        onClose={() => {
          openFileUpdateModal(null);
        }}
      >
        {!!fileUpdateModal && <ImportColumnModalContent />}
      </Modal>
    </>
  );
};

export const ImportColumnModalContent = () => {
  const { customerFields, relationsFields } = useAllFields();
  const [loading, setLoading] = useState(false);
  const [onlyMandatory, setOnlyMandatory] = useState(false);

  const [fileUpdateModal, openFileUpdateModal] = useRecoilState(
    ColumnsConfirmModalAtom
  );
  const [uploadState, setUploadState] = useRecoilState(
    CustomersUploadStateAtom
  );

  useControlledEffect(() => {
    openFileUpdateModal({
      ...fileUpdateModal!,
      mapping: Object.fromEntries(
        (fileUpdateModal?.mode === "customers"
          ? customerFields
          : relationsFields
        ).map((f) => [
          f.label,
          fileUpdateModal?.mapping[f.label] === undefined
            ? f.field_source === 4
              ? "__empty"
              : ""
            : fileUpdateModal?.mapping[f.label],
        ])
      ),
    });
  }, []);

  const expectedLabels = (
    fileUpdateModal?.mode === "customers" ? customerFields : relationsFields
  ).map((a) => a.label);
  const modeMapping = _.pickBy(fileUpdateModal?.mapping || {}, (_, key) =>
    expectedLabels.includes(key)
  );

  //Some mapping is not defined
  const errorUndefinedMapping = expectedLabels.some((a) => !modeMapping[a]);
  //Multiple mappings to the same field using lodash uniq
  const errorMultipleMappingSameTarget =
    Object.values(modeMapping).filter((a) => a && a !== "__empty").length !==
    new Set(Object.values(modeMapping).filter((a) => a && a !== "__empty"))
      .size;
  //The external_id field must be set
  const errorMandatoryFieldsNotSet = (
    fileUpdateModal?.mode === "customers"
      ? ["external_id", "account_number", "account_type"]
      : [
          "relation_external_id",
          "relation_type",
          "child_customer_external_id",
          "parent_customer_external_id",
        ]
  ).some(
    (a) =>
      !fileUpdateModal?.mapping[a] || fileUpdateModal?.mapping[a] === "__empty"
  );

  const modalHasErrors =
    errorUndefinedMapping ||
    errorMultipleMappingSameTarget ||
    errorMandatoryFieldsNotSet;

  const uploadChunks = async (chunks: UpdateCustomersRequest[]) => {
    openFileUpdateModal(null);
    for (let i = 0; i < chunks.length; i++) {
      try {
        setUploadState({
          current: i + 1,
          total: chunks.length,
          done: false,
        });
        const res = await CustomersApiClient.updateCustomer(chunks[i]);
        if (res.status !== "success") {
          console.error("Importation error: ", res);
          toast.error("Error while importing, see console for more details");
          break;
        }
      } catch (e) {
        toast.error("Error while importing");
        console.error(e);
        break;
      }
    }
    setUploadState({
      ...uploadState,
      done: true,
    });
    toast.success("Import done");
  };

  return (
    <>
      <ModalContent title="Import a customers or relations file">
        <InputLabel
          label="Import type"
          input={
            <Select
              value={fileUpdateModal?.mode}
              onChange={(e) =>
                openFileUpdateModal({
                  ...fileUpdateModal!,
                  mode: e.target.value as any,
                })
              }
            >
              <option value="relations">Relations</option>
              <option value="customers">Customers</option>
            </Select>
          }
        />
        <br />
        We detected{" "}
        {new Intl.NumberFormat().format(
          fileUpdateModal?.data?.length || 0
        )}{" "}
        rows in the file. Please review the columns mapping before to start the
        import.
        <br />
        <Checkbox
          className="my-2"
          label="Show only mandatory fields"
          value={onlyMandatory}
          onChange={(e) => {
            setOnlyMandatory(e);
          }}
        />
        <Checkbox
          label="Set all to empty"
          disabled={!errorUndefinedMapping}
          value={!errorUndefinedMapping}
          onChange={(e) => {
            if (e)
              openFileUpdateModal({
                ...fileUpdateModal!,
                mapping: Object.fromEntries(
                  Object.keys(fileUpdateModal?.mapping || {}).map((a) => [
                    a,
                    fileUpdateModal?.mapping[a] || "__empty",
                  ])
                ),
              });
          }}
        />
        <br />
        {(fileUpdateModal?.mode === "customers"
          ? customerFields
          : relationsFields
        )
          .filter((f) => !onlyMandatory || f.field_source !== 4)
          .map((f) => (
            <InputLabel
              className="mt-4"
              key={f.label}
              label={f.label}
              input={
                <>
                  <Select
                    className={
                      !fileUpdateModal?.mapping[f.label]
                        ? "border border-red-500"
                        : ""
                    }
                    value={fileUpdateModal?.mapping[f.label]}
                    onChange={(e) =>
                      openFileUpdateModal({
                        ...fileUpdateModal!,
                        mapping: {
                          ...fileUpdateModal?.mapping,
                          [f.label]: e.target.value,
                        },
                      })
                    }
                  >
                    <option value="">Select a field</option>
                    <option value="__empty">Let this field empty</option>
                    <option value="__custom">Custom value</option>
                    <option disabled></option>
                    {(fileUpdateModal?.fields || []).map((f2) => (
                      <option key={f2} value={f2}>
                        {f2}
                      </option>
                    ))}
                  </Select>
                  {fileUpdateModal?.mapping[f.label] === "__custom" && (
                    <Input
                      className="mt-2"
                      onChange={(e) => {
                        openFileUpdateModal({
                          ...fileUpdateModal!,
                          mapping: {
                            ...fileUpdateModal?.mapping,
                            [f.label + "__custom"]: e.target.value,
                          },
                        });
                      }}
                    />
                  )}
                </>
              }
            />
          ))}
        <br />
        {errorMultipleMappingSameTarget && (
          <div className="text-red-500">
            You can't map multiple fields to the same target field
          </div>
        )}
        {errorUndefinedMapping && (
          <div className="text-red-500">You need to map all the fields</div>
        )}
        {errorMandatoryFieldsNotSet && (
          <div className="text-red-500">
            Some mandatory fields must be mapped.
          </div>
        )}
        <ButtonConfirm
          className="mt-4"
          onClick={() => {
            //Test for badly formatted values
            setLoading(true);
            try {
              if (
                validateContent(
                  fileUpdateModal?.mode!,
                  fileUpdateModal?.data || [],
                  fileUpdateModal?.mapping
                )
              ) {
                const mapping = fileUpdateModal?.mapping!;
                const mode = fileUpdateModal?.mode!;

                const chunks: {
                  disable_risk_scan: boolean;
                  verify_input: boolean;
                  customers: any[];
                  relations: any[];
                }[] = [];

                let data: {
                  disable_risk_scan: boolean;
                  verify_input: boolean;
                  customers: any[];
                  relations: any[];
                };
                let count = 0;
                fileUpdateModal?.data?.forEach((row, i) => {
                  if (count === CHUNK_SIZE) {
                    chunks.push(data);
                    count = 0;
                  }
                  if (count === 0) {
                    data = {
                      disable_risk_scan: true,
                      verify_input: false,
                      customers: [],
                      relations: [],
                    };
                  }
                  count++;

                  const entity: any = {
                    fields: {},
                  };
                  for (const col of Object.keys(mapping)) {
                    if (mapping[col] === "__empty") continue;

                    const field = (
                      mode === "customers" ? customerFields : relationsFields
                    ).find((a) => a.label === col);

                    /* 	FieldSource_CustomerInternal         FieldSource = 2
                  FieldSource_CustomerCustom                       = 3
                  FieldSource_CustomerComputed                     = 4
                  FieldSource_CustomerRelationInternal             = 5
                  FieldSource_CustomerRelationCustom               = 6 */
                    if (
                      field?.field_source === 6 ||
                      field?.field_source === 3
                    ) {
                      entity.fields[col] = row[mapping[col]];
                    } else {
                      entity[col] = row[mapping[col]];
                    }

                    if (mapping[col] === "__custom") {
                      entity[col] = mapping[col + "__custom"];
                    }

                    if (col === "date_of_birth") {
                      //If format is not YYYY-MM-DD return error
                      if (
                        entity[col] &&
                        !/^\d{4}-\d{2}-\d{2}$/.test(entity[col]) &&
                        !/^\d{2}\/\d{2}\/\d{4}$/.test(entity[col])
                      )
                        throw new Error(
                          `Invalid date format for ${col} ${entity?.external_id}`
                        );
                    }
                  }
                  if (mode === "customers") {
                    data.customers.push(entity);
                  } else {
                    data.relations.push(entity);
                  }

                  if (i === (fileUpdateModal?.data?.length || 0) - 1) {
                    chunks.push(data);
                  }
                });

                toast.success("File is valid, starting upload...");

                //Start upload
                uploadChunks(chunks);
              }
            } catch (e) {
              toast.error(
                "Error while parsing the file content, please refer to the template." +
                  e
              );
              console.error(e);
            }
            setLoading(false);
          }}
          disabled={modalHasErrors}
          loading={loading}
        >
          Import/Update {fileUpdateModal?.data?.length}{" "}
          {fileUpdateModal?.mode === "customers" ? "customers" : "relations"}
        </ButtonConfirm>
      </ModalContent>
    </>
  );
};
