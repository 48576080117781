import { Button } from "@atoms/button/button";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import { ModalContent } from "@atoms/modal/modal";
import { SideModal } from "@atoms/modal/side-modal";
import { Info, SectionSmall } from "@atoms/text";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { useBilling } from "@features/billing/use-billing";
import { useReportingSettings } from "@features/reporting/state/use-reporting-settings";
import {
  ReportingSettingsReportingType,
  ReportingSettingsType,
} from "@features/reporting/types";
import { parseCronExpression } from "@features/reporting/utils";
import { useControlledEffect } from "@features/utils";
import { TrashIcon } from "@heroicons/react/24/outline";
import _ from "lodash";
import { useEffect, useState } from "react";
import { atom, useRecoilState } from "recoil";

export const ReportingSettingsModalAtom = atom({
  key: "ReportingSettingsModalAtom",
  default: {
    open: false,
  },
});

export default function ReportingSettingsSideModal() {
  const [modal, setModal] = useRecoilState(ReportingSettingsModalAtom);
  return (
    <SideModal
      className="bg-slate-100 dark:bg-slate-900"
      open={modal.open}
      onClose={() => {
        setModal({ open: false });
      }}
    >
      <ReportingSettingsSideModalContent />
    </SideModal>
  );
}

function ReportingSettingsSideModalContent() {
  const [saved, setSaved] = useState(true);
  const [modal, setModal] = useRecoilState(ReportingSettingsModalAtom);
  const { reportingSettings, save, sendTestReport, loading, refresh } =
    useReportingSettings();
  const [form, setForm] = useState<ReportingSettingsType>();
  const { reviewGroups } = useReviewGroups({});
  const {
    isScanEnabled,
    isRiskEnabled,
    isOnboardingEnabled,
    isChatEnabled,
    isKYTEnabled,
    isPressEnabled,
  } = useBilling();
  const areEmailsValid = true;

  useControlledEffect(() => {
    refresh();
    setSaved(true);
  }, []);

  useEffect(() => {
    setSaved(false);
  }, [JSON.stringify(form)]);

  // We use useEffect instead of passing reportingSettings directly as default value for configuration in order to synchronize
  // with the backend (on initial render to avoid undefined assignement while awaiting response, on 'save' error to reset the configuration form)
  useEffect(() => {
    if (reportingSettings) {
      setForm(reportingSettings);
    }
  }, [reportingSettings]);

  const products = Object.entries({
    goscan: isScanEnabled,
    gorisk: isRiskEnabled,
    govid: isOnboardingEnabled,
    gochat: isChatEnabled,
    gokyt: isKYTEnabled,
    gopress: isPressEnabled,
  })
    .filter(([, b]) => b)
    .map(([k]) => k);

  const productsNames = {
    goscan: "Go!Scan",
    gorisk: "Go!Risk",
    govid: "Go!Vid",
    gochat: "Go!Chat",
    gokyt: "Go!KYT",
    gopress: "Go!Press",
  };

  const productsLabels = {
    goscan: "go!scan",
    gorisk: "go!score",
    govid: "go!vid",
    gochat: "go!chat",
    gokyt: "go!kyt",
    gopress: "go!press",
  };

  if (!form?.outstanding_delay) return <></>;

  return (
    <>
      <ModalContent
        title={
          <div className="flex flex-row items-center">Manage email reports</div>
        }
      >
        <div style={{ maxWidth: "800px", width: "100vw" }}>
          <SectionSmall>Outstanding tasks delays (Days)</SectionSmall>
          {products.map((k, i) => (
            <InputLabel
              key={i}
              className="mt-2"
              label={(productsNames as any)[k] + " outstanding tasks"}
              input={
                <Input
                  placeholder="Delay"
                  value={(form?.outstanding_delay as any)[k]}
                  onChange={(e) => {
                    let inputValue = parseInt(e.target.value);
                    if (Number.isNaN(inputValue)) {
                      inputValue = 0;
                    }
                    setForm({
                      ...form!,
                      outstanding_delay: {
                        ...form!.outstanding_delay,
                        [k]: inputValue,
                      },
                    });
                  }}
                />
              }
            />
          ))}

          <SectionSmall className="mt-6">Email reports</SectionSmall>
          <Info>
            Daily email reports on your product's stats (Dashboard) can be sent
            to selected individuals. You can configure the recipients and the
            content of these emails (data filtered by review groups and Algoreg
            products). You may set up as many reporting distribution lists as
            desired.
          </Info>

          {form?.reporting.map((reportingDiffusion, index) => (
            <>
              <div className="bg-slate-100 border rounded p-4 relative mt-1 mb-2">
                <button
                  type="button"
                  className="bg-red-500 text-white rounded-full p-1 text-slate-600 dark:text-slate-300 hover:opacity-75 focus:outline-none absolute right-2 top-2 "
                  onClick={() =>
                    setForm({
                      ...form,
                      reporting: [
                        ...form.reporting.slice(0, index),
                        ...form.reporting.slice(index + 1),
                      ],
                    })
                  }
                >
                  <TrashIcon className="h-4 w-4" aria-hidden="true" />
                </button>
                <InputLabel
                  label="Recipients"
                  className="mt-2"
                  input={
                    <EmailListInput
                      onChange={(mails) => {
                        setForm({
                          ...form,
                          reporting: [
                            ...form.reporting.slice(0, index),
                            {
                              ...reportingDiffusion,
                              recipients: mails,
                            },
                            ...form.reporting.slice(index + 1),
                          ],
                        });
                      }}
                      value={reportingDiffusion.recipients}
                    />
                  }
                />
                <InputLabel
                  label="Review groups"
                  className="mt-2"
                  input={
                    <SelectMultiple
                      value={reportingDiffusion.review_groups || []}
                      onChange={(selectedReviewGroups: string[]) => {
                        setForm({
                          ...form,
                          reporting: [
                            ...form.reporting.slice(0, index),
                            {
                              ...reportingDiffusion,
                              review_groups: _.uniq(selectedReviewGroups || []),
                            },
                            ...form.reporting.slice(index + 1),
                          ],
                        });
                      }}
                      options={_.uniq([...(reviewGroups || [])]).map((rg) => ({
                        value: rg,
                        label: rg,
                      }))}
                    />
                  }
                />
                <InputLabel
                  label="Products"
                  className="mt-2"
                  input={
                    <SelectMultiple
                      value={reportingDiffusion.products || []}
                      onChange={(selectedProducts: string[]) => {
                        setForm({
                          ...form,
                          reporting: [
                            ...form.reporting.slice(0, index),
                            {
                              ...reportingDiffusion,
                              products: selectedProducts || [],
                            },
                            ...form.reporting.slice(index + 1),
                          ],
                        });
                      }}
                      options={
                        products.map((a) => ({
                          value: (productsLabels as any)[a],
                          label: (productsNames as any)[a],
                        })) || []
                      }
                    />
                  }
                />
                <InputLabel
                  label="Frequency"
                  className="mt-2"
                  input={
                    <SelectMultiple
                      value={
                        parseCronExpression(
                          reportingDiffusion.frequency
                        )?.dayOfWeek.split(",") || []
                      }
                      onChange={(selectedDaysOfWeek: string[]) => {
                        setForm({
                          ...form,
                          reporting: [
                            ...form.reporting.slice(0, index),
                            {
                              ...reportingDiffusion,
                              frequency:
                                "0 0 12 ? * " + selectedDaysOfWeek.join(","),
                            },
                            ...form.reporting.slice(index + 1),
                          ],
                        });
                      }}
                      options={[
                        { label: "Monday", value: "MON" },
                        { label: "Tuesday", value: "TUE" },
                        { label: "Wednesday", value: "WED" },
                        { label: "Thursday", value: "THU" },
                        { label: "Friday", value: "FRI" },
                        { label: "Saturday", value: "SAT" },
                        { label: "Sunday", value: "SUN" },
                      ]}
                    />
                  }
                />

                <div className="mt-4">
                  <Button
                    theme="outlined"
                    size="sm"
                    onClick={() => sendTestReport(index)}
                    loading={loading}
                    disabled={!saved}
                  >
                    Send now {saved ? "" : " (save first)"}
                  </Button>
                </div>
              </div>
            </>
          ))}

          <div className="mt-4 text-right">
            <Button
              disabled={loading}
              theme="secondary"
              onClick={() => {
                setForm({
                  ...form!,
                  reporting: [
                    ...form!.reporting,
                    {
                      recipients: [],
                      review_groups: [],
                      products: [],
                      frequency: "0 0 12 ? * MON,TUE,WED,THU,FRI",
                    } as ReportingSettingsReportingType,
                  ],
                });
              }}
            >
              New report
            </Button>
          </div>

          <div className="mt-4">
            <Button
              disabled={loading}
              theme="default"
              onClick={() => setModal({ ...modal, open: false })}
              shortcut={["esc"]}
            >
              Cancel
            </Button>
            <Button
              className="ml-2"
              disabled={loading || !areEmailsValid}
              onClick={() => {
                save(form!);
                setSaved(true);
              }}
            >
              Save settings
            </Button>
          </div>
        </div>
      </ModalContent>
    </>
  );
}

const EmailListInput = (props: {
  onChange: (emails: string[]) => void;
  value: string[];
}) => {
  const [value, setValue] = useState(props.value.join(", "));

  //reportingDiffusion.recipients.join(", ")
  return (
    <Input
      placeholder="james@mi5.co.uk, m@mi5.co.uk, ..."
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        const emails = e.target.value
          .split(/,|;/g)
          .map((a) => a.trim().toLocaleLowerCase())
          .filter((a) => a);
        props.onChange(emails);
      }}
    />
  );
};
