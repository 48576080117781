import InputDate from "@atoms/input/input-date";
import { InputLabel } from "@atoms/input/input-decoration-label";
import SelectMultiple from "@atoms/input/input-select-multiple";
import { Input } from "@atoms/input/input-text";
import Tabs from "@atoms/tabs";
import { useAgents } from "@features/agents/state/use-agents";
import { useReviewGroups } from "@features/agents/state/use-review-groups";
import { AuthJWT } from "@features/auth/jwt";
import {
  InboxThreadRequestOptions,
  InboxThreadStatus,
} from "@features/inbox/types";
import { useMessagesLabels } from "@features/messages-labels/state/use-messages-labels";
import { useControlledEffect } from "@features/utils";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { InboxModalContext } from "./modal";
import { useAuth } from "@features/auth/state/hooks";

export const ThreadsListFilters = ({
  value,
  onChange,
  extended,
}: {
  value: Partial<InboxThreadRequestOptions>;
  onChange: (params: Partial<InboxThreadRequestOptions>) => void;
  extended?: boolean;
}) => {
  const [filters, setFilters] =
    useState<Partial<InboxThreadRequestOptions>>(value);
  const { reviewGroups } = useReviewGroups({access: "CHAT"});
  const { members: agents } = useAgents();
  const { customerId: modalCustomerId } = useContext(InboxModalContext);
  const { labels } = useMessagesLabels();
  const { user } = useAuth();

  useEffect(() => {
    setFilters(value);
  }, [value]);

  useControlledEffect(() => {
    if (_.isEqual(filters, value)) return;
    onChange({
      ...filters,
    });
  }, [filters]);

  // Need agents actives
  const agentsActives = agents.filter(
    (agent) =>
      agent.active &&
      agent.clients.filter(
        (client) => client.active && client.id === AuthJWT.clientId
      ).length > 0
  );

  //Force extends if filters are set
  extended =
    extended ||
    !!(
      filters.customer_id ||
      filters.contact ||
      filters.min_created_at ||
      filters.max_created_at ||
      filters.min_updated_at ||
      filters.max_updated_at ||
      filters.review_groups?.length ||
      filters.only_without_assignment ||
      filters.only_without_review_groups ||
      // Must be last one
      (filters.assigned_to_member_id &&
        filters.assigned_to_member_id !== user?.id)
    );

  return (
    <div className="flex flex-col">
      {extended && (
        <div className="flex flex-col space-y-2 mt-3 pt-2 pb-4 -mx-4 px-4 bg-slate-200 dark:bg-slate-800 border-b dark:border-slate-700">
          {!modalCustomerId && (
            <div className="flex flex-row space-x-2">
              <InputLabel
                label="Contact"
                className="grow"
                input={
                  <Input
                    highlight
                    value={filters.contact ?? ""}
                    onChange={(e) => {
                      setFilters({
                        ...filters,
                        contact: e.target.value || null,
                      });
                    }}
                    placeholder="m@mi5.gov.uk"
                  />
                }
              />
              <InputLabel
                label="Customer ID"
                className="grow"
                input={
                  <Input
                    highlight
                    value={filters.customer_id ?? ""}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        customer_id: e.target.value || null,
                      })
                    }
                    placeholder="MI5007"
                  />
                }
              />
            </div>
          )}
          <div className="flex flex-row space-x-2">
            <InputLabel
              label="Created after/before"
              className="w-1/2"
              input={
                <div className="flex flex-row">
                  <InputDate
                    className="border-r-none rounded-r-none"
                    highlight
                    value={filters.min_created_at ?? null}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        min_created_at: e?.toISOString() ?? null,
                      })
                    }
                  />
                  <InputDate
                    className="border-l-none rounded-l-none"
                    highlight
                    value={filters.max_created_at ?? null}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        max_created_at: e?.toISOString() ?? null,
                      })
                    }
                  />
                </div>
              }
            />
            <InputLabel
              label="Updated after/before"
              className="w-1/2"
              input={
                <div className="flex flex-row">
                  <InputDate
                    className="border-r-none rounded-r-none"
                    highlight
                    value={filters.min_updated_at ?? null}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        min_updated_at: e?.toISOString() ?? null,
                      })
                    }
                  />
                  <InputDate
                    className="border-l-none rounded-l-none"
                    highlight
                    value={filters.max_updated_at ?? null}
                    onChange={(e) =>
                      setFilters({
                        ...filters,
                        max_updated_at: e?.toISOString() ?? null,
                      })
                    }
                  />
                </div>
              }
            />
          </div>
          <div className="flex flex-row space-x-2">
            <InputLabel
              label="Review groups"
              className="w-1/2"
              input={
                <SelectMultiple
                  highlight
                  value={
                    filters.only_without_review_groups
                      ? ["none"]
                      : filters.review_groups ?? []
                  }
                  options={[
                    { value: "none", label: "(None)" },
                    ..._.sortBy(
                      reviewGroups.map((c) => ({
                        value: c,
                        label: c,
                      })),
                      "label"
                    ),
                  ]}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      only_without_review_groups: e.includes("none"),
                      review_groups: (!e.includes("none") && e) || [],
                    })
                  }
                />
              }
            />
            <InputLabel
              label="Assigned to"
              className="w-1/2"
              input={
                <SelectMultiple
                  highlight
                  selectionLimit={1}
                  value={
                    filters.only_without_assignment
                      ? ["none"]
                      : filters.assigned_to_member_id
                      ? [filters.assigned_to_member_id.toString()]
                      : []
                  }
                  options={[
                    { value: "none", label: "(None)" },
                    ..._.sortBy(
                      agentsActives.map((agent) => ({
                        value: agent.id.toString(),
                        label: agent.name,
                      })) || [],
                      "label"
                    ),
                  ]}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      only_without_assignment: e.includes("none"),
                      assigned_to_member_id:
                        !e.includes("none") && e.length > 0
                          ? parseInt(e[0])
                          : null,
                    })
                  }
                />
              }
            />
          </div>
          <div className="flex flex-row space-x-2">
            <InputLabel
              label="Tags"
              className="w-full"
              input={
                <SelectMultiple
                  highlight
                  value={filters.tag_codes ?? []}
                  options={labels.map((l) => ({
                    value: l.code,
                    label: l.name,
                  }))}
                  onChange={(e) =>
                    setFilters({
                      ...filters,
                      tag_codes: e,
                    })
                  }
                />
              }
            />
          </div>
        </div>
      )}

      <div className={"flex flex-col " + (extended ? "mt-1" : "mt-5")}>
        <div className="grow -ml-7 -mr-4">
          <Tabs
            className="px-4"
            tabs={[
              { value: "mine", label: "Me" },
              { value: InboxThreadStatus.OPEN, label: "Open" },
              { value: InboxThreadStatus.REMINDER, label: "Reminder" },
              { value: InboxThreadStatus.WAITING, label: "Waiting" },
              { value: InboxThreadStatus.CLOSED, label: "Closed" },
              { value: "", label: "All" },
            ]}
            value={
              filters.status === InboxThreadStatus.OPEN &&
              filters.assigned_to_member_id === user?.id
                ? "mine"
                : filters.status || ""
            }
            onChange={(e) =>
              e === "mine"
                ? setFilters({
                    ...filters,
                    status: InboxThreadStatus.OPEN,
                    assigned_to_member_id: user?.id,
                  })
                : setFilters({
                    ...filters,
                    assigned_to_member_id: undefined,
                    status: e ? (e as InboxThreadStatus) : undefined,
                  })
            }
          />
        </div>
      </div>
    </div>
  );
};
