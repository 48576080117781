import InputSelectMultiple from "@atoms/input/input-select-multiple";
import {
  RELATIONSHIP,
  CUSTOMERS_RISKS_BACKEND_COLORS,
} from "@features/customers/utils";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { RISK_LABEL } from "@features/risk-decisions/utils";
import { UsersIcon } from "@heroicons/react/24/outline";
import { CSSProperties } from "react";
import { Handle, HandleProps, NodeProps, Position } from "reactflow";
import { NodeLayout } from "./components/_node_layout";

const handleStyle: CSSProperties = {
  height: 15,
  width: 15,
  right: -24,
  //   position: "relative",
  //   backgroundColor: "blue",
};

const startProps: HandleProps = {
  type: "source",
  id: "start",
  position: Position.Right,
};

export type RelationStartNode = {
  relation_types: {
    type: number;
  }[];
  risk_factor_ids: number[];
  minimum_risk: number;
};

const validateNode = (node: RelationStartNode) => {
  if (!node.relation_types.length) return "Relations is required";
  if (!node.risk_factor_ids?.length) return "Risk Factors is required";
  return "";
};

export default function RelationsNode(
  props: NodeProps<
    RelationStartNode & {
      editable: boolean;
      onChange: (data: RelationStartNode) => void;
    }
  >
) {
  const { riskFactors } = useRiskFactors("kyt");

  const levelRisk = props.data.minimum_risk || 2;

  return (
    <NodeLayout invalidMessage={validateNode(props.data)}>
      <div className="p-4 flex flex-row space-x-4">
        <div className="rounded bg-slate-400 p-2 h-12 w-12 shrink-0">
          <UsersIcon className="h-full w-8 text-white" />
        </div>
        <div className="grow">
          <div className="font-bold">Customer relatives information</div>
          <div className="relative text-right">
            <small>Start</small>
            <Handle {...startProps} style={handleStyle} />
          </div>
        </div>
      </div>

      <div>
        <InputSelectMultiple
          disabled={!props.data.editable}
          className="nowheel nodrag rounded-none border-gray-200 border border-l-0 border-r-0 h-9 w-full text-sm"
          options={
            // Tranform RELATIONSHIP to CustomFieldType
            Object.keys(RELATIONSHIP).map((key) => {
              return {
                value: key,
                label: RELATIONSHIP[key],
              };
            })
          }
          onChange={(e) =>
            props.data.onChange({
              ...props.data,
              relation_types: e.map((r) => {
                return { type: Number(r) };
              }),
            })
          }
          value={(props.data.relation_types || []).map(
            (item) => `${item.type}`
          )}
          placeholder="Select relations"
        />
      </div>
      <div>
        <InputSelectMultiple
          disabled={!props.data.editable}
          className="nowheel nodrag rounded-none border-gray-200 border border-t-0 border-l-0 border-r-0 h-9 w-full text-sm"
          options={
            // Tranform risk factor to CustomFieldType
            (riskFactors || []).map((risk) => {
              return {
                value: `${risk.id}`,
                label: risk.label,
              };
            })
          }
          value={(props.data.risk_factor_ids || [])?.map((id) => `${id}`)}
          onChange={(selectedList) =>
            props.data.onChange({
              ...props.data,
              risk_factor_ids: selectedList.map((id) => Number(id)),
            })
          }
          placeholder="Select risks factors"
        />
      </div>
      <div className="flex flex-col border-b p-3 border-gray-200 space-y-2">
        <div className="flex flex-rows place-content-between ">
          <small className="text-gray-500">Minimum risk Level</small>
          <small
            className={"text-" + CUSTOMERS_RISKS_BACKEND_COLORS[levelRisk]}
          >
            {RISK_LABEL[levelRisk]}
          </small>
        </div>
        <input
          disabled={!props.data.editable}
          className={
            "nowheel nodrag h-2 appearance-none align-middle w-full rounded-lg bg-" +
            CUSTOMERS_RISKS_BACKEND_COLORS[levelRisk]
          }
          type="range"
          min={2}
          max={4}
          value={levelRisk}
          step={1}
          onChange={(event) => {
            props.data.onChange({
              ...props.data,
              minimum_risk: Number(event.target.value),
            });
          }}
        />
      </div>

      <div className="h-2"></div>
      {validateNode(props.data) && <div className="h-5"></div>}
    </NodeLayout>
  );
}
