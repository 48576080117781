import { RiskResourceType } from "@features/risk-decisions/types";
import { useRiskFactors } from "@features/risk-decisions/use-risks-decisions";
import { useParams } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";
import { atomFamily } from "recoil";
import RiskFlow from "./risk-flow";

export const RiskEditorHasUnsavedChangesAtom = atomFamily<
  boolean,
  RiskResourceType
>({
  key: "RiskEditorHasUnsavedChangesAtom",
  default: false,
});

export default function RiskFactorsView({
  type,
  editable,
}: {
  readonly type: RiskResourceType;
  editable: boolean;
}) {
  const { id } = useParams();
  const { loading, riskFactors } = useRiskFactors(type);

  if (riskFactors.length === 0 && loading) return <></>;
  console.log({ 1: id ?? (type == "scan" || type == "press" ? "-1" : "") });
  
  return (
    <div className="flex flex-col h-full">
      <div className="grow border-t">
        <ReactFlowProvider>
          <RiskFlow
            editable={editable}
            id={id ?? (type == "scan" || type == "press" ? "-1" : "")}
            type={type}
          />
        </ReactFlowProvider>
      </div>
    </div>
  );
}
